import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  startWritingTestApi,
  saveWritingTestApi,
  submitWritingTestApi,
  fetchWritingDetailsApi
} from "../../../Utils/ApiHandler/WritingApi";

// state = {inprogress: 4, studentSubmitted: 6,  inreview: 5, reviewSubmitted: 7}

const initialState = {
  data: null,
  // data: {
  //   skillTitle: "Communication",
  //   questionText: "What do you call a person who cooks well?",
  //   answer: "<p>Sample content</p>",
  //   status: 5,
  //   isCompleted: 0,
  //   rubrics: {
  //     "ARGUMENT": "",
  //     "ORGANIZATION/STRUCTURE": "",
  //     "EVIDENCE AND SUPPORT":"",
  //     "TOPIC": "",
  //     "DEVELOPMENT": ""

  //   }
  // },
  loading: false,
  error: null,
};

// data : {
//   questionId : question._id,
//   question: question,
//   answer: answer,
//   skillTitle: skillTitle,
//   instructions: resource
//   rubrics: rubrics,
// }

// Thunks for API calls
export const startWritingTestThunk = createAsyncThunk(
  "writingTest/startWritingTest",
  async ({ token, assignmentId }) => {
    const response = await startWritingTestApi(token, assignmentId);
    if(!response.data) {
      return response;
    }
    return {...response.data};
  }
);

export const saveWritingTestThunk = createAsyncThunk(
  "writingTest/saveWritingTest",
  async ({ token, assignmentId,questionId, timeSpent,  answer, status }) => {
    console.log("save Writing Test in Thunk ",answer)
    const response = await saveWritingTestApi(
      token,
      assignmentId,
      questionId,
      timeSpent,
      answer,
      status
    );
    return response.data;
  }
);

export const submitWritingTestThunk = createAsyncThunk(
  "writingTest/submitWritingTest",
  async ({ token, assignmentId, questionId, answer, status, rubrics }) => {
    const response = await submitWritingTestApi(
      token,
      assignmentId,
      questionId,
      answer,
      status,
      rubrics
    );
    
    return response.data;
  }
);

export const fetchWritingDetailsThunk = createAsyncThunk(
  "writingTest/fetchWritingDetails",
  async ({ token, assignmentId, answer, status, rubrics }) => {
    const response = await fetchWritingDetailsApi(
      token,
      assignmentId
    );
    
    return response.data;
  }
);

// Create the slice
const writingTestSlice = createSlice({
  name: "writingTest",
  initialState,
    
  reducers: {
    cleanWritingData: (state) => {
      state.data = null;
      state.loading = false; 
      state.error = null;
    },
    settingLoad: (state) => {
      state.loading = true; 
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(startWritingTestThunk.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous error
      })
      .addCase(startWritingTestThunk.fulfilled, (state, action) => {
        
    //   "action.payload": {
    //     "_id": "66f166f1909ad2f4601a8264",
    //     "qtype": 25,
    //     "question": {
    //         "text": "dummy text"
    //     },
    //     "skillTitle": "Opinion Writing",
    //     "submittedAnswer": [
    //         "<p>esth</p>"
    //     ]
    // }
    if(action.payload.error){
      state.error = action.payload.error;
      state.data = null;
      
    }else{
        state.loading = false;
        state.data = { ...state.data, skillTitle:action.payload.skillTitle,
           question: action.payload?.question?.text, 
           answer: action.payload?.submittedAnswer?.[0], 
           questionId : action.payload._id, 
           status: action.payload.status,
           timeSpent: action.payload.timeSpent,
           resource: action.payload.resource }; 
      }
    })
      .addCase(startWritingTestThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveWritingTestThunk.pending, (state) => {
        // state.loading = true;
        state.error = null; 
      })
      .addCase(saveWritingTestThunk.fulfilled, (state, action) => {
      //   {
      //     "question": "dummy text",
      //     "_id": "66f166f1909ad2f4601a8264",
      //     "assignmentId": "66fa7f3df056f065759c7198",
      //     "skillId": "6676694ac4af9607f3237a97",
      //     "status": 4,
      //     "submittedAnswer": [
      //         "<p>efwg</p>"
      //     ],
      //     "qtype": 25,
      //     "atype": 2,
      //     "isFlag": false,
      //     "skillTitle": "Opinion Writing"
      // }
        state.loading = false;
        // Handle the save response if needed
        console.log("Save wrting respones data", action.payload);
        localStorage.setItem("writingTestStatus",action.payload.status)
        state.data = { ...state.data, 
          skillTitle: action.payload?.skillTitle, 
          status: action.payload.status, 
          questionId: action.payload._id, 
          question: action.payload.question, 
          timeSpent: action.payload.timeSpent,
          answer: action.payload.submittedAnswer?.[0] };
      })
      .addCase(saveWritingTestThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store error message
      })
      .addCase(submitWritingTestThunk.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous error
      })
      .addCase(submitWritingTestThunk.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Submit response data", action.payload);
        // Handle the submit response if needed
        state.data = {...state.data, isCompleted: action.payload.isCompleted}
      })
      .addCase(submitWritingTestThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store error message
      }).addCase(fetchWritingDetailsThunk.pending,(state, action) => {
        state.loading = true;
        state.error = null; 
      }).addCase(fetchWritingDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = {...state.data, ...action.payload}
      
      }).addCase(fetchWritingDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error; 
      }

      )
  },
});

// Export actions and reducer
export const { cleanWritingData, settingLoad } = writingTestSlice.actions;

export default writingTestSlice.reducer;
