import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Start Benchmark Test
export const startBenchmarkTestAPI = async (
  assignmentId,
  programName,
  token,
  questionId,
  timeSpent
) => {
  try {
    var url;
    const body = timeSpent
      ? JSON.stringify({ assignmentId, isPause: true, timeSpent })
      : JSON.stringify({ assignmentId, questionId });
    url = `${BASE_URL}/sd/v2/startBenchmarkTest/${programName}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const result = await response.json();
    if (result.statusCode === 500) {
      toast.error("Something went wrong, Please try again after some time.");
      return { message: "Failure", status: 500 };
    } else if (result.message === "Success") {
      return result;
    }
    const errorResponse = result;
    throw new Error(
      errorResponse.message ||
        `Network response was not ok: ${response.statusText}`
    );
  } catch (error) {
    throw new Error(`Failed to start test: ${error.message}`);
  }
};

// Benchmark Test Next Question
export const benchmarkTestNextQuestionAPI = async (
  questionId,
  assignmentId,
  submittedAnswer,
  isSkipped,
  timeSpentPerQuestion,
  programName,
  token
) => {
  var timeSpent = timeSpentPerQuestion;
  try {
    var url;
    if (programName === "ela") {
      url = `${BASE_URL}/sd/v2/benchmarkTestNextQuestion/ela`;
    } else if (programName === "math") {
      url = `${BASE_URL}/sd/v2/benchmarkTestNextQuestion/math`;
    }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        questionId,
        assignmentId,
        submittedAnswer,
        isSkipped,
        timeSpent,
      }),
    });
    const result = await response.json();
    if (
      result.statusCode === 500 ||
      result.message === "No question found to resume the test"
    ) {
      toast.error("Something went wrong, Please try again after some time.");
      return { message: "Failure", status: 500 };
    }
    if (result.message === "Success") {
      return result;
    }
    const errorResponse = result;
    throw new Error(
      errorResponse.message ||
        `Network response was not ok: ${response.statusText}`
    );
  } catch (error) {
    throw new Error(`Failed to get next question: ${error.message}`);
  }
};

// Start RC(Reading compensation) Benchmark Test
export const startRCBenchmarkTestAPI = async (
  assignmentId,
  token,
  questionId,
  parentQuestionId,
  timeSpent
) => {
  try {
    var url;
    const body = timeSpent
      ? JSON.stringify({ assignmentId, isPause: true, timeSpent })
      : JSON.stringify({ assignmentId, questionId, parentQuestionId });
    url = `${BASE_URL}/sd/v2/startBenchmarkTest/reading`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const result = await response.json();
    if (result.statusCode === 500) {
      toast.error("Something went wrong, Please try again after some time.");
      return { message: "Failure", status: 500 };
    } else if (result.message === "Success") {
      return result;
    }
    const errorResponse = result;
    throw new Error(
      errorResponse.message ||
        `Network response was not ok: ${response.statusText}`
    );
  } catch (error) {
    throw new Error(`Failed to start test: ${error.message}`);
  }
};

// Benchmark Test Next Question
export const startRCBenchmarkTestNextQuestionAPI = async (
  questionId,
  assignmentId,
  submittedAnswer,
  isSkipped,
  timeSpentPerQuestion,
  token,
  parentQuestionId
) => {
  var timeSpent = timeSpentPerQuestion;
  try {
    var url;
    url = `${BASE_URL}/sd/v2/benchmarkTestNextQuestion/reading`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        questionId,
        assignmentId,
        submittedAnswer,
        isSkipped,
        timeSpent,
        parentQuestionId,
      }),
    });
    const result = await response.json();
    if (
      result.statusCode === 500 ||
      result.message === "No question found to resume the test"
    ) {
      toast.error("Something went wrong, Please try again after some time.");
      return { message: "Failure", status: 500 };
    }
    if (result.message === "Success") {
      return result;
    }
    const errorResponse = result;
    throw new Error(
      errorResponse.message ||
        `Network response was not ok: ${response.statusText}`
    );
  } catch (error) {
    throw new Error(`Failed to get next question: ${error.message}`);
  }
};
