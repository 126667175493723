/**
 * SET_ASSIGNMENT_ID
 *
 * Action type constant for setting the assignment ID.
 *
 * @author - Gaurav
 */
export const SET_ASSIGNMENT_ID = "SET_ASSIGNMENT_ID";

/**
 * TEST_FINISHED
 *
 * Action type constant for setting the assignment ID.
 *
 * @author - Gaurav
 */
export const TEST_COMPLETED = "TEST_COMPLETED";
/**
 * TEST_FINISHED
 *
 * Action type constant for setting the assignment ID.
 *
 * @author - Gaurav
 */
export const TEST_ENDED = "TEST_ENDED";

export const RESET_TEST_STATE = "RESET_TEST_STATE";

export const TEST_EMPTY_STATE = "TEST_EMPTY_STATE";
/**
 * SET_QUESTION_ID
 *
 * Action type constant for setting the Question ID.
 *
 * @author - Gaurav
 */
// export const SET_QUESTION_ID = "SET_QUESTION_ID";

/**
 * START_PLACEMENT_TEST_REQUEST
 * START_PLACEMENT_TEST_SUCCESS
 * START_PLACEMENT_TEST_FAILURE
 *
 * Action type constants for starting the placement test.
 *
 * @author - Gaurav
 */
export const START_PLACEMENT_TEST_REQUEST = "START_PLACEMENT_TEST_REQUEST";
export const START_PLACEMENT_TEST_SUCCESS = "START_PLACEMENT_TEST_SUCCESS";
export const START_PLACEMENT_TEST_FAILURE = "START_PLACEMENT_TEST_FAILURE";

/**
 * SUBMIT_ANSWER_REQUEST
 * SUBMIT_ANSWER_SUCCESS
 * SUBMIT_ANSWER_FAILURE
 *
 * Action type constants for submitting an answer in the placement test.
 *
 * @author - Gaurav
 */
export const SUBMIT_ANSWER_REQUEST = "SUBMIT_ANSWER_REQUEST";
export const SUBMIT_ANSWER_SUCCESS = "SUBMIT_ANSWER_SUCCESS";
export const SUBMIT_ANSWER_FAILURE = "SUBMIT_ANSWER_FAILURE";

/**
 * NEXT_QUESTION_REQUEST
 * NEXT_QUESTION_SUCCESS
 * NEXT_QUESTION_FAILURE
 *
 * Action type constants for fetching the next question in the placement test.
 *
 * @author - Gaurav
 */
export const NEXT_QUESTION_REQUEST = "NEXT_QUESTION_REQUEST";
export const NEXT_QUESTION_SUCCESS = "NEXT_QUESTION_SUCCESS";
export const NEXT_QUESTION_FAILURE = "NEXT_QUESTION_FAILURE";

/**
 * FETCH_QUESTION_REQUEST
 * FETCH_QUESTION_SUCCESS
 * FETCH_QUESTION_FAILURE
 *
 * Action type constants for fetching a specific question in the placement test.
 *
 * @author - Gaurav
 */
export const FETCH_QUESTION_REQUEST = "FETCH_QUESTION_REQUEST";
export const FETCH_QUESTION_SUCCESS = "FETCH_QUESTION_SUCCESS";
export const FETCH_QUESTION_FAILURE = "FETCH_QUESTION_FAILURE";

/**
 * SET_TOTAL_QUESTIONS
 *
 * Action type constant for setting the total number of questions in the test.
 *
 * @author - Gaurav
 */
export const SET_TOTAL_QUESTIONS = "SET_TOTAL_QUESTIONS";

/**
 * SET_QUESTIONS_ANSWERED
 * SET_QUESTIONS_SKIPPED
 *
 * Action type constants for setting the number of questions answered and skipped.
 *
 * @author - Gaurav
 */
export const SET_QUESTIONS_ANSWERED = "SET_QUESTIONS_ANSWERED";
export const SET_QUESTIONS_SKIPPED = "SET_QUESTIONS_SKIPPED";

/**
 * SET_ACCURACY_RATE
 *
 * Action type constant for setting the accuracy rate in the test.
 *
 * @author - Gaurav
 */
export const SET_ACCURACY_RATE = "SET_ACCURACY_RATE";

/**
 * SET_SELECTED_OPTION
 *
 * Action type constant for setting the selected option in a multiple-choice question.
 *
 * @author - Gaurav
 */
export const SET_SELECTED_OPTION = "SELECTED_OPTION";

/**
 * SET_SPEAKER_ACTIVE
 * SET_SPEAKER_INACTIVE
 *
 * Action type constants for toggling the speaker icon state.
 *
 * @author - Gaurav
 */
export const SET_SPEAKER_ACTIVE = "SET_SPEAKER_ACTIVE";
export const SET_SPEAKER_INACTIVE = "SET_SPEAKER_INACTIVE";

/**
 * CLICK_HOME_BUTTON
 *
 * Action type constant for clicking the home button.
 *
 * @author - Gaurav
 */
export const CLICK_HOME_BUTTON = "CLICK_HOME_BUTTON";
