import { Outlet } from "react-router-dom";
import HeaderTrack from "../../Components/Header/HeaderTrack";

const LayoutWithoutProfile = () => {
  return (
    <div>
      <HeaderTrack />
      <div className="container-fluid">
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default LayoutWithoutProfile;
