import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import siteLogo from "../../Assets/Images/siteLogo-new.webp";

const HeaderTrack = () => {
  return (
    <nav className="flex-content customHeader">
      <Link className="logoContainer">
        <img
          src={siteLogo}
          alt="Home"
          className="logoCustom"
          width={100}
          height={100}
          loading="lazy"
        />
      </Link>
    </nav>
  );
};

export default HeaderTrack;
