import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { parseLatex } from "../../../Utils/LatexUtils";
import questionTypeConfig from "../questionTypeConfig";

// image import
import Speaker from "../../../Assets/Images/speaker.svg";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";

// Suppress ResizeObserver errors
const handleError = (event) => {
  if (
    event.message ===
    "ResizeObserver loop completed with undelivered notifications."
  ) {
    event.stopImmediatePropagation();
  }
};

window.addEventListener("error", handleError);

const OptionComponent = ({
  setSelectedOptions,
  onTextInputChange,
  setHasTextBoxValue,
  setInputData,
  handleSpeak,
  speakingIndex,
}) => {
  const [textInputValues, setTextInputValues] = useState([""]);
  const [selectedOptions, updateSelectedOptions] = useState([]);
  const { questionType, options } = useSelector(
    (state) => state.testViewReducer
  );
  const [speechSynthesis, setSpeechSynthesis] = useState(
    () => window.speechSynthesis
  );
  const [imageClass, setImageClass] = useState("default");

  //   handle option selection
  const toggleOptionSelection = useCallback(
    (optionId) => {
      updateSelectedOptions((prevSelectedOptions) => {
        let newSelectedOptions;
        if (questionTypeConfig.isMultiSelect.includes(questionType)) {
          newSelectedOptions = prevSelectedOptions.includes(optionId)
            ? prevSelectedOptions.filter((id) => id !== optionId)
            : [...prevSelectedOptions, optionId];
        } else {
          newSelectedOptions = prevSelectedOptions.includes(optionId)
            ? []
            : [optionId];
        }
        setSelectedOptions(newSelectedOptions);
        return newSelectedOptions;
      });
    },
    [questionType, setSelectedOptions]
  );

  const handleImageLoad = useCallback((event) => {
    const img = event.target;
    setImageClass(
      img.naturalWidth === 400 && img.naturalHeight === 400
        ? "custom"
        : "default"
    );
  }, []);

  //   handle text input
  const handleTextInputChange = useCallback(
    (event, index) => {
      const newValue = event.target.value.trim();
      setTextInputValues((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = newValue;
        onTextInputChange(updatedValues);
        return updatedValues;
      });
    },
    [onTextInputChange]
  );

  const handleTextBoxChange = (event) => {
    const newValue = event.target.value.trim();
    setTextInputValues([newValue]);
    setInputData([newValue]);
    if (!newValue) {
      setHasTextBoxValue(false);
      setInputData([]);
    } else {
      setHasTextBoxValue(true);
      setInputData([newValue]);
    }
  };

  //   capitalize  letter for true/false
  const capitalizeFirstForTrueFalse = useCallback((text) => {
    const lowerText = text?.toLowerCase().trim();
    return lowerText === "true" || lowerText === "false"
      ? lowerText.toUpperCase()
      : text;
  }, []);

  //   render option
  const renderOptionText = useCallback(
    (option, index) => (
      <div
        key={option._id}
        className={`text-option-row ${selectedOptions.includes(option._id) ? "selected" : ""
          }`}
        onClick={() => toggleOptionSelection(option._id)}
      >
        <div
          className="option-speaker-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleSpeak(option.text, index);
          }}
        >
          <img
            src={speakingIndex === index ? activeSpeaker : Speaker}
            alt="Speaker"
          />
        </div>
        <div className="option-text-wrapper">
          <div
            className={`option-text ${selectedOptions.includes(option._id) ? "selected" : ""
              }`}
          >
            <div className="input-wrapper">
              {questionTypeConfig.isMultiSelect.includes(questionType) ? (
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option._id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOptionSelection(option._id);
                  }}
                  onChange={() => { }}
                  className="option-checkbox"
                />
              ) : (
                <input
                  type="radio"
                  className="option-radio"
                  name="single-select"
                  checked={selectedOptions.includes(option._id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOptionSelection(option._id);
                  }}
                />
              )}
            </div>
            <div
              className="optionText-box-container"
              dangerouslySetInnerHTML={{
                __html: parseLatex(capitalizeFirstForTrueFalse(option.text)),
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleOptionSelection(option._id);
              }}
            />
          </div>
        </div>
      </div>
    ),
    [
      selectedOptions,
      handleSpeak,
      toggleOptionSelection,
      questionType,
      capitalizeFirstForTrueFalse,
    ]
  );

  //   render option image
  const renderOptionImage = useCallback(
    (option) => (
      <div
        key={option._id}
        className={`option-image ${selectedOptions.includes(option._id) ? "selected" : ""
          }`}
      >
        <img
          src={option.image}
          alt={`Img ${option._id}`}
          className={selectedOptions.includes(option._id) ? "selected" : ""}
          onClick={() => toggleOptionSelection(option._id)}
          onLoad={handleImageLoad}
        />
        {questionTypeConfig.isMultiSelect.includes(questionType) ? (
          <input
            type="checkbox"
            checked={selectedOptions.includes(option._id)}
            onChange={() => toggleOptionSelection(option._id)}
          />
        ) : (
          <input
            type="radio"
            name="single-select"
            checked={selectedOptions.includes(option._id)}
            onClick={(e) => {
              e.stopPropagation();
              toggleOptionSelection(option._id);
            }}
          />
        )}
      </div>
    ),
    [selectedOptions, toggleOptionSelection, handleImageLoad, questionType]
  );

  const renderOptionTextAndImage = useCallback(
    (option, index) => (
      <div
        key={option._id}
        className={`option-and-image ${selectedOptions.includes(option._id) ? "selected" : ""
          }`}
      >
        <img
          src={option.image}
          alt={`Img ${option._id}`}
          onLoad={handleImageLoad}
        />
        <div className="speaker-and-text">
          <div
            className="optionText-box-container"
            dangerouslySetInnerHTML={{
              __html: parseLatex(capitalizeFirstForTrueFalse(option.text)),
            }}
            onClick={(e) => {
              e.stopPropagation();
              toggleOptionSelection(option._id);
            }}
          />
          <img
            src={speakingIndex === index ? activeSpeaker : Speaker}
            alt="Speaker"
            onClick={(e) => {
              e.stopPropagation();
              handleSpeak(option.text, index);
            }}
          />
        </div>
        {questionTypeConfig.isMultiSelect.includes(questionType) ? (
          <input
            type="checkbox"
            checked={selectedOptions.includes(option._id)}
            onChange={() => toggleOptionSelection(option._id)}
          />
        ) : (
          <input
            type="radio"
            name="single-select"
            checked={selectedOptions.includes(option._id)}
            onClick={(e) => {
              e.stopPropagation();
              toggleOptionSelection(option._id);
            }}
          />
        )}
      </div>
    ),
    [
      selectedOptions,
      toggleOptionSelection,
      handleImageLoad,
      handleSpeak,
      capitalizeFirstForTrueFalse,
    ]
  );

  const renderTextOptions = useMemo(
    () => (options ?? []).filter((option) => option.text).map(renderOptionText),
    [options, renderOptionText]
  );

  const renderImageOptions = useMemo(
    () => (options ?? []).map(renderOptionImage),
    [options, renderOptionImage]
  );

  const renderTextAndImageOptions = useMemo(
    () => (options ?? []).map(renderOptionTextAndImage),
    [options, renderOptionTextAndImage]
  );

  return (
    <div>
      {questionTypeConfig.textTypeOption.includes(questionType) && (
        <div
          className="text-options-container
          "
        //  m-5 mx-4 d-flex flex-column gap-4
        >
          {options
            .filter((option) => option.text && option.text.length > 0)
            .map((option, index) => (
              <div
                key={option._id}
                className={`text-option-row ${selectedOptions.includes(option._id) ? "selected" : ""
                  }`}
                onClick={() => toggleOptionSelection(option._id)}
              >
                <div
                  className="option-speaker-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSpeak(option.text, index);
                  }}
                >
                  <img
                    src={speakingIndex === index ? activeSpeaker : Speaker}
                    alt="Speaker"
                  // className="mt-4 ms-4"
                  />
                </div>
                <div className="option-text-wrapper ">
                  {questionTypeConfig.isMultiSelect.includes(questionType) ? (
                    <div
                      className={`option-text ${selectedOptions.includes(option._id) ? "selected" : ""
                        }`}
                    >
                      <div className="input-wrapper">
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option._id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleOptionSelection(option._id);
                          }}
                          onChange={() => { }}
                          className="option-checkbox"
                        />
                      </div>
                      <div className="optionText-box-container">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parseLatex(
                              capitalizeFirstForTrueFalse(option.text)
                            ),
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleOptionSelection(option._id);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`option-text ${selectedOptions.includes(option._id) ? "selected" : ""
                        }`}
                      onClick={(e) => toggleOptionSelection(e, option._id)}
                    >
                      <div className="input-wrapper">
                        <input
                          type="radio"
                          className="option-radio"
                          name="single-select"
                          checked={selectedOptions.includes(option._id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleOptionSelection(option._id);
                          }}
                        />
                      </div>
                      <div className="optionText-box-container">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parseLatex(
                              capitalizeFirstForTrueFalse(option.text)
                            ),
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleOptionSelection(option._id);
                          }}
                        />
                      </div>
                    </div>
                    // </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}

      {questionTypeConfig.imageTypeOption.includes(questionType) && (
        <div className={`image-container ${imageClass}`}>
          {options.map((option) => (
            <div
              className={`option-image ${selectedOptions.includes(option._id) ? "selected" : ""
                }`}
              key={option._id}
              onClick={() => toggleOptionSelection(option._id)}
            >
              <img
                src={option.image}
                alt={`Img ${option._id}`}
                className={
                  selectedOptions.includes(option._id)
                    ? "selected imageTypeOption"
                    : "imageTypeOption"
                }
                onLoad={handleImageLoad}
              />
              {questionTypeConfig.isMultiSelect.includes(questionType) ? (
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option._id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOptionSelection(option._id);
                  }}
                  className="mb-4 mt-4 d-flex flex-row align-items-center justify-content-center"
                />
              ) : (
                <input
                  type="radio"
                  name="single-select"
                  checked={selectedOptions.includes(option._id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOptionSelection(option._id);
                  }}
                  className="mb-4 mt-4"
                />
              )}
            </div>
          ))}
        </div>
      )}
      {questionTypeConfig.textAndImageTypeOption.includes(questionType) && (
        <div className={`image-container ${imageClass}`}>
          {options.map((option, index) => (
            <div key={option._id}>
              <div
                className={`option-and-image ${selectedOptions.includes(option._id) ? "selected" : ""
                  }`}
                onClick={() => toggleOptionSelection(option._id)}
              >
                <div style={{ minHeight: "11rem", height: "auto" }}>
                  {questionTypeConfig.textAnswerTypeInputBox.includes(questionType) ? null :
                    <img
                      src={option.image}
                      alt={`Img ${option._id}`}
                      onLoad={handleImageLoad}
                    />
                  }
                  <div className="speaker-and-text d-flex gap-1">
                    <div className="text-and-image">
                      <img
                        src={speakingIndex === index ? activeSpeaker : Speaker}
                        alt="Speaker"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSpeak(option.text, index);
                        }}
                      />
                    </div>
                    <div className="optionText-box-container">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: parseLatex(
                            capitalizeFirstForTrueFalse(option.text)
                          ),
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleOptionSelection(option._id);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {questionTypeConfig.isMultiSelect.includes(questionType) ? (
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option._id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptionSelection(option._id);
                    }}
                    style={{ marginBottom: "1.25rem" }}
                  />
                ) : (
                  <input
                    type="radio"
                    name="single-select"
                    checked={selectedOptions.includes(option._id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptionSelection(option._id);
                    }}
                    className="mb-4"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {questionTypeConfig.textAnswerTypeInputBox.includes(questionType) && (
        <div className="preview-options d-flex flex-column ms-4 mt-22 gap-4">
          <input
            type="text"
            value={textInputValues[0]}
            className="text-options"
            placeholder="Enter answer"
            onChange={handleTextBoxChange}
          />
        </div>
      )}
    </div>
  );
};

export default memo(OptionComponent);
