import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import questionTypeConfig from "../questionTypeConfig";
import { parseLatex } from "../../../Utils/LatexUtils";

// image import
import Speaker from "../../../Assets/Images/speaker.svg";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";

const QuestionComponent = ({
  onInputChange,
  questionType,
  questionText,
  questionImage,
  handleSpeak,
  speakingIndex,
}) => {
  // state
  const [imageStyle, setImageStyle] = useState({});
  const [inputValues, setInputValues] = useState([]);
  const [marginBottom, setMarginBottom] = useState("1rem");
  const [isSpeaking, setIsSpeaking] = useState(false);

  // useRef
  const inputRefs = useRef({});
  const questionContainerRef = useRef(null);

  const handleInputChange = (event, index) => {
    const newValue = event.target.value.trim();
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = newValue;
      const updatedValuesFilter = updatedValues.filter(
        (item) => item !== "" && item !== undefined && item !== null
      );
      onInputChange(updatedValuesFilter);
      return updatedValues;
    });

    // Adjust the input width based on the content
    adjustInputWidth(event.target, inputRefs.current[index]);
  };

  const adjustInputWidth = (input, span) => {
    if (span) {
      span.textContent = input.value || input.placeholder;
      input.style.width = `${span.offsetWidth + 10}px`;
    }
  };

  // handle image load
  const handleImageLoad = (event) => {
    const img = event.target;
    setImageStyle(
      img.naturalWidth === 400 && img.naturalHeight === 400
        ? {
            borderRadius: "0.25rem",
            objectFit: "contain",
          }
        : {}
    );
  };

  useEffect(() => {
    if (questionContainerRef.current) {
      const height = questionContainerRef.current.offsetHeight;
      setMarginBottom(`${height * 0.1}px`);
    }
  }, [questionType]);

  const isFirstBlank =
    questionText !== undefined && questionText.startsWith("<#@blank#>");

  const convertBlank = (questionText) =>
    questionText.split("<#@blank#>").join("_______");

  return (
    <div className="question-container" ref={questionContainerRef}>
      {questionTypeConfig.textTypeQuestion.includes(questionType) && (
        <div
          className={
            questionTypeConfig.fillInTheBlanksType.includes(questionType)
              ? "question-text-part-fill-in-the-blanks"
              : "question-text-part"
          }
        >
          <div className="text-question">
            <div
              className={`${
                isFirstBlank &&
                !questionTypeConfig.fillInTheBlanksType.includes(questionType)
                  ? "first-blank-speaker-icon"
                  : questionTypeConfig.fillInTheBlanksType.includes(
                      questionType
                    )
                  ? "fill-in-the-blank-speaker-icon"
                  : "question-speaker-icon"
              } 
                ${isSpeaking ? "enlarged" : ""}`}
              onClick={() => handleSpeak(questionText, "textQuestion")}
            >
              <img
                src={speakingIndex === "textQuestion" ? activeSpeaker : Speaker}
                alt="Speaker"
              />
            </div>
            {questionTypeConfig.fillInTheBlanksType.includes(questionType) ? (
              <div className="text-with-blanks">
                {questionText.split(/(<#@blank#>)/).map((part, index) => (
                  <React.Fragment key={index}>
                    {part === "<#@blank#>" ? (
                      <span className="fill-in-the-blank-container d-inline-block align-items-center">
                        <span className="fill-in-the-blank-wrapper position-relative">
                          <input
                            type="text"
                            className="fill-in-the-blank"
                            placeholder="Enter Answer"
                            onChange={(event) =>
                              handleInputChange(event, index)
                            }
                            style={{ minWidth: "12ch" }}
                            maxLength={40}
                          />
                          <span
                            ref={(el) => (inputRefs.current[index] = el)}
                            className="fill-in-the-blank-hidden position-absolute d-none fs-6"
                          />
                          <span className="fill-in-the-blank-highlight"></span>
                        </span>
                      </span>
                    ) : (
                      <span
                        key={index}
                        className="text-part"
                        dangerouslySetInnerHTML={{ __html: parseLatex(part) }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: convertBlank(parseLatex(questionText)),
                }}
                className="question-box-component"
              />
            )}
          </div>
        </div>
      )}
      {questionTypeConfig.imageTypeQuestion.includes(questionType) && (
        <div className="question-image-part d-flex justify-content-center align-items-center flex-grow-1 pt-0 mt-3">
          <img
            src={questionImage}
            alt="Question Img"
            loading="lazy"
            style={imageStyle}
            onLoad={handleImageLoad}
          />
        </div>
      )}
      {questionTypeConfig.textAndImageTypeQuestion.includes(questionType) && (
        <>
          <div className="question-text-image-part d-flex flex-row">
            <div
              className={`${
                isFirstBlank &&
                !questionTypeConfig.fillInTheBlanksWithImageType.includes(
                  questionType
                )
                  ? "first-blank-speaker-icon"
                  : questionTypeConfig.fillInTheBlanksWithImageType.includes(
                      questionType
                    )
                  ? "blanks-with-image-speaker-icon"
                  : questionTypeConfig.fillInTheBlanksType.includes(
                      questionType
                    )
                  ? "fill-in-the-blank-speaker-icon"
                  : "question-speaker-icon-with-image"
              } 
                ${isSpeaking ? "enlarged" : ""}`}
              onClick={() => handleSpeak(questionText, "textAndImageQuestion")}
            >
              <img
                src={
                  speakingIndex === "textAndImageQuestion"
                    ? activeSpeaker
                    : Speaker
                }
                alt="Speaker"
              />
            </div>
            <div
              className={
                questionTypeConfig.fillInTheBlanksType.includes(questionType)
                  ? "question-text-part-fill-in-the-blanks"
                  : "question-text-part"
              }
              // style={{ marginRight: "1.5rem" }}
            >
              {questionTypeConfig.fillInTheBlanksType.includes(questionType) ? (
                <div className="text-with-blanks pe-4">
                  {questionText.split(/(<#@blank#>)/).map((part, index) => (
                    <React.Fragment key={index}>
                      {part === "<#@blank#>" ? (
                        <span className="fill-in-the-blank-container d-inline-block align-items-center">
                          <span className="fill-in-the-blank-wrapper position-relative">
                            <input
                              type="text"
                              className="fill-in-the-blank"
                              placeholder="Enter Answer"
                              onChange={(event) =>
                                handleInputChange(event, index)
                              }
                              style={{ minWidth: "12ch" }}
                              maxLength={40}
                            />
                            <span
                              ref={(el) => (inputRefs.current[index] = el)}
                              className="fill-in-the-blank-hidden position-absolute d-none fs-6"
                            />
                            <span className="fill-in-the-blank-highlight"></span>
                          </span>
                        </span>
                      ) : (
                        <span
                          key={index}
                          className="text-part"
                          dangerouslySetInnerHTML={{ __html: parseLatex(part) }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: convertBlank(parseLatex(questionText)),
                  }}
                  className="question-box-text-with-image"
                />
              )}
            </div>
          </div>
          <div className="question-image-part d-flex justify-content-center align-items-center flex-grow-1 pt-0 mt-3">
            <img src={questionImage} alt="Question Imag" loading="lazy" />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(QuestionComponent);
