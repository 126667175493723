export const SET_ASSIGNMENT_ID = "SET_ASSIGNMENT_ID";

export const BENCHMARK_TEST_FINISHED = "BENCHMARK_TEST_FINISHED";

export const BENCHMARK_TEST_COMPLETED = "BENCHMARK_TEST_COMPLETED";

export const RESET_TEST_STATE = "RESET_TEST_STATE";

export const START_BENCHMARK_TEST_REQUEST = "START_BENCHMARK_TEST_REQUEST";
export const START_BENCHMARK_TEST_SUCCESS = "START_BENCHMARK_TEST_SUCCESS";
export const START_BENCHMARK_TEST_FAILURE = "START_BENCHMARK_TEST_FAILURE";
export const START_RC_BENCHMARK_TEST_SUCCESS =
  "START_RC_BENCHMARK_TEST_SUCCESS";

export const BENCHMARK_SUBMIT_ANSWER_REQUEST =
  "BENCHMARK_SUBMIT_ANSWER_REQUEST";
export const BENCHMARK_SUBMIT_ANSWER_SUCCESS =
  "BENCHMARK_SUBMIT_ANSWER_SUCCESS";
export const BENCHMARK_SUBMIT_ANSWER_FAILURE =
  "BENCHMARK_SUBMIT_ANSWER_FAILURE";

export const BENCHMARK_NEXT_QUESTION_REQUEST =
  "BENCHMARK_NEXT_QUESTION_REQUEST";
export const BENCHMARK_NEXT_QUESTION_SUCCESS =
  "BENCHMARK_NEXT_QUESTION_SUCCESS";
export const BENCHMARK_NEXT_QUESTION_FAILURE =
  "BENCHMARK_NEXT_QUESTION_FAILURE";
export const BENCHMARK_RC_NEXT_QUESTION_SUCCESS =
  "BENCHMARK_RC_NEXT_QUESTION_SUCCESS";

export const FETCH_QUESTION_REQUEST = "FETCH_QUESTION_REQUEST";
export const FETCH_QUESTION_SUCCESS = "FETCH_QUESTION_SUCCESS";
export const FETCH_QUESTION_FAILURE = "FETCH_QUESTION_FAILURE";

export const SET_TOTAL_QUESTIONS = "SET_TOTAL_QUESTIONS";

export const SET_QUESTIONS_ANSWERED = "SET_QUESTIONS_ANSWERED";
export const SET_QUESTIONS_SKIPPED = "SET_QUESTIONS_SKIPPED";

export const SET_ACCURACY_RATE = "SET_ACCURACY_RATE";

export const SET_SELECTED_OPTION = "SELECTED_OPTION";

export const SET_SPEAKER_ACTIVE = "SET_SPEAKER_ACTIVE";
export const SET_SPEAKER_INACTIVE = "SET_SPEAKER_INACTIVE";

export const CLICK_HOME_BUTTON = "CLICK_HOME_BUTTON";
