import { useState, useEffect, useCallback, useMemo } from "react";
import "./HomeButtonGroup.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// utils imports
import {
  updateStateBasedOnPath,
  handleTabNavigation,
} from "./homeButtonGroupUtils";

const HomeButtonGroup = () => {
  // state
  const [practiceTrue, setPracticeTrue] = useState(true);
  const [progressTrue, setProgressTrue] = useState(false);
  const [assignTest, setAssignTest] = useState(false);
  const [libraryTrue, setLibraryTrue] = useState(false);

  //   redux selectors
  const showAssign = useSelector((state) => state.icons.staffAccess);
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.details);

  // update state based on path
  useEffect(() => {
    updateStateBasedOnPath(
      location.pathname,
      setAssignTest,
      setProgressTrue,
      setPracticeTrue,
      setLibraryTrue
    );
  }, [location.pathname]);

  // handle tab
  const handleTab = useCallback(
    (page) => {
      handleTabNavigation(
        page,
        location.pathname,
        navigate,
        setPracticeTrue,
        setProgressTrue,
        setAssignTest,
        setLibraryTrue
      );
    },
    [location.pathname, navigate]
  );

  const isSpellingAndVocabulary = location.pathname.includes("spelling") || location.pathname.includes("vocabulary");
  const isStaff = location.pathname.includes("staff") 

  // button group
  const buttonGroupClasses = useMemo(() => {
    return {
      practice: practiceTrue  
        ? "custom-highlight-btn custom-btn fw-semi-bold"
        : progressTrue ? "practice-btn-label custom-btn fw-medium me-4"
        :"practice-btn-label custom-btn fw-medium",
      progress: progressTrue
        ? "custom-highlight-btn progress-Custom custom-btn fw-semi-bold"
        : assignTest || libraryTrue
          ? "custom-btn progress-assign-btn-label fw-medium"
          : "custom-btn progress-btn-label fw-medium",
      library: (libraryTrue && showAssign && location.pathname.includes('reading'))
        ? "custom-highlight-btn custom-btn library-test-highlight fw-semi-bold ms-4"
        : libraryTrue ? "custom-highlight-btn custom-btn library-assign-test-highlight fw-semi-bold"
        : assignTest ? 
        "custom-btn progress-assign-btn-label fw-medium" :
        "progress-btn-label practice-btn-label custom-btn fw-medium",
      assign: assignTest
        ? "custom-highlight-btn custom-btn assign-test-highlight fw-semi-bold"
        : "progress-btn-label practice-btn-label custom-btn fw-medium",
    };
  }, [practiceTrue, progressTrue, assignTest, libraryTrue]);

  return (
    isAuthenticated && !isStaff && (
      <div className='custom-button-group d-flex align-items-center'>
        <div
          className={buttonGroupClasses.practice} 
          onClick={() => handleTab("practice")}
        >
          {isSpellingAndVocabulary ? "Practice" : "Assignments"}
        </div>
        <div
          className={buttonGroupClasses.progress}
          onClick={() => handleTab("progress")}
        >
          Progress
        </div>
        { (location.pathname.includes("math") || location.pathname.includes("language") || 
          location.pathname.includes("reading")) &&
        (<div
          className={buttonGroupClasses.library}
          onClick={() =>handleTab("library")}>
            Library
        </div>)}
        {!isSpellingAndVocabulary && showAssign && (
          <div
            className={buttonGroupClasses.assign}
            onClick={() => handleTab("assign")}
          >
            Assign
          </div>
        )}
      </div>
    )
  );
};

export default HomeButtonGroup;