import React, { useEffect, useState } from "react";
import "./Error404.css";
import { useNavigate } from "react-router-dom";
const Error404 = () => {
  const navigate = useNavigate();
  const [digit1, setDigit1] = useState(null);
  const [digit2, setDigit2] = useState(null);
  const [digit3, setDigit3] = useState(null);

  // Digit animation for 404
  useEffect(() => {
    const randomNum = () => Math.floor(Math.random() * 9) + 1;
    let i = 0;
    const loop3 = setInterval(() => {
      if (i > 40) {
        clearInterval(loop3);
        setDigit3(4);
      } else {
        setDigit3(randomNum());
        i++;
      }
    }, 30);

    const loop2 = setInterval(() => {
      if (i > 80) {
        clearInterval(loop2);
        setDigit2(0);
      } else {
        setDigit2(randomNum());
        i++;
      }
    }, 30);

    const loop1 = setInterval(() => {
      if (i > 100) {
        clearInterval(loop1);
        setDigit1(4);
      } else {
        setDigit1(randomNum());
        i++;
      }
    }, 30);

    return () => {
      clearInterval(loop1);
      clearInterval(loop2);
      clearInterval(loop3);
    };
  }, []);

  return (
    <div className="error">
      <div className="container-floud">
        <div className="col-xs-12 ground-color text-center">
          <div className="container-error-404">
            <div className="clip">
              <div className="shadow">
                <span className="digit thirdDigit">{digit3}</span>
              </div>
            </div>
            <div className="clip">
              <div className="shadow">
                <span className="digit secondDigit">{digit2}</span>
              </div>
            </div>
            <div className="clip">
              <div className="shadow">
                <span className="digit firstDigit">{digit1}</span>
              </div>
            </div>
            <div className="msg">
              OH!<span className="triangle"></span>
            </div>
          </div>
          <h2 className="h1">Sorry! Page not found</h2>
          <div className="home-button-container">
            <button
              className="home-button"
              onClick={() => navigate("/math/assignments")}
            >
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
