import "./Header.css";
import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import siteLogo from "../../Assets/Images/siteLogo-new.webp";
import { useAuthenticate } from "../../hooks/useAuthenticate";
// components
import HeaderItem from "./HeaderItem";
import ProfileDropdown from "./ProfileDropdown";
// slice
import { setPresentProgram } from "../../redux/slices/studentDetails/studentDetailsSlice";
import { setLargeIcon, setProgress } from "../../redux/slices/icons/iconsSlice";
import { clearLibraryDomains, setLibraryLoading } from "../../redux/slices/library/librarySlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { roleType } = useAuthenticate()
  const { staffData } = useAuthenticate()
  if (roleType !== "") {

    localStorage.setItem("roleType", roleType)
  }
  
  // redux selectors
  const {
    isAuthenticated,
    firstName,
    profilePic,
    role,
    staffFirstName,
    staffLastName,
    lastName,
  } = useSelector((state) => state.details);

  // get current pathname
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();
  const isMath = pathname.includes("/math");
  const isEla = pathname.includes("/ela");
  const isStaff = pathname.includes("/staff");
  const isReading = pathname.includes("/reading");
  const isWriting = pathname.includes("/writing");

  // handle page highlight
  const handlePageHighlight = (page) => {
    dispatch(setLargeIcon());
    dispatch(setProgress());
    dispatch(setPresentProgram(page.charAt(0).toUpperCase() + page.slice(1)));
    dispatch(setLibraryLoading())
    dispatch(clearLibraryDomains())
    if (page === "ela") {
      navigate(`/ela/language/assignments`);
    } else if (page === "staff") {
      navigate(`staff/library`);
    } else {
      navigate(`/${page}/assignments`);
    }
  };

  useEffect(() => {
    if (pathname === "`/ela/`assignments") {
      navigate(`/ela/language/assignments`);
    }
  }, [pathname, navigate]);

  return (
    isAuthenticated && (
      <nav className="d-flex justify-content-between customHeader">
        <div className="d-flex align-items-center">
          <Link className="logoContainer">
            <img src={siteLogo} alt="Home" className="logoCustom" />
          </Link>
          <div className="d-flex align-items-center program-container">
            <HeaderItem
              label="Math"
              page="math"
              isHighlighted={isMath}
              onClick={handlePageHighlight}
            />
            <HeaderItem
              label="ELA"
              page="ela"
              isHighlighted={isEla}
              onClick={handlePageHighlight}
            />

            {(localStorage.getItem('roleType') === "student" || localStorage.getItem('roleType') === "parent") ? null : (
              <HeaderItem
                label="Staff"
                page="staff"
                isHighlighted={isStaff}
                onClick={() => {
                  handlePageHighlight("staff");
                  localStorage.setItem('staffLibrary', true);
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex align-items-center ">
          <div
            className="d-flex align-items-center profile-container
            alignAvatarName fw-semi-bold position-relative"
          >
            <div>
              <div className="student-name float-end">
                {`${firstName} ${lastName}`}
              </div>
              {role !== "student" && (
                <div className="teacher-name fw-normal">
                  {`${staffFirstName} ${staffLastName}`} | {role}
                </div>
              )}
            </div>
            {profilePic ? (
              <img src={profilePic} alt="Avatar" className="custom-avatar" />
            ) : (
              <div className="default-avatar-container d-inline-flex align-items-center justify-content-center">
                <i className="bi bi-person-fill default-avatar-icon"></i>
              </div>
            )}
          </div>
          <ProfileDropdown
            profilePic={profilePic}
            firstName={firstName}
            lastName={lastName}
            role={role}
            staffFirstName={staffFirstName}
            staffLastName={staffLastName}
          />
        </div>
      </nav>
    )
  );
};

export default Header;
