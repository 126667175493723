import React from "react";

const Spinner = () => {
  return (
    <div
      className="spinner-border spinner-border-sm text-light"
      role="status"
    />
  );
};

export default Spinner;
