const BASE_URL = process.env.REACT_APP_BASE_URL;

export const startWritingTestApi = async (token, assignmentId) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/startWritingTest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ assignmentId }),
    });

    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("startWritingTestApi response data:", data); 

    return data || {}; 
  } catch (error) {
    console.error("Error in startWritingTestApi:", error);
    throw new Error("Network response was not ok");
  }
};

export const saveWritingTestApi = async (token, assignmentId, questionId, timeSpent, answer, status) => {
  console.log("Checking the data before API call", token, assignmentId, questionId, answer, status);
  
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/saveWritingTest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ assignmentId, questionId, timeSpent, answer, status }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("saveWritingTestApi response data:", data); // Log response data
    return data || {};
  } catch (error) {
    console.error("Error in saveWritingTestApi:", error);
    throw new Error("Network response was not ok");
  }
};

export const submitWritingTestApi = async (token, assignmentId, questionId, answer, status, rubrics) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/submitWritingTest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ assignmentId, questionId, status, answer, rubrics }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("submitWritingTestApi response data:", data); // Log response data
    return data || {};
  } catch (error) {
    console.error("Error in submitWritingTestApi:", error);
    throw new Error("Network response was not ok");
  }
};

export const fetchWritingDetailsApi = async (token, assignmentId) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/getWritingAssignmentDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ assignmentId }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("fetchWritingDetailsApi response data:", data);
    return data || {};
  } catch (error) {
    console.error("Error in fetchWritingDetailsApi:", error);
    throw new Error("Network response was not ok");
  }
};
