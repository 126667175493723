import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./GradeWiseDetailedReport.css";
import { FaPlay } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  clearMasteryData,
  getDomainsMasteryByLevelIdThunk,
  getSkillsMasteryByTopicIdThunk,
  getTopicsMasteryByDomainIdThunk,
} from "../../redux/slices/gradeLevelMasteryReport/gradeLevelMasteryReportSlice.js";
import Loader from "../Loader/Loader";
import { useAuthenticate } from "../../hooks/useAuthenticate";

const GradeWiseDetailedReport = () => {
  const [expandedItems, setExpandedItems] = useState({
    domains: [],
    topics: [],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const grade = queryParams.get("grade");
  const gradeId = queryParams.get("gradeId");
  const masteryType = queryParams.get("type");
  const authToken = useSelector((state) => state.details.sdaccessToken);
  const programs = useSelector((state) => state.details.enrolledPrograms);
  const { authResult, loading: authLoading } = useAuthenticate();

  // Redux state for domains (topics are nested inside each domain)
  const { data, loading, topicsLoading, skillsLoading } = useSelector(
    (state) => state.gradeMastery
  );
  const dispatch = useDispatch();

  const getProgramId = useCallback(() => {
    const programMapping = {
      language: "ELA",
      math: "Math",
      reading: "Reading",
      writing: "Writing"
    };
    const pathSegment = pathname.split("/").slice(-3)[0];
    const programTitle = programMapping[pathSegment.toLowerCase()];
    const program = programs.find((p) => p.title === programTitle);
    return program ? program._id : "";
  }, [pathname, programs]);
  const programId = getProgramId();

  useEffect(() => {
    return () => {
      dispatch(clearMasteryData());
    };
  }, []);

  useEffect(() => {
    const fetchDomainsData = async () => {
      if (!authLoading && authResult) {
        if (programId && gradeId && authToken && masteryType) {
          dispatch(
            getDomainsMasteryByLevelIdThunk({
              token: authToken,
              programId,
              levelId: gradeId ,
              masteryType: !pathname.includes('reading') ? masteryType === "current" ? 1 : 0 : "",
            })
          );
        }
      }
    };
    fetchDomainsData();
  }, [authLoading]);

const toggleExpand = useCallback((type, identifier) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [type]: prevState[type][0] === identifier ? [] : [identifier],
    }));
  }, []);

  const handleBackClick = useCallback(() => navigate(-1), [navigate]);

  const getPlayButtonClass = (identifier, type) =>
    expandedItems[type].includes(identifier) ? "play-button-rotated" : "";

  const getActiveBorderClass = (identifier, type) =>
    expandedItems[type].includes(identifier) ? "active-domain-border" : "";
  const getActiveTitleColor = (identifier, type) =>
    expandedItems[type].includes(identifier) ? "active-domain-title-color" : "";

  const formatGWScore = (score) => {
    if (score === null || score === undefined || isNaN(score)) {
      return "N/A";
    }
    const formattedScore = Math.abs(score);
    return formattedScore % 1 === 0
      ? formattedScore.toFixed(0)
      : formattedScore.toFixed(2);
  };

  const getScoreClass = (score) => {
    return Math.abs(score) < 50 ? "low-score" : "high-score";
  };

  const handleDomainClick = (domainId) => {
    // If topics are not yet loaded for the domain, fetch them
    const domain = data?.domains?.find((domain) => domain._id === domainId);
    if (domain && !domain.topics && gradeId && authToken) {
      dispatch(
        getTopicsMasteryByDomainIdThunk({
          token: authToken,
          programId,
          levelId: gradeId,
          domainId,
          masteryType: masteryType === "current" ? 1 : 0,
        })
      );
    }
  };

  const handleTopicClick = (topicId) => {
    const domain = data?.domains?.find((domain) =>
      domain?.topics?.find((topic) => topic._id === topicId)
    );
    const topic = domain?.topics?.find((topic) => topic._id === topicId);


    if (domain && topic && !topic.skills && gradeId && authToken) {
      const domainId = domain?._id;

      dispatch(
        getSkillsMasteryByTopicIdThunk({
          token: authToken,
          programId,
          levelId: gradeId,
          domainId,
          topicId,
          masteryType: masteryType === "current" ? 1 : 0,
        })
      );
    }
  };
  
  if (loading || authLoading) return <Loader />;

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="gw-wrapper">
        <div className="gw-main-wrapper d-flex justify-content-center align-items-center">
          <div className="gw-main-container">
            <div className="gw-header d-flex justify-content-between align-items-center p-4">
              <div className="gw-grade">Grade {grade}</div>
              <div className="gw-overall-score-wrapper d-flex justify-content-center align-items-center">
                <div className="gw-overall-score">OVERALL LEVEL SCORE</div>
                <div
                  className={`gw-overall-score-percentage ${getScoreClass(
                    data?.levelScore
                  )}`}
                >
                  {formatGWScore(Math.abs(data?.levelScore) || 0)}%
                </div>
              </div>
            </div>
            <div className="gw-divider"></div>
            {data?.domains?.length > 0 ? (
              data?.domains?.map((domain) => (
                <React.Fragment key={domain._id}>
                  <div
                    className={`gw-domain-wrapper d-flex justify-content-between align-items-center p-4 border ${getActiveBorderClass(
                      domain.title, "domains"
                    )} `}
                    onClick={() => {
                      toggleExpand("domains", domain.title);
                      handleDomainClick(domain._id);
                    }}
                  >
                    <FaPlay
                      className={`results-play-button ${getPlayButtonClass(
                        domain.title,
                        "domains"
                      )}`}
                    />
                    <div className={`gw-domain-text ${getActiveTitleColor(domain.title, "domains")}`}>{domain.title}</div>
                    <div
                      className={`gw-domain-score ${getScoreClass(
                        domain.domainScore
                      )}`}
                    >
                      {formatGWScore(Math.abs(domain.domainScore || 0))}%
                    </div>
                  </div>
                  {expandedItems.domains.includes(domain.title) &&
                    (topicsLoading ? (
                      <div className="loading-dots" style={{ padding: "1rem" }}>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    ) : (
                      domain.topics?.length > 0 ? (
                        domain.topics?.map((topic) => (
                          <React.Fragment key={topic._id}>
                            <div
                              className={`gw-topic-wrapper d-flex justify-content-between align-items-center p-4 border ${getActiveBorderClass(topic.title, "topics")} `}
                              onClick={() => {
                                toggleExpand("topics", topic.title);
                                handleTopicClick(topic._id);
                              }}
                            >
                              <FaPlay
                                className={`results-play-button ${getPlayButtonClass(
                                  topic.title,
                                  "topics"
                                )}`}
                              />
                              <div className={`gw-topic-text ${getActiveTitleColor(topic.title, "topics")} `}>{topic.title}</div>
                              <div
                                className={`gw-topic-score ${getScoreClass(
                                  topic.topicScore
                                )}`}
                              >
                                {formatGWScore(Math.abs(topic.topicScore || 0))}%
                              </div>
                            </div>
                            {expandedItems.topics.includes(topic.title) &&
                              (skillsLoading ? (
                                <div
                                  className="loading-dots"
                                  style={{ padding: "1rem" }}
                                >
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              ) : ( topic?.skills?.length > 0 ? (
                                topic.skills?.map((skill) => (
                                  <div
                                    key={skill._id}
                                    className="gw-skill-wrapper d-flex justify-content-between align-items-center p-4 border"
                                  >
                                    <div className="gw-skill-text">
                                      {skill.title}
                                    </div>
                                    <div
                                      className={`gw-skill-score ${getScoreClass(
                                        skill.skillScore
                                      )}`}
                                    >
                                      {formatGWScore(
                                        Math.abs(skill.skillScore || 0)
                                      )}
                                      %
                                    </div>
                                  </div>
                                ))) : (
                                  <div className="gw-skill-wrapper d-flex 
                                      justify-content-between align-items-center 
                                      p-4 border gw-skill-text">
                                    Skills not available for the selected topic
                                  </div>
                                )
                              ))}
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="gw-topic-wrapper gw-skill-text d-flex 
                          justify-content-between align-items-center p-4 
                          border">Topics not available for the selected domain
                        </div>
                      )
                     
                    ))}
                </React.Fragment>
              ))
            ) : (
              <div className="gw-no-details-message text-center">
                No details available
              </div>
            )}
          </div>
        </div>
        <div
          className="gw-button-container d-flex justify-content-end"
          style={{ marginRight: data?.domains?.length > 0 ? "0.8rem" : "2rem" }}
        >
          <button className="gw-back-button" onClick={handleBackClick}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default GradeWiseDetailedReport;
