import {
  startPlacementTestAPI,
  placementTestNextQuestionAPI,
} from "../../Utils/ApiHandler/PlacementTestApi";
import {
  TEST_COMPLETED,
  TEST_ENDED,
  RESET_TEST_STATE,
  SET_ASSIGNMENT_ID,
  SUBMIT_ANSWER_REQUEST,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAILURE,
  START_PLACEMENT_TEST_REQUEST,
  START_PLACEMENT_TEST_SUCCESS,
  START_PLACEMENT_TEST_FAILURE,
  NEXT_QUESTION_REQUEST,
  NEXT_QUESTION_SUCCESS,
  NEXT_QUESTION_FAILURE,
} from "../actionTypes/placementTestActionTypes";

export const startPlacementTest =
  (assignmentId, programName, token, timeSpent) => async (dispatch) => {
    dispatch({
      type: START_PLACEMENT_TEST_REQUEST,
    });
    try {
      const response = await startPlacementTestAPI(
        assignmentId,
        programName,
        token,
        timeSpent
      );
      // console.log("response--->", response);
      let data = {
        level: response.data.currentLevel,
        accuracy: response.data.accuracyRate,
        complexity: response.data?.complexity,
        code: response.data?.code,
      };
      console.log("Current question Details", {
        accuracyRate: response?.data?.accuracyRate,
        difficulty: response?.data?.currentQuestion?.difficulty,
      });
      // console.log("Current question details", data);

      if (
        response.status === 500 ||
        response.message === "Failure" ||
        response.data.isCompleted
      ) {
        console.log("ressponse", response.status);
        localStorage.setItem("placementCompleted",1);
        dispatch({ type: TEST_COMPLETED, payload: response.data });
      } else {
        localStorage.setItem("placementCompleted",0);
        dispatch({
          type: START_PLACEMENT_TEST_SUCCESS,
          payload: response.data,
        });
        return data;
      }
    } catch (error) {
      dispatch({ type: SET_ASSIGNMENT_ID, payload: assignmentId });
      dispatch({ type: START_PLACEMENT_TEST_FAILURE, payload: error.message });
      return;
    }
  };

export const placementTestNextQuestion =
  (
    questionId,
    assignmentId,
    submittedAnswer,
    isSkipped,
    timeSpentPerQuestion,
    programName,
    token,
    isCompleted
  ) =>
  async (dispatch) => {
    dispatch({
      type: NEXT_QUESTION_REQUEST,
    });
    try {
      const response = await placementTestNextQuestionAPI(
        questionId,
        assignmentId,
        submittedAnswer,
        isSkipped,
        timeSpentPerQuestion,
        programName,
        token,
        isCompleted
      );
      console.log("Current question Details", {
        accuracyRate: response?.data?.accuracyRate,
        difficulty: response?.data?.currentQuestion?.difficulty,
      });
      let data = {
        level: response.data.currentLevel,
        accuracy: response.data.accuracyRate,
        complexity: response.data?.complexity,
        code: response.data?.code,
      };
      if (response.data.isCompleted === 1) {
        localStorage.setItem("placementCompleted",1);
        dispatch({ type: TEST_ENDED, payload: response.data });
      } else if (response.message === "Test Completed") {
        dispatch({ type: TEST_ENDED, payload: response.data });
      } else if (response.message === "Success") {
        localStorage.setItem("placementCompleted",0);
        dispatch({ type: NEXT_QUESTION_SUCCESS, payload: response.data });
        return data;
      }
    } catch (error) {
      dispatch({ type: NEXT_QUESTION_FAILURE, payload: error.message });
      return;
    }
  };
export const testCompleted = (data) => ({
  type: TEST_COMPLETED,
  payload: data,
});

export const setAssignmentId = (assignmentId) => ({
  type: SET_ASSIGNMENT_ID,
  payload: assignmentId,
});

export const setQuestionId = (questionId) => ({
  type: SET_ASSIGNMENT_ID,
  payload: questionId,
});
export const resetTestState = () => ({
  type: RESET_TEST_STATE,
});
