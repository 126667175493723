/**
 * SET_ASSIGNMENT_ID
 *
 * Action type constant for setting the assignment ID.
 *
 */
export const SET_ASSIGNMENT_ID = "SET_ASSIGNMENT_ID";

export const SET_RC_ASSIGNMENT_ID = "SET_RC_ASSIGNMENT_ID";

/**
 * TEST_FINISHED
 *
 * Action type constant for setting the assignment ID.
 *
 */
export const SKILL_PRACTICE_FINISHED = "SKILL_PRACTICE_FINISHED";

export const RC_SKILL_PRACTICE_FINISHED = "RC_SKILL_PRACTICE_FINISHED";

/**
 * PRACTICE_FINISHED
 *
 * Action type constant for setting the assignment ID.
 *
 */
export const SKILL_PRACTICE_COMPLETED = "SKILL_PRACTICE_COMPLETED";

export const SKILL_FINISHED = "SKILL_FINISHED";

export const SKILL_QUESTIONS_COUNT = "SKILL_QUESTIONS_COUNT";

export const RESET_TEST_STATE = "RESET_TEST_STATE";

export const RC_SKILL_PRACTICE_COMPLETED = "RC_SKILL_PRACTICE_COMPLETED";

export const RC_SKILL_FINISHED = "RC_SKILL_FINISHED";

export const RC_SKILL_QUESTIONS_COUNT = "RC_SKILL_QUESTIONS_COUNT";

export const RC_RESET_TEST_STATE = "RC_RESET_TEST_STATE";
/**
 * SET_QUESTION_ID
 *
 * Action type constant for setting the Question ID.
 *
 */
// export const SET_QUESTION_ID = "SET_QUESTION_ID";

/**
 * START_BENCHMARK_TEST_REQUEST
 * START_BENCHMARK_TEST_SUCCESS
 * START_BENCHMARK_TEST_FAILURE
 *
 * Action type constants for starting the benchmark test.
 *
 */
export const START_SKILL_PRACTICE_REQUEST = "START_SKILL_PRACTICE_REQUEST";
export const START_SKILL_PRACTICE_SUCCESS = "START_SKILL_PRACTICE_SUCCESS";
export const START_SKILL_PRACTICE_FAILURE = "START_SKILL_PRACTICE_FAILURE";

export const START_RC_SKILL_PRACTICE_REQUEST =
  "START_RC_SKILL_PRACTICE_REQUEST";
export const START_RC_SKILL_PRACTICE_SUCCESS =
  "START_RC_SKILL_PRACTICE_SUCCESS";
export const START_RC_SKILL_PRACTICE_FAILURE =
  "START_RC_SKILL_PRACTICE_FAILURE";

/**
 * SUBMIT_ANSWER_REQUEST
 * SUBMIT_ANSWER_SUCCESS
 * SUBMIT_ANSWER_FAILURE
 *
 * Action type constants for submitting an answer in the benchmark test.
 *
 */
export const SKILL_SUBMIT_ANSWER_REQUEST = "SKILL_SUBMIT_ANSWER_REQUEST";
export const SKILL_SUBMIT_ANSWER_SUCCESS = "SKILL_SUBMIT_ANSWER_SUCCESS";
export const SKILL_SUBMIT_ANSWER_FAILURE = "SKILL_SUBMIT_ANSWER_FAILURE";

export const RC_SKILL_SUBMIT_ANSWER_REQUEST = "RC_SKILL_SUBMIT_ANSWER_REQUEST";
export const RC_SKILL_SUBMIT_ANSWER_SUCCESS = "RC_SKILL_SUBMIT_ANSWER_SUCCESS";
export const RC_SKILL_SUBMIT_ANSWER_FAILURE = "RC_SKILL_SUBMIT_ANSWER_FAILURE";

/**
 * NEXT_QUESTION_REQUEST
 * NEXT_QUESTION_SUCCESS
 * NEXT_QUESTION_FAILURE
 *
 * Action type constants for fetching the next question in the benchmark test.
 *
 */
export const SKILL_NEXT_QUESTION_REQUEST = "SKILL_NEXT_QUESTION_REQUEST";
export const SKILL_NEXT_QUESTION_SUCCESS = "SKILL_NEXT_QUESTION_SUCCESS";
export const SKILL_NEXT_QUESTION_FAILURE = "SKILL_NEXT_QUESTION_FAILURE";

export const RC_SKILL_NEXT_QUESTION_REQUEST = "RC_SKILL_NEXT_QUESTION_REQUEST";
export const RC_SKILL_NEXT_QUESTION_SUCCESS = "RC_SKILL_NEXT_QUESTION_SUCCESS";
export const RC_SKILL_NEXT_QUESTION_FAILURE = "RC_SKILL_NEXT_QUESTION_FAILURE";

/**
 * FETCH_QUESTION_REQUEST
 * FETCH_QUESTION_SUCCESS
 * FETCH_QUESTION_FAILURE
 *
 * Action type constants for fetching a specific question in the benchmark test.
 *
 */
export const FETCH_QUESTION_REQUEST = "FETCH_QUESTION_REQUEST";
export const FETCH_QUESTION_SUCCESS = "FETCH_QUESTION_SUCCESS";
export const FETCH_QUESTION_FAILURE = "FETCH_QUESTION_FAILURE";

/**
 * SET_TOTAL_QUESTIONS
 *
 * Action type constant for setting the total number of questions in the test.
 *
 */
export const SET_TOTAL_QUESTIONS = "SET_TOTAL_QUESTIONS";

/**
 * SET_QUESTIONS_ANSWERED
 * SET_QUESTIONS_SKIPPED
 *
 * Action type constants for setting the number of questions answered and skipped.
 *
 */
export const SET_QUESTIONS_ANSWERED = "SET_QUESTIONS_ANSWERED";
export const SET_QUESTIONS_SKIPPED = "SET_QUESTIONS_SKIPPED";

/**
 * SET_ACCURACY_RATE
 *
 * Action type constant for setting the accuracy rate in the test.
 *
 */
export const SET_ACCURACY_RATE = "SET_ACCURACY_RATE";

/**
 * SET_SELECTED_OPTION
 *
 * Action type constant for setting the selected option in a multiple-choice question.
 *
 */
export const SET_SELECTED_OPTION = "SELECTED_OPTION";

/**
 * SET_SPEAKER_ACTIVE
 * SET_SPEAKER_INACTIVE
 *
 * Action type constants for toggling the speaker icon state.
 *
 */
export const SET_SPEAKER_ACTIVE = "SET_SPEAKER_ACTIVE";
export const SET_SPEAKER_INACTIVE = "SET_SPEAKER_INACTIVE";

/**
 * CLICK_HOME_BUTTON
 *
 * Action type constant for clicking the home button.
 *
 */
export const CLICK_HOME_BUTTON = "CLICK_HOME_BUTTON";
