import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import questionTypeConfig from "../questionTypeConfig";
import Speaker from "../../../Assets/Images/speaker.svg";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";
import { parseLatex, parseLatexText } from "../../../Utils/LatexUtils";

const ReadingQuestionComponent = ({
  onInputChange,
  speakingIndex,
  handleSpeak,
}) => {
  // state
  const [inputValues, setInputValues] = useState([]);
  const [imageStyle, setImageStyle] = useState({});
  const [marginBottom, setMarginBottom] = useState("1rem");

  const questionContainerRef = useRef(null);
  const inputRefs = useRef({});

  const speechSynthesisInstance = window.speechSynthesis;
  const { questionImage, questionType, questionText } = useSelector(
    (state) => state.testViewReducer
  );

  useEffect(() => {
    if (questionContainerRef.current) {
      const height = questionContainerRef.current.offsetHeight;
      setMarginBottom(`${height * 0.1}px`);
    }
  }, [questionType]);

  //     handle input change
  const handleInputChange = useCallback(
    (event, index) => {
      const input = event.target;
      const span = inputRefs.current[index];
      const newValue = input.value.trim();
      setInputValues((prevValues) => {
        let updatedValues = [...prevValues];
        updatedValues[0] = newValue;
        if (updatedValues[0] === "") {
          updatedValues = [];
        }
        onInputChange(updatedValues);
        return updatedValues;
      });
      if (span) {
        span.textContent = input.value || input.placeholder;
        input.style.width = `${span.offsetWidth + 10}px`;
      }
    },
    [onInputChange]
  );

  //   handle image load
  const handleImageLoad = useCallback((event) => {
    const img = event.target;
    setImageStyle(
      img.naturalWidth === 400 && img.naturalHeight === 400
        ? { borderRadius: "0.25rem", objectFit: "contain" }
        : {}
    );
  }, []);

  //   render fill in the blanks
  const renderFillInTheBlanks = useCallback(
    (questionText) =>
      questionText.split(/(<#@blank#>)/)?.map((part, index) => (
        <span key={index} className="d-inline-flex align-items-center">
          {part === "<#@blank#>" ? (
            <span className="d-inline-block align-items-center">
              <span
                className="fill-in-the-blank-wrapper position-relative"
                style={{ marginTop: "-10px" }}
              >
                <input
                  type="text"
                  className="fill-in-the-blank "
                  placeholder="Enter Answer"
                  onChange={(event) => handleInputChange(event, index)}
                  style={{ minWidth: "12ch" }}
                  maxLength={40}
                />
                <span
                  ref={(el) => (inputRefs.current[index] = el)}
                  className="fill-in-the-blank-hidden position-absolute d-none fs-6"
                />
                <span className="fill-in-the-blank-highlight"></span>
              </span>
            </span>
          ) : (
            <span
              className="flex-shrink-0 flex-grow-1 min-w-0"
              dangerouslySetInnerHTML={{ __html: parseLatex(part) }}
            />
          )}
        </span>
      )),
    [handleInputChange]
  );

  //   render question text
  const renderQuestionText = useMemo(() => {
    return questionTypeConfig.fillInTheBlanksType.includes(questionType) ? (
      renderFillInTheBlanks(questionText)
    ) : (
      <div
        dangerouslySetInnerHTML={{ __html: parseLatex(questionText) }}
      />
    );
  }, [questionText, questionType, renderFillInTheBlanks]);

  return (
    <div
      className="reading-question-container d-flex flex-row text-dark gap-2 w-100 fw-semibold"
      ref={questionContainerRef}
    >
      <div
        className={
          questionTypeConfig.fillInTheBlanksType.includes(questionType)
            ? "question-text-part-fill-in-the-blanks"
            : "question-text-part"
        }
      >
        <div className="text-question-rc d-flex flex-row gap-2">
          <div
            className={`speaker-icon ${
              speakingIndex !== null ? "enlarged" : ""
            }`}
            onClick={() => handleSpeak(questionText, "textQuestion")}
          >
            <img
              src={speakingIndex === "textQuestion" ? activeSpeaker : Speaker}
              alt="Speaker"
            />
          </div>
          {renderQuestionText}
        </div>
      </div>
      {questionTypeConfig.imageTypeQuestion.includes(questionType) && (
        <div className="question-image-part d-flex justify-content-center align-items-center flex-grow-1 pt-0 mt-3 ">
          <img
            src={questionImage}
            alt="Question Img"
            loading="lazy"
            style={imageStyle}
            onLoad={handleImageLoad}
          />
        </div>
      )}
      {questionTypeConfig.textAndImageTypeQuestion.includes(questionType) && (
        <>
          <div className="question-text-image-part d-flex flex-row ">
            <div
              className={`speaker-icon ${
                speakingIndex !== null ? "enlarged" : ""
              }`}
              onClick={() => handleSpeak(questionText, "textAndImageQuestion")}
            >
              <img
                src={
                  speakingIndex === "textAndImageQuestion"
                    ? activeSpeaker
                    : Speaker
                }
                alt="Speaker"
              />
            </div>
            <div
              className={
                questionTypeConfig.fillInTheBlanksType.includes(questionType)
                  ? "question-text-part-fill-in-the-blanks"
                  : "question-text-part"
              }
              style={{ marginLeft: "0", marginRight: "1.5rem" }}
            >
              {renderQuestionText}
            </div>
          </div>
          <div className="question-image-part d-flex justify-content-center align-items-center flex-grow-1 pt-0 mt-3">
            <img src={questionImage} alt="Question img" loading="lazy" />
          </div>
        </>
      )}
    </div>
  );
};

export default ReadingQuestionComponent;
