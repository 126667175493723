import toast from "react-hot-toast";
const base_url = process.env.REACT_APP_BASE_URL;

// addTestToStudent
export const addTestToStudent = async (data, authToken) => {
  try {
    const response = await fetch(`${base_url}/sd/v1/insertStudentAssignment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      let respjson = await response.json();
      const messages = respjson.message;
      if (
        messages ===
        "An active placement test assignment already exists for this student."
      ) {
        return "activePlacement";
      } else {
        messages.forEach((msg) => {
          if (msg.type === "field") {
            toast.error(msg.msg);
          } else {
            toast.error("Something went wrong");
          }
        });
      }
      return false;
    } else {
      const responseData = await response.json();
      const { assignedStudentAssignment, incompleteSkills } = responseData.data;

      if (assignedStudentAssignment.length > 0) {
        // assignedStudentAssignment.forEach(assignment => {
        toast.success("Assigned successfully");
        // });
      }

      if (incompleteSkills.length > 0) {
        return {
          error: true,
          incompletedData: incompleteSkills
        }
        incompleteSkills.forEach((skill) => {
          toast.error(`${skill.message}`);
        });
      }

      return true;
    }
  } catch (error) {
    console.error(error);
    toast.error("Failed to create student assignment. Please try again.");
  }
};

export const assignWritingAssignment = async (data, token) => {
  try {
    const response = await fetch(`${base_url}/sd/v1/insertWritingAssignment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      toast.error("Something went wrong");
      return false;
    } else {
      const responseData = await response.json();
      const { incompleteSkills } = responseData.data;

      if (incompleteSkills.length > 0) {
        incompleteSkills.forEach((skill) => {
          toast.error(`${skill.message}`);
        });
        return true;
      }
    }
    console.log(response);
    toast.success("Assigned successfully");
    return true;
  } catch (error) {
    console.error(error);
    toast.error("Failed to create student assignment. Please try again.");
  }
};

// get level by programId
export const getAllLevelsByProgramId = async (programId, token) => {
  const response = await fetch(
    `${base_url}/sd/v1/getAllLevelsByProgramId/${programId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

// get domain by levelId
export const getAllDomainsByLevelId = async (levelId, token) => {
  const response = await fetch(
    `${base_url}/sd/v1/getAllDomainsByLevelId/${levelId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

// get topic by domainId
export const getAllTopicsByDomainId = async (domainId, token) => {
  const response = await fetch(
    `${base_url}/sd/v1/getAllTopicsByDomainId/${domainId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

// get skill by topicId
export const getAllSkillsByTopicId = async (topicId, token, programId) => {
  const response = await fetch(
    `${base_url}/sd/v1/getAllSkillsByTopicId/${topicId}?programId=${programId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

// get question by skillId
export const getAllQuestionsBySkillId = async (skillId, token) => {
  const response = await fetch(
    `${base_url}/sd/v1/getAllQuestionsBySkillId/${skillId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};
