import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import "./WritingCompletedDetails.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useAuthenticate } from "../../../hooks/useAuthenticate";
import { fetchWritingDetailsThunk } from "../../../redux/slices/writing/writingTestSlice";
const WritingCompletedDetails = () => {
  const {
    authResult,
    loading: authLoading,
    isAuthenticated,
  } = useAuthenticate();
  const { sdaccessToken: token } = useSelector((state) => state.details);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const assignmentId = queryParams.get("id");
  useEffect(() => {
    dispatch(
      fetchWritingDetailsThunk({ token: token, assignmentId: assignmentId })
    );
  }, [assignmentId, dispatch, token]);
  const {
    data: writingData,
    error: writingError,
    loading: writingLoading,
  } = useSelector((state) => state.writing);

  const formattedCompletedDt = writingData?.completedDt
    ? moment(writingData?.completedDt * 1000).format("D MMM, YYYY")
    : "";
  const rubricNames = {
    FocusOrOpinion: "Focus / Opinion",
    Organization: "Organization",
    EvidenceAndSupport: "Evidence And Support",
    LanguageAndConventions: "Language And Conventions",
    NarrativeFocus: "Narrative Focus"
  }
  const rubricsData = writingData?.rubrics
    ? Object.entries(writingData?.rubrics)
    : [];

  console.log(writingData?.answer);
  console.log(writingData);
  if (!isAuthenticated)
    return (
      <div className="auth-err-msg">
        You are not authenticated to access this page
      </div>
    );

  if (writingLoading || authLoading) {
    return <Loader />;
  }

  if (!writingData) {
    return <div className="auth-err-msg">Something went wrong</div>;
  }
  return (
    <div className="writing-completed-details-container">
      <div className=" writing-completed-details-header d-flex justify-content-between align-items-start mb-2 mt-1">
        <div className="writing-test-details">
          <div style={{ fontSize: "1.2rem" }} >
            <i
              className="bi bi-arrow-left custom-icon"
              onClick={() => navigate("/ela/writing/assignments")}
            ></i>
          </div>
          <div>
            <div className="d-flex gap-2" style={{ lineHeight: "1" }}>
              <div style={{
                marginBottom: "0",
                fontFamily: "Inter",
                fontSize: "1.125rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal"
              }}>{formattedCompletedDt}</div>

              {writingData?.completedDt > writingData?.dueDt ? (
                <p className="d-inline-flex justify-content-center align-items-center"
                  style={{
                    padding: "4px 8px",
                    gap: "10px",
                    color: "#F01D1D",
                    borderRadius: "4px",
                    background: "rgba(240, 29, 29, 0.10)",
                    fontFamily: "Inter",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "12px",
                    marginBottom: "0"
                  }}
                >
                  Late
                </p>
              ) : (
                <p className="d-inline-flex justify-content-center align-items-center"
                  style={{
                    padding: "4px 8px",
                    color: "green",
                    borderRadius: "4px",
                    background: "rgba(24, 250, 24, 0.10)",
                    fontFamily: "Inter",
                    fontSize: "11px",
                    fontStyle: "normal",
                    gap: "10px",
                    fontWeight: "600",
                    lineHeight: "12px",
                    marginBottom: "0"
                  }}
                >
                  On Time
                </p>
              )}
            </div>
            <div>
              <small
                style={{
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                Writing Test
              </small>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center pe-3">
          <div className="mb-0" style={{
            color: "#555",

            fontFamily: "Inter",
            fontSize: "0.75rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "0.75rem",/* 100% */
            letterSpacing: "0.03rem"
          }}>OVERALL SCORE</div>
          <div
            style={{
              color: writingData?.score <= 50 
              ? "#F00" 
              : writingData?.score <= 80 
              ? "#FFA500"
              : "#159B6A",
              fontFamily: "Inter",
              fontSize: "1.625rem",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "1.625rem"
            }}
          >
            {writingData?.score}<span>%</span>
          </div>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid #EFEFEF", marginLeft: "-1.2rem" }}></div>
      <div className="writing-completed-details-body">
        <div className="writing-details-question-container">
          {writingData?.question?.text}
        </div>
        <div>
          <div style={{
            letterSpacing: "0.25px",
            fontFamily: "Inter",
            fontSize: "1.125rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal"
          }}>Rubrics</div>
          <div className="writing-rubrics-container">
            {rubricsData.map(([name, score], index) => (
              <div
                className="d-flex justify-content-between align-items-center rubric-item"
                key={index}
              >
                <div>{rubricNames[name]}</div>
                <div
                  style={
                    score <= 50 ? 
                      {
                        color: "#F00",
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "1.625rem",
                      }
                      : score <=80 ? 
                      {
                        color: "#FFA500",
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "1.625rem",
                      }
                      : {
                        color: "#159B6A",
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "1.625rem",
                      }
                  }
                >
                  {score} %
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div style={{
            letterSpacing: "0.25px",
            fontFamily: "Inter",
            fontSize: "1.125rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal"
          }}>Passage</div>
          {/* <div
            className="writing-details-answer-container"
            dangerouslySetInnerHTML={{ __html: writingData?.answer }}
          /> */}
          <ReactQuill
                className="saved-answer"
                  value={writingData?.answer?.[0] || " "}
                  modules={{toolbar: false}}
                  readOnly={true}
                />
        </div>
      </div>
    </div>
  );
};

export default WritingCompletedDetails;
