import { Outlet } from "react-router-dom";
import Header from "../../Components/Header/Header";

const LayoutWithHeader = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default LayoutWithHeader;
