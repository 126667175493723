import { useEffect, useState } from "react";
import { io } from "socket.io-client";

export const useWebSocket = (data) => {
  const [socket, setSocket] = useState(null);
  const baseUrl =
    process.env.REACT_APP_SOCKET_URL || process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (!baseUrl) {
      // console.error("Base URL is not defined");
      return;
    }

    const newSocket = io(baseUrl, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 3000,
      transports: ["websocket"],
    });

    setSocket(newSocket);
    // newSocket.on("connect", () => {
    //   console.log(
    //     `------- Connected to Socket.IO server with id ${newSocket.id}`
    //   );
    //   newSocket.emit("connected", { hello: newSocket.id });
    // });

    newSocket.on("connect", () => {
      // console.log(`Connected to Socket.IO server with id ${newSocket.id}`);

      //   // Send user data upon connecting

      newSocket.emit("connected", data);
      // console.log("User data sent to the server:", data);
    });

    newSocket.on("disconnect", () => {
      // console.log(`--------- disconnected from Socket.IO server`);
    });

    newSocket.on("disconnected", (socket) => {
      // console.log(`Client with id ${socket.id} disconnected`);
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
        newSocket.off("connect");
        newSocket.off("disconnect");
      }
    };
  }, [baseUrl]);

  return { socket };
};
