import { useState, useEffect, useRef } from "react";

export const useIdleTimer = (timeout = 180000) => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimeoutRef = useRef(null);

  useEffect(() => {
    const resetIdleTimer = () => {
      if (isIdle) {
        setIsIdle(false);
      }
      clearTimeout(idleTimeoutRef.current);
      idleTimeoutRef.current = setTimeout(() => {
        setIsIdle(true);
      }, timeout);
    };

    const handleClick = () => {
      if (isIdle) {
        setIsIdle(false);
      }
      resetIdleTimer();
    };

    const startIdleTimer = () => {
      idleTimeoutRef.current = setTimeout(() => {
        setIsIdle(true);
      }, timeout);
    };

    window.addEventListener("keypress", resetIdleTimer);
    document.addEventListener("click", handleClick);
    startIdleTimer();

    return () => {
      clearTimeout(idleTimeoutRef.current);
      window.removeEventListener("keypress", resetIdleTimer);
      document.removeEventListener("click", handleClick);
    };
  }, [isIdle, timeout]);

  return { isIdle, setIsIdle };
};
