import { createSlice } from "@reduxjs/toolkit";

const getListIcon = localStorage.getItem("isListIcon") === "true";
const getProgress =
  localStorage.getItem("isProgress") === null ||
  localStorage.getItem("isProgress") === "true";

const initialState = {
  isListIcon: getListIcon,
  isProgress: getProgress,
  isLoading: false,
  staffAccess: false,
};

const iconSlice = createSlice({
  name: "icons",
  initialState,
  reducers: {
    setListIcon: (state) => {
      state.isListIcon = true;
      localStorage.setItem("isListIcon", true);
    },
    setLargeIcon: (state) => {
      state.isListIcon = false;
      localStorage.setItem("isListIcon", false);
    },
    setProgress: (state) => {
      state.isProgress = true;
      localStorage.setItem("isProgress", true);
    },
    setCompleted: (state) => {
      state.isProgress = false;
      localStorage.setItem("isProgress", false);
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setShowAssign: (state) => {
      state.staffAccess = true;
    },
  },
});

export const {
  setListIcon,
  setLargeIcon,
  setProgress,
  setCompleted,
  setIsLoading,
  setShowAssign,
} = iconSlice.actions;
export default iconSlice.reducer;
