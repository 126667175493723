import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import sparkles from "../../Assets/Images/sparkles.jpg";
import testEndCone from "../../Assets/Images/testEndCone.svg";

const TestFinishCard = ({ programName, isemptyState, completionMessage }) => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleContinueClick = () => {
    switch (programName.toLowerCase()) {
      case "math":
        navigate("/math/assignments");
        break;
      case "ela":
        navigate("/ela/language/assignments", { replace: true });
        break;
      case "reading":
        navigate("/ela/reading/assignments", { replace: true });
        break;
      case "writing":
        navigate("/ela/writing/assignments", { replace: true });
        break;
      default:
        navigate("/", { replace: true });
    }
  };
  

  return (
    <div className="complete-parent-container">
      <div className="complete-body bg-white d-flex flex-column">
        {!isemptyState ? (
          <>
            <img
              src={sparkles}
              style={{
                borderRadius: "1.5rem",
                display: isLoaded ? "block" : "none",
              }}
              alt="Sparkles"
              onLoad={handleImageLoad}
            />
            {isLoaded && (
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-2 "
                style={{ marginTop: "-27rem" }}
              >
                <img src={testEndCone} alt="Test End Cone" />
                <span className="congratulations-message position-relative text-dark fw-bold fs-4">
                  Congratulations!
                </span>
                <span className="congratulations-sub-message position-relative text-muted fw-semibold fs-5 ms-4 me-1">
                  {completionMessage ? completionMessage : "Test ended successfully. No more questions to show."}
                </span>
                <div className="mt-5">
                  <button
                    className="continue-button position-relative flex-shrink-0 text-white border-0 fw-semibold rounded-pill "
                    onClick={handleContinueClick}
                  >
                    Let's continue
                    <span className="ms-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_340_15252)">
                          <path
                            d="M23.725 11.3364C23.7248 11.3361 23.7245 11.3358 23.7242 11.3355L18.8256 6.4605C18.4586 6.09529 17.865 6.09665 17.4997 6.46368C17.1345 6.83067 17.1359 7.42425 17.5028 7.7895L20.7918 11.0625H0.9375C0.419719 11.0625 0 11.4822 0 12C0 12.5178 0.419719 12.9375 0.9375 12.9375H20.7917L17.5029 16.2105C17.1359 16.5757 17.1345 17.1693 17.4998 17.5363C17.865 17.9034 18.4587 17.9047 18.8256 17.5395L23.7242 12.6645C23.7245 12.6642 23.7248 12.6639 23.7251 12.6636C24.0923 12.2971 24.0911 11.7016 23.725 11.3364Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_340_15252">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <span className="congratulations-sub-message-empty position-relative top-50 translate-middle-y text-danger fw-semibold fs-5">
              Something went wrong, please try again
            </span>
            <button
              className="continue-button-empty position-relative top-50 start-50 translate-middle btn btn-primary text-white fw-semibold "
              onClick={handleContinueClick}
            >
              Try Again
              <span className="ml-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_340_15252)">
                    <path
                      d="M23.725 11.3364C23.7248 11.3361 23.7245 11.3358 23.7242 11.3355L18.8256 6.4605C18.4586 6.09529 17.865 6.09665 17.4997 6.46368C17.1345 6.83067 17.1359 7.42425 17.5028 7.7895L20.7918 11.0625H0.9375C0.419719 11.0625 0 11.4822 0 12C0 12.5178 0.419719 12.9375 0.9375 12.9375H20.7917L17.5029 16.2105C17.1359 16.5757 17.1345 17.1693 17.4998 17.5363C17.865 17.9034 18.4587 17.9047 18.8256 17.5395L23.7242 12.6645C23.7245 12.6642 23.7248 12.6639 23.7251 12.6636C24.0923 12.2971 24.0911 11.7016 23.725 11.3364Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_340_15252">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestFinishCard;
