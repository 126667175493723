import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuthenticate } from "../../../hooks/useAuthenticate";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import "./WritingTestViewer.css";
import {
  submitWritingTestThunk,
  startWritingTestThunk,
  saveWritingTestThunk,
  cleanWritingData,
} from "../../../redux/slices/writing/writingTestSlice";

import WritingInstructionCard from "../../../Components/WarningMessage/WritingInstructionCard";
import Loader from "../../../Components/Loader/Loader";
import { parseLatexText } from "../../../Utils/LatexUtils";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";
import Speaker from "../../../Assets/Images/speaker.svg";
import {useQuillConfig } from "../../../hooks/useQuillConfig";

const WritingTestDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const quillRef = useRef(null);
  const editorContentRef = useRef("");

  // Token for authorising the api calls
  const { sdaccessToken: token } = useSelector((state) => state.details);

  const {formats, modules} = useQuillConfig();

  // Fetching assignment Id from Query Params
  const queryParams = new URLSearchParams(location.search);
  const assignmentId = queryParams.get("id");
  const rubricNames = {
    FocusOrOpinion: "Focus / Opinion",
    Organization: "Organization",
    EvidenceAndSupport : "Evidence And Support",
    LanguageAndConventions : "Language And Conventions",
    NarrativeFocus : "Narrative Focus"
  }
  // Authentication middleware handler
  const {
    authResult,
    loading: authLoading,
    isAuthenticated,
  } = useAuthenticate();

  // Component data from store
  const {
    data: writingData,
    loading: writingDataLoading,
    error: writingError,
  } = useSelector((state) => state.writing);

  // Access validation (Student/Teacher)
  const { staffAccess } = useSelector((state) => state.icons);
  const authMsg = useSelector((state) => state.details.authErrMsg);

  // Component data
  const [showGradeDetails, setShowGradeDetails] = useState(false);
  const [rubrics, setRubrics] = useState(
     {
      FocusOrOpinion: 0,
      Organization: 0,
      EvidenceAndSupport: 0,
      LanguageAndConventions: 0,
      NarrativeFocus: 0,
    }
  );
  const [isValid, setIsValid] = useState(false);
  const [isInstructionModelOpen, setInstructionModelOpen] = useState(false);

  // State for handling speech
  const [speakingIndex, setSpeakingIndex] = useState(null);
  const speechSynthesisInstance = window.speechSynthesis;

  // Clean the store data for writing on component unmount
  useEffect(() => {
    return () => {
      dispatch(cleanWritingData());
    };
  }, [dispatch]);
  // Starts assignment on component mount and refresh
  useEffect(() => {
    if (token && assignmentId) {
      dispatch(startWritingTestThunk({ token, assignmentId }));
    }
  }, [dispatch, token, assignmentId]);

  // Setup the editor content initially and when writing data changes(Updates on API calls)
  // useEffect(() => {
  //   if (writingData && quillRef.current) {
  //     const sanitizedAnswer = writingData.answer || "<p></p>";
  //     editorContentRef.current = sanitizedAnswer;

  //     const delta = quillRef.current
  //       .getEditor()
  //       .clipboard.convert(sanitizedAnswer);
  //     quillRef.current.getEditor().setContents(delta);
  //   }
  // }, [writingData]);
  useEffect(() => {
    if (writingData && quillRef.current && quillRef.current.getEditor) {
      const sanitizedAnswer = typeof writingData?.answer === "string" ? writingData?.answer : writingData?.answer?.[0] || "";
      editorContentRef.current = sanitizedAnswer;
 
      try {
        const delta = quillRef.current
          .getEditor()
          .clipboard.convert(sanitizedAnswer);
        quillRef.current.getEditor().setContents(delta);
      } catch (error) {
        console.error("Error setting contents in Quill editor:", error);
      }
    }
  }, [writingData]);
  useEffect(() => {
    if (writingData) {
      editorContentRef.current = typeof writingData?.answer === "string" ? writingData?.answer : writingData?.answer?.[0] || "";
    }
  }, [writingData]);

  // Runs on every rubric input change and validates if value is between 1 and 100
  useEffect(() => {
    const allFilled = Object.values(rubrics).every((val) => {
      const num = parseInt(val);
      return !isNaN(num) && num >= 0 && num <= 100;
    });
    setIsValid(allFilled && editorContentRef?.current);
  }, [rubrics]);

  // Speaking text handler
  const handleSpeak = (text, index) => {
    if ("speechSynthesis" in window) {
      const replacedText = text
        .replace(/<#@blank#>/g, "blank")
        .replace(/_{2,}/g, "blank");

      const utterance = new SpeechSynthesisUtterance(
        parseLatexText(replacedText)
      );
      if (speechSynthesisInstance.speaking || speechSynthesisInstance.pending) {
        if (speakingIndex === index) {
          speechSynthesisInstance.cancel();
          setSpeakingIndex(null);
          return;
        } else {
          speechSynthesisInstance.cancel();
        }
      }
      setSpeakingIndex(index);
      speechSynthesisInstance.speak(utterance);
      utterance.onend = () => setSpeakingIndex(null);
    }
  };

  // Saving progress handler
  const handleSaveClick = (state) => {
    if (quillRef.current) {
      editorContentRef.current = quillRef.current.getEditor().root.innerHTML;
      const sanitizedContent = DOMPurify.sanitize(editorContentRef.current);

      if (token && assignmentId && sanitizedContent) {
        dispatch(
          saveWritingTestThunk({
            token,
            assignmentId,
            questionId: writingData?.questionId,
            answer: [sanitizedContent],
            status: 5,
          })
        );
        toast.success("Your changes have been saved. Please remember that saving does not mean the assignment has been graded yet.");
      }
    }
  };

  // Submitting review handler
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(editorContentRef.current);

    if (token && assignmentId && rubrics) {
      console.log(editorContentRef.current)
      dispatch(
        submitWritingTestThunk({
          token,
          assignmentId,
          
          questionId: writingData?.questionId,
          answer: typeof editorContentRef.current === "string" ? [editorContentRef?.current] : [""],
          rubrics,
        })
      );
      toast.success("Submitted successfully");
      navigate("/ela/writing/assignments");
    }
  };

  // Toggle grade details and Editor for corrections
  const handleShowGradeDetails = () => {
    if (showGradeDetails) {
      setShowGradeDetails(false);
    } else {
      editorContentRef.current = quillRef.current.getEditor().root.innerHTML;

      setShowGradeDetails(true);
    }
  };

  // Handle rubric inputs change
  const handleRubricsChange = (key, value) => {
    setRubrics((prev) => ({
      ...prev,
      [key]: parseInt(value),
    }));
  };
  // Updates ref when editor content changes
  const handleAnswerChange = (value) => {
    if (quillRef.current) {
      editorContentRef.current = quillRef.current
        .getEditor()
        .root.innerHTML.trim();
    }
  };
  // Open instructions modal
  const handleOpenQuestionInstructions = () => {
    setInstructionModelOpen(true);
  };

  // When data is unavailable Loader will be shown
  if (authLoading || writingDataLoading) return <Loader />;

  // Authentication failed error
  if (!isAuthenticated) return <div className="auth-err-msg">{authMsg}</div>;
  // if(!assignmentId) return <div>Assignment details not found!!!</div>



  if (writingError || !writingData) {
    return <div className="auth-err-msg">Details not found!!!</div>;
  }
  return (
    <div className="writing-test-container">
      <div
        className="writing-test-body-container"
        style={{ padding: 0, minHeight: "80vh" }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "1vw",
            borderBottom: "2px solid lightgray",
          }}
        >
          <div style={{ fontSize: "1.2rem" }}>
            <strong>
              <i
                className="bi bi-arrow-left custom-icon"
                onClick={() => navigate("/ela/writing/assignments")}
              ></i>
              {writingData?.status === 5 ? showGradeDetails ? "Grade" : " Review" : " In Progress"}
            </strong>
          </div>
          {!showGradeDetails && (
            <span
              className="instructions-label resource-tooltip"
              onClick={handleOpenQuestionInstructions}
              data-tooltip={
                !writingData.resource
                  ? "Resources are not available"
                  : undefined
              }
            >
              <div>Resources</div>
            </span>
          )}
        </div>

        {!showGradeDetails ? (
          <>
            <div className="writing-grade-question-container" >
            {writingData?.status === 5 && !staffAccess &&  
            <div className="notEditableMessage">The assignment is currently in review and cannot be modified at this time.</div> }
              <div
                className="skill-instruction-container"
                style={{ 
                  paddingTop: (writingData?.status === 5 && !staffAccess) ? "0vh" : "1vh", 
                  paddingLeft: "2.5rem", paddingBottom: "0.5rem"
                }}
              >
                <span>Skill: {writingData?.skillTitle}</span>
              </div>
              <div className="d-flex align-items-start gap-2" style={{padding: "0.25rem  0.75rem"}}>
                <img
                  src={
                    speakingIndex === "textExplanationQuestion"
                      ? activeSpeaker
                      : Speaker
                  }
                  alt="Speaker"
                  style={{marginTop:"0.25rem"}}
                  onClick={() =>
                    handleSpeak(
                      writingData?.question,
                      "textExplanationQuestion"
                    )
                  }
                />
                <div className="question-text-container">
                  {writingData?.question}
                </div>
              </div>
            </div>
            <div className="writing-answer-container" style={{paddingLeft: " 2.5rem", paddingTop: "0.25rem", paddingRight: " 2.5rem", paddingBottom: "0.25rem"}}>
            <div style={{ backgroundColor: (writingData?.status === 5 && !staffAccess) ? "#EBEBEB" : "" , 
                borderRadius:"0.5rem",cursor: (writingData?.status === 5 && !staffAccess) ? "not-allowed" : ""}}>
              <ReactQuill
                ref={quillRef}
                value={editorContentRef.current || ""}
                modules={modules}
                formats={formats}
                onChange={handleAnswerChange}
                placeholder="Write your answer here..."
                readOnly={!staffAccess || writingData?.status !== 5}
              />
              </div>
            </div>
          </>
        ) : (
          <div className="grade-details-container">
            <div className="grade-details-rubrics">
              <form className="d-flex flex-column gap-3 p-1 pe-2">
                {Object.keys(rubrics).map((key) => (
                  <div
                    className="d-flex me-2 p-2 justify-content-between align-items-center"
                    style={{
                      borderBottom: "1px dashed grey",
                      width: "100%",
                      margin: "auto",
                    }}
                    key={key}
                  >
                    <label
                      htmlFor={key}
                      className="form-label me-3 flex-grow-1 custom-form-label"
                    >
                      {rubricNames[key]}
                    </label>
                    <div className="d-flex align-items-center gap-1 custom-input-container">
                      <input
                        type="number"
                        className="form-control custom-input"
                        id={key} 
                        value={rubrics[key]}
                        onChange={(e) =>
                          handleRubricsChange(key, e.target.value)
                        }
                        min="0"
                        max="100"
                        style={{ width: "70px", color: "black" }}
                        required
                      />
                      <span>%</span>
                    </div>
                  </div>
                ))}
              </form>
            </div>

            {editorContentRef.current && (

                <ReactQuill
                className="saved-answer"
                  value={editorContentRef.current}
                  modules={{toolbar: false}}
                  readOnly={true}
                />
                
              
            )}
          </div>
        )}
      </div>

      {staffAccess &&
        writingData?.status === 5 &&
        (!showGradeDetails ? (
          <div className="writing-button-container writing-grade-buttons">
            <button onClick={handleSaveClick} className="grade-save-button">
              Save
            </button>
            <button
              onClick={handleShowGradeDetails}
              className="grade-sub-button"
            >
              Grade
            </button>
          </div>
        ) : (
          <div className="writing-button-container writing-grade-buttons">
            <button
              onClick={handleShowGradeDetails}
              className="grade-save-button"
            >
              Back
            </button>
            <button
              type="submit"
              disabled={!isValid}
              className="grade-sub-button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        ))}
      {isInstructionModelOpen && (
        <WritingInstructionCard
          resource={writingData?.resource}
          close={() => setInstructionModelOpen(false)}
        />
      )}
    </div>
  );
};

export default WritingTestDetails;
