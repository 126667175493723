import React, { useState, useEffect, useRef } from "react";

const WritingTimer = ({ startFrom = 0, isRunning = false, onTimeUpdate }) => {
  const [seconds, setSeconds] = useState(() => {
    const savedTime = localStorage.getItem("writingTimer");
    return savedTime ? parseInt(savedTime, 10) : startFrom;
  });

  const writingIntervalRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && isRunning) {
        clearInterval(writingIntervalRef.current);
      } else if (!document.hidden && isRunning) {
        startTimer();
      }
    };

    const startTimer = () => {
      writingIntervalRef.current = setInterval(() => {
        setSeconds((prev) => {
          const newTime = prev + 1;
          localStorage.setItem("writingTimer", newTime);
          return newTime;
        });
      }, 1000);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    if (isRunning) {
      startTimer();
    }

    return () => {
      clearInterval(writingIntervalRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isRunning]);

  useEffect(() => {
    onTimeUpdate(seconds);
  }, [seconds, onTimeUpdate]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div>
      <div className="d-flex justify-content-center fs-3 fw-bold">
        {formatTime(seconds)}
      </div>
    </div>
  );
};

export default WritingTimer;
