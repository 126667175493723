import React, { useState } from 'react'
import instructionFirst from '../../Assets/Images/instruction-first.svg'
import instructionSecond from '../../Assets/Images/instruction-second.svg'
import instructionThird from '../../Assets/Images/instruction-third.svg'
import instructionFifth from '../../Assets/Images/instruction-fifth.svg'
import instructionSixth from '../../Assets/Images/instruction-sixth.svg'
import instructionFinishBtn from '../../Assets/Images/instruction-nextButton.svg'
import './placementTestInstructionModal.css'
import instructionNextButton from '../../Assets/Images/instruction-nextButton.svg'
import { useNavigate } from 'react-router-dom'
 
const PlacementTestInstructionCard = ({isModalOpen,
  handleModalClose,
  handlePlacementModal,
  location,
  particularAssignmentDetails}) => {
  const [programName, setProgramName] = useState("");  
  const [images,setImages] = useState([instructionFirst,instructionSecond,instructionThird,instructionFifth,instructionSixth])
  const [heading,setHeading]=useState(['Test Limit','No Parental Assistance',
    'Test Preparation',
    'Test Difficulty',
    'Test Interrupted?'
   
  ])
  const [text,setText]=useState(['The test will automatically end 40 minutes after it begins',
    "Inaccurate test results can lead to incorrect batch placement, affecting the student's progress. Therefore, parents should not assist during the test.",
    'Make sure you have water and everything you need ready at your desk before you start.',    
    "The test is adaptive, meaning the questions will change based on your progress. You may encounter harder questions at times and then suddenly easier ones. That's perfectly fine, so just keep going",
    'If your test ends unintentionally, contact your counselor to arrange a retake.'
  ])
  const [currentIndex,setCurrentIndex]=useState(0)
  const navigate = useNavigate()
 
  const handleNext=()=>{
    if(currentIndex<images.length-1){
      setCurrentIndex(currentIndex+1)      
    }
    if(currentIndex === images.length-1){
      handleModalClose(false)
      if(location.pathname.includes("math")) {
        navigate(`/math/placement-test?assignmentDetails=${particularAssignmentDetails}`)
      } else if(location.pathname.includes("ela")) {
        navigate(`/ela/placement-test?assignmentDetails=${particularAssignmentDetails}`)
      }
    }    
  }
 
  const handleBack=()=>{
    if(currentIndex>0){
      setCurrentIndex(currentIndex-1)      
    }
    if(currentIndex === 0){
      handleModalClose(false)
    }  
  }
 
 
 
  if(!isModalOpen){
    return null
  }
  return (
    <div className='instruction-modal'>
      <div className='instruction-modal-pt'>
     
      <div className='instruction-heading'>
        <div className='instruction-heading-text'>Instructions for Placement Test</div>
      </div>
      <div className='instruction-div-image'><img src={images[currentIndex]} alt="InstructionFirstTime"  className='instruction-image'/></div>
      <h3 className='instruction-heading-body'>{heading[currentIndex]}</h3>
      <p className='instruction-body'>{text[currentIndex]}</p>
      <div>
        <div className='instruction-btn-div-back'>
          <button onClick={handleBack} className='instruction-btn-back'>Back</button>
        </div>
      <div className='instruction-btn-div'>
        {currentIndex !== images.length-1 ?<button onClick={handleNext} className='instruction-btn-start'>Next</button>
      :   <button onClick={handleNext} className='instruction-btn-finish'><span style={{marginRight:'35px'}}>Start test</span>
      <span><img style={{width: '52px',height:'51px',flexShrink: '0',alignSelf:"flex-end",position:"absolute",top:"0",right:'0'}} src={instructionFinishBtn} alt="finish button" /></span></button>
      }
      </div>
      </div>
     
      </div>
    </div>
  )
}
 
export default PlacementTestInstructionCard