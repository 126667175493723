import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { flagComment } from "../../redux/slices/flagQuestionCommentSlice/flagQuestionCommentSlice";
import toast from "react-hot-toast";
import Spinner from "../Spinner/Spinner";
const AddCommentModal = ({
  onSubmit,
  onClose,
  questionId,
  assignmentId,
  fetchTestDetails,
  practiceSessionId,
  getSpellingAndVocabularyDetails,
  cmsQuestionId,
  handleUpdateFlagStatus,
  setIsTestFlag,
  assignmentType,
}) => {
  const [flagReason, setFlagReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { sdaccessToken } = useSelector((state) => state.details);
  const { loading, error } = useSelector((state) => state.flagComment);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (flagReason.trim() === "") {
      setErrorMessage("Please enter a valid reason");
      return;
    }

    try {
      const response = await dispatch(
        flagComment({
          questionId,
          flagReason,
          token: sdaccessToken,
          assignmentId,
          fetchTestDetails,
          practiceSessionId,
          cmsQuestionId,
          getSpellingAndVocabularyDetails,
        })
      );
      if (response) {
        toast.success("flagged successfully");
        if (assignmentType === 2) {
          setIsTestFlag(true);
        }
      }
    } catch (error) {
      setErrorMessage("Failed to flag the comment");
    } finally {
      setFlagReason("");
      setErrorMessage("");
      onClose();
    }
  };

  // Handle change and clear error on typing
  const handleInputChange = (e) => {
    setFlagReason(e.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  // Handle focus and clear error on focusing the text area
  const handleInputFocus = () => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  // Show success toast on successful submission
  useEffect(() => {
    if (!loading && !error && flagReason.trim() !== "") {
      onSubmit(flagReason);
      setFlagReason("");
      handleUpdateFlagStatus(true);
      onClose();
    }
  }, [loading, error]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1040,
        }}
        onClick={onClose}
      ></div>

      {/* Modal content */}
      <div
        className="modal fade show"
        style={{
          display: "block",
          zIndex: 1050,
        }}
        aria-hidden="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-2">
            <div className="d-flex">
              <span
                className="btn-close me-2"
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  right: "0",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={onClose}
              ></span>
            </div>
            <div className="modal-body">
              <p
                className="mt-3"
                style={{
                  fontSize: "14px",
                }}
              >
                Please explain briefly why this question is being flagged. Your
                feedback is essential for maintaining the quality of our
                educational community.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <textarea
                    className="w-100 p-2"
                    id="commentInput"
                    rows="4"
                    placeholder="Write your reason..."
                    style={{ outline: "none", fontSize: "14px" }}
                    value={flagReason}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  ></textarea>
                  {errorMessage && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errorMessage}
                    </p>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={loading}
                >
                  {loading ? <Spinner /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCommentModal;
