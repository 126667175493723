import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  getAllStudentAssignments,
  deleteStudentAssignment,
  bulkDeleteAssignments,
} from "../../../Utils/ApiHandler/AssignmentApi";

// state for assignments
const initialState = {
  assignments: [],
  totalPages: 1,
  testDetails: null,
  loading: false,
  error: null,
  flagToViewLoadMore: false,
};
// Thunks for fetching  assignments
export const fetchAssignments = createAsyncThunk(
  "assignments/fetchAssignments",
  async (
    { token, location, page, previousAssignments },
    { rejectWithValue, getState }
  ) => {
    const state = getState();
    const { workEmail, enrolledPrograms } = state.details;
    const { isProgress, isListIcon } = state.icons;
    try {
      let programId = "";
      enrolledPrograms.forEach((enrolledProgram) => {
        if (
          location.pathname.toLowerCase().includes("language") &&
          enrolledProgram.title === "ELA"
        ) {
          programId = enrolledProgram._id;
        } else if (
          location.pathname.toLowerCase().includes("math") &&
          enrolledProgram.title === "Math"
        ) {
          programId = enrolledProgram._id;
        } else if (
          location.pathname.toLowerCase().includes("reading") &&
          enrolledProgram.title === "Reading"
        ) {
          programId = enrolledProgram._id;
        } else if (
          location.pathname.toLowerCase().includes("writing") &&
          enrolledProgram.title === "Writing"
        ) {
          programId = enrolledProgram._id;
        }
      });

      const data = await getAllStudentAssignments(
        workEmail,
        token,
        programId,
        isProgress,
        page || 1
      );

      let modifiedData;
      let loadFlag = true;
      const newAssignments = data.data.studentAssignments;

      if ((!isProgress && newAssignments.length < 9) || 
          (isProgress && newAssignments.length < 12 )) {
        loadFlag = false;
      }

      if (!isListIcon && previousAssignments.length > 0) {
        const existingAssignments = state.assignments.assignments;

        // Filter out existing assignments that do not have matching `_id` with newAssignments
        const unmatchedExistingAssignments = existingAssignments.filter(
          (existing) =>
            !newAssignments.some(
              (newAssignment) => newAssignment._id === existing._id
            )
        );

        // Combine newAssignments first, followed by unmatchedExistingAssignments
        const combinedAssignments = [
          ...unmatchedExistingAssignments,
          ...newAssignments,
        ];
        modifiedData = {
          studentAssignments: combinedAssignments,
          loadMoreFlag: loadFlag,
          totalPages: data.data.totalPages,
        };
      } else {
        modifiedData = {
          studentAssignments: newAssignments,
          loadMoreFlag: loadFlag,
          totalPages: data.data.totalPages,
        };
      }
      return modifiedData;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

// Thunk for deleting an assignment
export const deleteAssignment = createAsyncThunk(
  "assignments/deleteAssignment",
  async ({ assignmentId, token }, { rejectWithValue }) => {
    try {
      await deleteStudentAssignment(assignmentId, token);
      return assignmentId;
    } catch (error) {
      toast.error("Failed to delete assignment");
      return rejectWithValue(error.toString());
    }
  }
);

export const bulkDeletAssignmentThunk = createAsyncThunk(
  "assignments/bulkDelete",
  async ({ dStatus, token, location },{ getState }) => {
    const state = getState();
    const { enrolledPrograms } = state.details;
    let programId = "";
      enrolledPrograms.forEach((enrolledProgram) => {
        if (
          location.pathname.toLowerCase().includes("language") &&
          enrolledProgram.title === "ELA"
        ) {
          programId = enrolledProgram._id;
        } else if (
          location.pathname.toLowerCase().includes("math") &&
          enrolledProgram.title === "Math"
        ) {
          programId = enrolledProgram._id;
        } else if (
          location.pathname.toLowerCase().includes("reading") &&
          enrolledProgram.title === "Reading"
        ) {
          programId = enrolledProgram._id;
        } else if (
          location.pathname.toLowerCase().includes("writing") &&
          enrolledProgram.title === "Writing"
        ) {
          programId = enrolledProgram._id;
        }
      });

    const response = await bulkDeleteAssignments(dStatus, token, programId);
    return response;
  }
)

// Assignments slice
const assignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssignments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssignments.fulfilled, (state, action) => {
      state.assignments = action.payload.studentAssignments;
      state.flagToViewLoadMore = action.payload.loadMoreFlag;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
    });
    builder.addCase(fetchAssignments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteAssignment.fulfilled, (state, action) => {
      state.assignments = state.assignments.filter(
        (assignment) => assignment._id !== action.payload
      );
    });
    builder.addCase(deleteAssignment.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(bulkDeletAssignmentThunk.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(bulkDeletAssignmentThunk.fulfilled, (state, action) => {
      state.loading =false;
    })
    builder.addCase(bulkDeletAssignmentThunk.rejected, (state, action) => {
      state.loading =false;
    })
  },
});

export default assignmentsSlice.reducer;
