const BASE_URL = process.env.REACT_APP_BASE_URL;

// Helper function to send the fetch request
const sendFlagRequest = async (url, body, token) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw new Error("Failed to flag question comment");
  }

  return await response.json();
};

export const flagQuestionCommentApi = async (
  questionId,
  flagReason,
  token,
  assignmentId,
  fetchTestDetails,
  practiceSessionId = null,
  cmsQuestionId = null,
  getSpellingAndVocabularyDetails
) => {
  if (!Array.isArray(questionId)) {
    questionId = [questionId];
  }

  if (!Array.isArray(cmsQuestionId)) {
    cmsQuestionId = [cmsQuestionId];
  }

  let url;
  let body;

  if (window.location.href.includes("spelling")) {
    url = `${BASE_URL}/sd/v1/spellingPractice/completedDetails/flagQuestion`;
    body = {
      practiceSessionId,
      cmsQuestionIds: cmsQuestionId,
      commentText: flagReason,
    };
  } else if (window.location.href.includes("vocabulary")) {
    url = `${BASE_URL}/sd/v1/vocabularyPractice/completedDetails/flagQuestion`;
    body = {
      practiceSessionId,
      cmsQuestionIds: cmsQuestionId,
      commentText: flagReason,
    };
  } else {
    url = `${BASE_URL}/sd/v2/addFlagCommentToQuestion`;
    body = {
      questionIds: questionId,
      commentText: flagReason,
      assignmentId,
    };
  }

  const responseData = await sendFlagRequest(url, body, token);

  if (window.location.href.includes("spelling") || window.location.href.includes("vocabulary")) {
    getSpellingAndVocabularyDetails(token);
  } else {
    fetchTestDetails(token);
  }

  return responseData;
};
