import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import './ELASubMenu.css';
import {
  setLargeIcon,
  setProgress,
} from "../../redux/slices/icons/iconsSlice";

const ELASubMenu = () => {
  const [activeItem, setActiveItem] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Access the isAuthenticated value from the Redux state to conditionally render the component
  const { isAuthenticated } = useSelector((state) => state.details);

  // List of menu items with their display names and corresponding paths
  const menuItems = useMemo(() => [
    { name: 'Language', path: 'language/assignments' },
    { name: 'Reading', path: 'reading/assignments' },
    { name: 'Writing', path: 'writing/assignments' },
    { name: 'Spelling', path: 'spelling/practice' },
    { name: 'Vocabulary', path: 'vocabulary/practice' }
  ], []);

  // Function to handle when a menu item is clicked
  const handleMenuClick = (item) => {
    dispatch(setLargeIcon());
    dispatch(setProgress());
    setActiveItem(item.name);
    navigate(`/ela/${item.path}`);
  };

  // useEffect hook to set the active item based on the current URL path
  useEffect(() => {
    const currentPath = location.pathname.split('/ela/')[1];
    const matchedItem = menuItems.find((item) => currentPath?.includes(item.path.split('/')[0]));
  
    if (matchedItem) {
      setActiveItem(matchedItem.name);
    }
  }, [location.pathname, menuItems]);
   
  return (
    isAuthenticated && (
      <div className="text-center pt-3">
        <div className="d-flex flex-wrap justify-content-center align-items-start ela-sub-menu-wrapper">
          {menuItems.map((item, index) => (
            <div key={index} className="d-flex flex-column align-items-center">
              <p
                className={`mb-0 ${activeItem === item.name ? 'active-text' : 'menu-text'}`}
                onClick={() => handleMenuClick(item)}
              >
                {item.name}
              </p>
              <div className={`divider-segment ${activeItem === item.name ? 'highlighted' : ''}`}></div>
            </div>
          ))}
        </div>
        <div className="w-100 main-divider"></div>
      </div>
    )
  );
};

export default ELASubMenu;