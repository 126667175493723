import React, { useState } from "react";
import "./SkillPracticeVideoModal.css";
import spVideoPerson from "../../../Assets/Images/sp-video-person.svg";
import spVideoPuzzle from "../../../Assets/Images/sp-video-puzzle.svg";

const SkillPracticeVideoModal = ({
  onClose,
  skillTitle,
  skillVideo,
  MathsLanguageSkillPracticeVideo,
  mathLanguageSkillName,
  RCskillPracticeVideo,
  RCSkillName,
  skillPracticeStaffVideo,
  skillPracticeStaffTitle,
  staffSkillVideo,
  isResumed,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => setIsPlaying(true);
  return (
    <div
      className="modal d-flex align-items-center justify-content-center bg-dark "
      style={{ display: "block" }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center p-4 sp-video-modal position-relative ">
        <h4 className="mb-4">
          {skillTitle ||
            mathLanguageSkillName ||
            RCSkillName ||
            skillPracticeStaffTitle}
        </h4>

        {(skillVideo ||
          MathsLanguageSkillPracticeVideo ||
          RCskillPracticeVideo ||
          skillPracticeStaffVideo) && (
          <div className="video-box mb-4 d-flex align-items-center justify-content-center position-relative">
            {isPlaying ? (
              <video
                controlslist="nodownload"
                className="video-content"
                width="100%"
                height="100%"
                controls
                autoPlay
              >
                <source
                  src={
                    skillVideo ||
                    MathsLanguageSkillPracticeVideo ||
                    RCskillPracticeVideo ||
                    skillPracticeStaffVideo
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className="play-btn-container">
                <button
                  className="btn btn-secondary play-btn"
                  onClick={handlePlay}
                >
                  ▶
                </button>
                <div className="tooltip position-absolute">Play Video</div>
              </div>
            )}
          </div>
        )}
        <div className="bottom-icons d-flex justify-content-between position-absolute w-100 mt-auto">
          <img src={spVideoPerson} alt="Person" className="sp-video-person" />
          <img src={spVideoPuzzle} alt="Puzzle" className="sp-video-puzzle" />
        </div>
        <button className="btn btn-primary sp-video-button" onClick={onClose}>
          {staffSkillVideo || isResumed ? "Resume" : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default SkillPracticeVideoModal;
