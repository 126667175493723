import {
  TEST_ENDED,
  TEST_COMPLETED,
  RESET_TEST_STATE,
  SET_ASSIGNMENT_ID,
  CLICK_HOME_BUTTON,
  NEXT_QUESTION_REQUEST,
  NEXT_QUESTION_FAILURE,
  NEXT_QUESTION_SUCCESS,
  SUBMIT_ANSWER_REQUEST,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAILURE,
  START_PLACEMENT_TEST_SUCCESS,
  START_PLACEMENT_TEST_REQUEST,
  START_PLACEMENT_TEST_FAILURE,
  TEST_EMPTY_STATE,
} from "../actionTypes/placementTestActionTypes";
import {
  START_BENCHMARK_TEST_FAILURE,
  START_BENCHMARK_TEST_SUCCESS,
  START_BENCHMARK_TEST_REQUEST,
  BENCHMARK_NEXT_QUESTION_REQUEST,
  BENCHMARK_NEXT_QUESTION_FAILURE,
  BENCHMARK_NEXT_QUESTION_SUCCESS,
  BENCHMARK_SUBMIT_ANSWER_FAILURE,
  BENCHMARK_SUBMIT_ANSWER_REQUEST,
  BENCHMARK_SUBMIT_ANSWER_SUCCESS,
  BENCHMARK_TEST_COMPLETED,
  START_RC_BENCHMARK_TEST_SUCCESS,
  BENCHMARK_RC_NEXT_QUESTION_SUCCESS,
} from "../actionTypes/benchmarkTestActionTypes";

import {
  START_SKILL_PRACTICE_SUCCESS,
  START_SKILL_PRACTICE_FAILURE,
  START_SKILL_PRACTICE_REQUEST,
  SKILL_NEXT_QUESTION_FAILURE,
  SKILL_NEXT_QUESTION_REQUEST,
  SKILL_NEXT_QUESTION_SUCCESS,
  SKILL_PRACTICE_COMPLETED,
  SKILL_SUBMIT_ANSWER_REQUEST,
  SKILL_SUBMIT_ANSWER_SUCCESS,
  SKILL_SUBMIT_ANSWER_FAILURE,
  SKILL_QUESTIONS_COUNT,
  START_RC_SKILL_PRACTICE_SUCCESS,
  START_RC_SKILL_PRACTICE_FAILURE,
  START_RC_SKILL_PRACTICE_REQUEST,
  RC_SKILL_NEXT_QUESTION_FAILURE,
  RC_SKILL_NEXT_QUESTION_REQUEST,
  RC_SKILL_NEXT_QUESTION_SUCCESS,
  RC_SKILL_SUBMIT_ANSWER_REQUEST,
  RC_SKILL_SUBMIT_ANSWER_SUCCESS,
  RC_SKILL_SUBMIT_ANSWER_FAILURE,
  RC_SKILL_PRACTICE_COMPLETED,
  RC_SKILL_QUESTIONS_COUNT,
} from "../actionTypes/skillPracticeActionTypes";

const initialState = {
  options: [],
  error: null,
  isCompleted: 0,
  complexity: "",
  accuracyRate: 0,
  questionType: 1,
  questionId: null,
  totalQuestions: 0,
  totalTimeSpent: 0,
  assignmentId: null,
  testFinished: false,
  submittedAnswer: [],
  questionImage: null,
  selectedOption: null,
  testCompleted: false,
  speakerActive: false,
  questionsAnswered: 0,
  skippedQuestionIds: [],
  skippedQuestionCount: 0,
  skillName: "",
  questionText: "",
  attemptedQuestionsCount: 0,
  showCompletionCard: false,
  completionData: null,
  isSkillCompleted: 0,
  isemptyState: false,
  textExplanation: {},
  timeLeft: 300,
  skillPracticeVideo: '',
  isNewSkill: false,
};

const testViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_PLACEMENT_TEST_REQUEST:
      return {
        ...state,
      };
    case START_BENCHMARK_TEST_REQUEST:
      return {
        ...state,
      };
    case START_SKILL_PRACTICE_REQUEST:
      return {
        ...state,
      };
    case START_RC_SKILL_PRACTICE_REQUEST:
      return {
        ...state,
      };
    case START_PLACEMENT_TEST_SUCCESS:
      localStorage.setItem('currentQuestion', action.payload.currentQuestion._id)
      return {
        ...state,
        questionId: action.payload.currentQuestion._id,
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        totalTimeSpent: action.payload.totalTimeSpent,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isCompleted: action.payload.isCompleted ?? 0,
        timeLeft: action.payload?.timeLeft,
        complexity: action.payload.complexity,
        createdAt: action.payload.startDt,
      };
    case "START_BENCHMARK_TEST_SUCCESS":
      return {
        ...state,
        questionId: action.payload.currentQuestion._id,
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        totalTimeSpent: action.payload.totalTimeSpent,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isCompleted: action.payload.isCompleted ?? 0,
        createdAt: action.payload.startDt,
      };
    case START_RC_BENCHMARK_TEST_SUCCESS:
      return {
        ...state,
        passageText: action.payload.currentQuestion.passageText,
        passageId: action.payload.currentQuestion.passageId,
        questionId: action.payload.currentQuestion._id,
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        totalTimeSpent: action.payload.totalTimeSpent,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isCompleted: action.payload.isCompleted ?? 0,
        createdAt: action.payload.startDt,
      };
    case BENCHMARK_RC_NEXT_QUESTION_SUCCESS:
      return {
        ...state,
        passageText: action.payload.currentQuestion.passageText,
        passageId: action.payload.currentQuestion.passageId,
        questionId: action.payload.currentQuestion._id,
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        totalTimeSpent: action.payload.totalTimeSpent,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isCompleted: action.payload.isCompleted ?? 0,
        createdAt: action.payload.startDt,
      };
    case START_SKILL_PRACTICE_SUCCESS:
      return {
        ...state,
        questionId: action.payload.currentQuestion._id,
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        totalTimeSpent: action.payload.totalTimeSpent,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isCompleted: action.payload.isCompleted ?? 0,
        textExplanation: action.payload.textExplanation,
        skillPracticeVideo: action.payload.skillPracticeVideo,
        isNewSkill: action.payload.isNewSkill,


      };
    case START_RC_SKILL_PRACTICE_SUCCESS:
      return {
        ...state,
        passageText: action.payload.currentQuestion.passageText,
        passageId: action.payload.currentQuestion.passageId,
        questionId: action.payload.currentQuestion._id,
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        totalTimeSpent: action.payload.totalTimeSpent,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isCompleted: action.payload.isCompleted ?? 0,
        createdAt: action.payload.startDt,
        textExplanation: action.payload.textExplanation,
        skillPracticeVideo: action.payload.skillPracticeVideo,
        isNewSkill: action.payload.isNewSkill,

      };
    case "START_BENCHMARK_TEST_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case START_PLACEMENT_TEST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case START_SKILL_PRACTICE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case START_RC_SKILL_PRACTICE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_ASSIGNMENT_ID:
      return {
        ...state,
        assignmentId: action.payload,
      };
    case SUBMIT_ANSWER_REQUEST:
      return {
        ...state,
      };
    case SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        question: action.payload,
      };
    case SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case BENCHMARK_SUBMIT_ANSWER_REQUEST:
      return {
        ...state,
      };
    case BENCHMARK_SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        question: action.payload,
      };
    case BENCHMARK_SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SKILL_SUBMIT_ANSWER_REQUEST:
      return {
        ...state,
      };
    case SKILL_SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        question: action.payload,
      };
    case SKILL_SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RC_SKILL_SUBMIT_ANSWER_REQUEST:
      return {
        ...state,
      };
    case RC_SKILL_SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        question: action.payload,
      };
    case RC_SKILL_SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case NEXT_QUESTION_REQUEST:
      return {
        ...state,
      };
    case NEXT_QUESTION_SUCCESS:
      localStorage.setItem('currentQuestion',action.payload.currentQuestion._id)
      return {
        ...state,
        questionId: action.payload.currentQuestion._id,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        submittedAnswer: [],
        accuracyRate: action.payload.accuracyRate,
        assignmentId: action.payload.assignmentId,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        totalTimeSpent: action.payload.totalTimeSpent,
        timeLeft: action.payload.timeLeft,
        isCompleted: action.payload.isCompleted ?? 0,
        createdAt: action.payload.startDt,
      };
    case NEXT_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case BENCHMARK_NEXT_QUESTION_REQUEST:
      return {
        ...state,
      };
    case BENCHMARK_NEXT_QUESTION_SUCCESS:
      return {
        ...state,
        questionId: action.payload.currentQuestion._id,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        submittedAnswer: [],
        accuracyRate: action.payload.accuracyRate,
        assignmentId: action.payload.assignmentId,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        totalTimeSpent: action.payload.totalTimeSpent,
        isCompleted: action.payload.isCompleted ?? 0,
        createdAt: action.payload.startDt,
      };
    case BENCHMARK_NEXT_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SKILL_NEXT_QUESTION_REQUEST:
      return {
        ...state,
      };
    case RC_SKILL_NEXT_QUESTION_REQUEST:
      return {
        ...state,
      };
    case SKILL_NEXT_QUESTION_SUCCESS:
      return {
        ...state,
        questionId: action.payload.currentQuestion._id,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        submittedAnswer: [],
        accuracyRate: action.payload.accuracyRate,
        assignmentId: action.payload.assignmentId,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        totalTimeSpent: action.payload.totalTimeSpent,
        isCompleted: action.payload.isCompleted ?? 0,
      };
    case RC_SKILL_NEXT_QUESTION_SUCCESS:
      return {
        ...state,
        passageId: action.payload.currentPassage.passageId,
        passageText: action.payload.currentPassage.passageText,
        questionId: action.payload.currentQuestion._id,
        questionType: action.payload.currentQuestion.qtype,
        options: action.payload.currentQuestion.options || [],
        questionText: action.payload.currentQuestion.question.text,
        questionImage: action.payload.currentQuestion.question.image || null,
        submittedAnswer: [],
        accuracyRate: action.payload.accuracyRate,
        assignmentId: action.payload.assignmentId,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        skillName:
          action.payload.currentQuestion.skillTitle ||
          action.payload.currentQuestion.skill,
        totalTimeSpent: action.payload.totalTimeSpent,
        isCompleted: action.payload.isCompleted ?? 0,
      };
    case SKILL_NEXT_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RC_SKILL_NEXT_QUESTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLICK_HOME_BUTTON:
      return initialState;
    case TEST_COMPLETED:
      return {
        ...state,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        totalTimeSpent: action.payload.totalTimeSpent,
        questionId: "",
        testCompleted: true,
        isCompleted: 1,
        isemptyState: false,
      };
    case BENCHMARK_TEST_COMPLETED:
      return {
        ...state,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        totalTimeSpent: action.payload.totalTimeSpent,
        questionId: "",
        testCompleted: true,
        isCompleted: 1,
        isemptyState: false,
      };
    case SKILL_PRACTICE_COMPLETED:
      return {
        ...state,
        accuracyRate: action.payload.score,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        totalTimeSpent: action.payload.totalTimeSpent,
        textExplanation: action.payload.textExplanation,
        questionId: "",
        testCompleted: true,
        isCompleted: 1,
        isemptyState: false,
      };
    case SKILL_QUESTIONS_COUNT:
      return {
        ...state,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount,
        isSkillCompleted: 1,
      };
    case RC_SKILL_PRACTICE_COMPLETED:
      return {
        ...state,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        totalTimeSpent: action.payload.totalTimeSpent,
        textExplanation: action.payload.textExplanation,
        questionId: "",
        testCompleted: true,
        isCompleted: 1,
      };
    case RC_SKILL_QUESTIONS_COUNT:
      return {
        ...state,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        isSkillCompleted: 1,
      };
    case TEST_ENDED:
      return {
        ...state,
        isCompleted: 1,
        accuracyRate: action.payload.accuracyRate,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        totalTimeSpent: action.payload.totalTimeSpent,
        questionId: "",
        isemptyState: false,
      };
    case RESET_TEST_STATE:
      return {
        ...initialState,
        testCompleted: false,
      };
    case TEST_EMPTY_STATE:
      return {
        ...initialState,
        isCompleted: 1,
        totalTimeSpent: action.payload.totalTimeSpent,
        attemptedQuestionsCount: action.payload?.attemptedQuestionsCount || 0,
        skippedQuestionIds: action.payload.skippedQuestionIds || [],
        isemptyState: true,
      };
    default:
      return state;
  }
};

export default testViewReducer;
