import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { FaAngleDown } from 'react-icons/fa';
import './SpellingAndVocabCompletedDetails.css';
import { fetchSpellingAndVocabularyDetails } from '../../Utils/ApiHandler/ProgressReportApi';
import Loader from '../Loader/Loader';
import { useAuthenticate } from "../../hooks/useAuthenticate";
import ExplanationModalContent from '../../Pages/Test/TestBuilder/ExplanationModalContent';
import { parseLatexText } from "../../Utils/LatexUtils";

const SpellingAndVocabCompletedDetails = () => {
    const [activeFilter, setActiveFilter] = useState('correct');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedWord, setSelectedWord] = useState(null);
    const [textExplanation, setTextExplanation] = useState(null);
    const [speakingIndex, setSpeakingIndex] = useState("");
    const [details, setDetails] = useState({ correctAnswers: [], wrongAnswers: [], skippedAnswers: [] });

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const grade = queryParams.get('grade');
    const spellingPracticeId = queryParams.get('spellingPracticeId');
    const vocabularyPracticeId = queryParams.get('vocabularyPracticeId');
    const completion = queryParams.get('completion');
    const isVocabularyPath = location.pathname.includes('vocabulary');

    const token = useSelector((state) => state.details.sdaccessToken);
    const { authResult, loading: authLoading } = useAuthenticate();

    const speechSynthesisInstance = window.speechSynthesis;

    /**
     * Fetches spelling or vocabulary details based on the current practice session ID.
     */
    const getSpellingAndVocabularyDetails = useCallback(async () => {
        if (!authLoading && authResult && (spellingPracticeId || vocabularyPracticeId)) {
            try {
                const data = await fetchSpellingAndVocabularyDetails(spellingPracticeId, vocabularyPracticeId, token);
                setDetails(data);
            } catch (error) {
                console.error("Error fetching details:", error);
            }
            finally {
                setLoading(false);
            }
        }
    }, [spellingPracticeId, vocabularyPracticeId, token, authResult, authLoading]);

    useEffect(() => {
        getSpellingAndVocabularyDetails();
    }, [getSpellingAndVocabularyDetails]);

    /**
     * Prepares and sets the explanation data for the selected question.
     * @param {Object} question - The selected question object.
     */
    const setTextExplanationData = (question) => {
        const practiceSessionId = isVocabularyPath
            ? details.vocabularyPracticeSession?.practiceSessionId
            : details.practiceSession?.practiceSessionId;

        const levelOrder = isVocabularyPath
            ? details.vocabularyPracticeSession?.levelOrder
            : details.practiceSession?.levelOrder;

        const explanationData = {
            question: question.question?.text,
            correctAnswer: question.correctAnswer,
            submittedAnswer: question.submittedAnswer,
            isCorrect: question.isCorrect,
            timeSpent: question.timeSpent,
            questionNo: question.questionNo,
            questionStatus: question.questionStatus,
            skippedAnswers: question.skippedAnswers || [],
            cmsQuestionId: question.cmsQuestionId,
            practiceSessionId,
            levelOrder,
            isFlag: question.isFlag,
            options: question.options
        };
        setTextExplanation(explanationData);
    };

    /**
     * Handles selection of a question by its ID.
     * Fetches the selected question details and sets them in the modal.
     * @param {string} questionId - The ID of the selected question.
     */
    const handleQuestionSelection = (questionId) => {
        const selectedQuestion =
            details.wrongAnswers.find((q) => q.questionId === questionId) ||
            details.skippedAnswers.find((q) => q.questionId === questionId) ||
            details.correctAnswers.find((q) => q.questionId === questionId);

        if (selectedQuestion) {
            setTextExplanationData(selectedQuestion);
        } else {
            console.warn("No question found for ID:", questionId);
        }
    };

    const handleSpeak = (text, index) => {
        if ("speechSynthesis" in window) {
            const replacedText = text
                .replace(/<#@blank#>/g, "blank")
                .replace(/_{2,}/g, "blank");

            const utterance = new SpeechSynthesisUtterance(
                parseLatexText(replacedText)
            );
            if (speechSynthesisInstance.speaking || speechSynthesisInstance.pending) {
                if (speakingIndex === index) {
                    speechSynthesisInstance.cancel();
                    setSpeakingIndex(null);
                    return;
                } else {
                    speechSynthesisInstance.cancel();
                }
            }
            setSpeakingIndex(index);
            speechSynthesisInstance.speak(utterance);
            utterance.onend = () => setSpeakingIndex(null);
        }
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && (speechSynthesisInstance.speaking || speechSynthesisInstance.pending)) {
                speechSynthesisInstance.cancel();
                setSpeakingIndex(null);
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    }, []);

    const currentAnswers = useMemo(() => {
        const answers = activeFilter === 'correct' ? details.correctAnswers :
            activeFilter === 'wrong' ? details.wrongAnswers :
                details.skippedAnswers;

        return answers.slice().sort((a, b) => {
            const wordA = isVocabularyPath ? a.question.text.toLowerCase() : a.correctAnswer[0].toLowerCase();
            const wordB = isVocabularyPath ? b.question.text.toLowerCase() : b.correctAnswer[0].toLowerCase();
            return wordA.localeCompare(wordB);
        });
    }, [activeFilter, details, isVocabularyPath]);

    const createColumns = (answers, columnsCount = 4) => {
        const columns = Array.from({ length: columnsCount }, () => []);

        answers.forEach((answer, index) => {
            const columnIndex = index % columnsCount;
            columns[columnIndex].push(answer);
        });

        return columns;
    }

    const columns = createColumns(currentAnswers, 4);

    const menuItems = spellingPracticeId ? [
        { key: 'correct', label: `Answered Correctly (${details.practiceSession?.correct})` },
        { key: 'wrong', label: `Answered Wrong (${details.practiceSession?.wrong})` },
        { key: 'skipped', label: `Skipped Words (${details.practiceSession?.skipped})` },
    ] : [
        { key: 'correct', label: `Answered Correctly (${details.vocabularyPracticeSession?.correct})` },
        { key: 'wrong', label: `Answered Wrong (${details.vocabularyPracticeSession?.wrong})` },
        { key: 'skipped', label: `Skipped Words (${details.vocabularyPracticeSession?.skipped})` },
    ];

    const handleWordClick = (answer) => {
        setSelectedWord(answer);
        setIsModalOpen(true);
        handleQuestionSelection(answer.questionId);
    };

    const handleExplanationModalClose = () => {
        setIsModalOpen(false);
        window.speechSynthesis.cancel();
    };

    const completionColor = completion <= 50 ? "#F00" : completion <= 80 ? "#FFA500" :"#159B6A";

    const handleFilterClick = (filter) => setActiveFilter(filter);

    const handleSpellingDetailsBackArrow = useCallback(() => navigate(-1), [navigate]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center p-3">
            <div className="spelling-details-wrapper w-100">
                <div className="spelling-details-header d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <FaAngleDown onClick={handleSpellingDetailsBackArrow} className="spelling-back-icon" />
                        <div className="spelling-details-level">{grade}</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="spelling-details-completion">COMPLETION</div>
                        <div className="spelling-details-score" style={{ color: completionColor }}>{completion}%</div>
                    </div>
                </div>

                <div className="spelling-details-divider"></div>

                <div className="d-flex justify-content-center align-items-center gap-40 spelling-details-filter">
                    {menuItems.map((item) => (
                        <div key={item.key} className="filter-item-wrapper">
                            <div
                                className={`filter-item ${activeFilter === item.key ? 'active' : ''}`}
                                onClick={() => handleFilterClick(item.key)}
                            >
                                {item.label}
                            </div>
                            <div className={`filter-divider ${activeFilter === item.key ? 'active' : ''}`}></div>
                        </div>
                    ))}
                </div>

                <div className="spelling-details-divider"></div>

                <div className="spelling-details-words-list">
                    {currentAnswers.length === 0 ? (
                        <div className="no-details-container">
                            <div className="no-details-message">
                                No details available
                            </div>
                        </div>
                    ) : (
                        columns.map((column, columnIndex) => (
                            <div key={columnIndex} className="spelling-words-column">
                                {column.map((answer, index) => (
                                    <div
                                        key={index}
                                        className={`spelling-word ${index === column.length - 1 ? 'last-word' : ''}`}
                                        onClick={() => handleWordClick(answer)}
                                    >
                                        <div className="spelling-details-word">
                                            {isVocabularyPath ? answer.question.text : answer.correctAnswer}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    )}
                </div>
                {isModalOpen && (
                    <div
                        className="modal-backdrop d-flex justify-content-center align-items-center rounded-2 p-5"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="custom-explanation-modal-container">
                            <ExplanationModalContent
                                level={grade}
                                levelOrder={textExplanation.levelOrder}
                                questionNo={selectedWord?.questionNo}
                                handleExplanationModalClose={handleExplanationModalClose}
                                isDetailsModal={true}
                                questionId={selectedWord.questionId}
                                assignmentId={spellingPracticeId}
                                textExplanation={textExplanation}
                                setTextExplanationData={setTextExplanationData}
                                handleSpeak={handleSpeak}
                                getSpellingAndVocabularyDetails={getSpellingAndVocabularyDetails}
                                cmsQuestionId={selectedWord?.cmsQuestionId}
                                practiceSessionId={textExplanation.practiceSessionId}
                                isFlag={selectedWord?.isFlag}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SpellingAndVocabCompletedDetails;