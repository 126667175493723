import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLevelsByProgramId } from "../../../Utils/ApiHandler/AssignTestApi";
import {
  VocabularyPracticeStartApi,
  VocabularyPracticeSubmitApi,
  VocabularyPracticeSkipApi,
  vocabularyPracticeLevelApi,
  VocabularyPracticeStartSkippedQuestionApi,
  VocabularyPracticeStartIncorrectQuestionApi
} from "../../../Utils/ApiHandler/VocabularyApi";

// Async thunk for fetching vocabulary practice level
export const fetchVocabularyPracticeLevel = createAsyncThunk(
  "vocabularyPractice/fetchVocabularyPracticeLevel",
  async ({ programId, levelId, authToken }) => {
    const response = await vocabularyPracticeLevelApi(
      programId,
      levelId,
      authToken
    );
    return response;
  }
);

// Async thunk for fetching skill levels by program ID
export const fetchVocabularySkillLevels = createAsyncThunk(
  "vocabularyPractice/fetchVocabularySkillLevels",
  async ({ programId, authToken }) => {
    const response = await getAllLevelsByProgramId(programId, authToken);
    return response.data.levels.map((level) => ({
      value: level._id,
      label: level.title,
      order: level.order,
    }));
  }
);

// Async thunk for starting vocabulary practice
export const vocabularyPracticeStart = createAsyncThunk(
  "vocabularyPractice/start",
  async ({ programId, levelId, levelOrder, authToken }) => {
    const response = await VocabularyPracticeStartApi(
      programId,
      levelId,
      levelOrder,
      authToken,
    );

    return response;
  }
);

export const vocabularyPracticeStartSkippedQuestions = createAsyncThunk(
  "vocabularyPractice/startSkippedQuestions",
  async ({ workEmail, programId, levelId, levelOrder, authToken }) => {
    const response = await VocabularyPracticeStartSkippedQuestionApi(
      workEmail,
      programId,
      levelId,
      levelOrder,
      authToken,
    );

    return response;
  }
);

export const vocabularyPracticeStartIncorrectQuestions = createAsyncThunk(
  "vocabularyPractice/startIncorrectQuestion",
  async ({ workEmail, programId, levelId, levelOrder, authToken }) => {
    const response = await VocabularyPracticeStartIncorrectQuestionApi(
      workEmail,
      programId,
      levelId,
      levelOrder,
      authToken,
    );

    return response;
  }
);

// Async thunk for submitting vocabulary practice
export const vocabularyPracticeSubmit = createAsyncThunk(
  "VocabularyPractice/submit",
  async ({
    programId,
    levelId,
    levelOrder,
    questionId,
    answer,
    timeSpent,
    skippedPracticeSession,
    incorrectPracticeSession,
    authToken,
  }) => {
    const response = await VocabularyPracticeSubmitApi(
      programId,
      levelId,
      levelOrder,
      questionId,
      answer,
      timeSpent,
      skippedPracticeSession,
      incorrectPracticeSession,
      authToken
    );
    return response;
  }
);

// Async thunk for skipping vocabulary practice
export const vocabularyPracticeSkip = createAsyncThunk(
  "vocabularyPractice/skip",
  async ({
    programId,
    levelId,
    questionId,
    levelOrder,
    timeSpent,
    skippedPracticeSession,
    incorrectPracticeSession,
    authToken,
  }) => {
    const response = await VocabularyPracticeSkipApi(
      programId,
      levelId,
      questionId,
      levelOrder,
      timeSpent,
      skippedPracticeSession,
      incorrectPracticeSession,
      authToken
    );
    return response;
  }
);

// State
const initialState = {
  isLevelsLoading: false,
  isPracticeLoading: false,
  isSkipPracticeLoading: false,
  isIncorrectPracticeLoading: false,
  isSubmitLoading: false,
  isSkipLoading: false,
  isPracticeLevelsLoading: false,
  spellingPracticeLevel: [],
  error: null,
  success: false,
  submitSuccess: false,
  skipSuccess: false,
  vocabularySkillLevels: [],
  vocabularyQuestion: null,
  questionId: null,
  programId: null,
  vocabularyAccuracyRate: 0,
  questionsAnswered: 0,
  questionsSkipped: 0,
  incorrectQuestions: 0,
  timeSpent: 0,
  isTimerRunning: false,
  vocabularyQuestionNo: 0,
  isStarted: false,
  isCompleted: false,
  isSkippedPracticeSessionCompleted: false,
  isIncorrectPracticeSessionCompleted: false,
  attemptedVocabularyQuestion: null,
  attemptedCorrectAnswer: null,
  attemptedSubmittedAnswer: null,
  isVocabularyCorrect: null,
  vocabularySubmitSuccess: false,
  vocabularyLevelId: null,
  vocabularyLevelOrder: null,
  vocabularyPracticeSuccess: false,
  attemptedTimeSpent: 0,
  isSidebarActive: false,
  vocabularyOptions: [],
  vocabularyLevelOrder: null,
  attemptedVocabularyQuestionNo: 0,
};
// slice for handling all vocabulary practice actions
const vocabularySlice = createSlice({
  name: "vocabularyPractice",
  initialState,
  reducers: {
    startTimer(state) {
      state.isTimerRunning = true;
    },
    stopTimer(state) {
      state.isTimerRunning = false;
    },
    updateTimeSpent(state, action) {
      state.timeSpent = action.payload;
    },
    resetTimeSpent(state) {
      state.timeSpent = 0;
    },
    nextVocabularyQuestion: (state, action) => {
      state.vocabularySubmitSuccess = false;
    },
    setSidebarActive: (state, action) => {
      state.isSidebarActive = action.payload;
    },
    resetPracticeCompletionStates: (state) => {
      state.isCompleted = false;
      state.isSkippedPracticeSessionCompleted = false;
      state.isIncorrectPracticeSessionCompleted = false;
    },
  },
  extraReducers: (builder) => {
    //   For fetching vocabulary practice level
    builder
      .addCase(fetchVocabularyPracticeLevel.pending, (state) => {
        state.isPracticeLevelsLoading = true;
      })
      .addCase(fetchVocabularyPracticeLevel.fulfilled, (state, action) => {
        state.isPracticeLevelsLoading = false;
        if (action?.payload?.data?.levels?.length > 0) {
          state.vocabularyPracticeLevel = action?.payload?.data?.levels || [];
          state.programId = action?.payload?.data?.levels[0]?.programId;
          state.vocabularyLevelId = action?.payload?.data?.levels[0]?.levelId;
          state.vocabularyLevelOrder =
            action?.payload?.data?.levels[0]?.levelOrder;
          state.questionsAnswered =
            action?.payload?.data?.levels[0]?.correct +
            action?.payload?.data?.levels[0]?.wrong;
          state.questionsSkipped = action?.payload?.data?.levels[0]?.skipped;
          state.incorrectQuestions = action?.payload?.data?.levels[0]?.wrong;
          state.isCompleted = action?.payload?.data?.vocabularyPracticeSession?.isCompleted;
          state.isSkippedPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isSkippedPracticeSessionCompleted;
          state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isIncorrectPracticeSessionCompleted;
          if (state.isSidebarActive) {
            const savedTime = localStorage.getItem("currentTimeSpent");
            state.timeSpent = parseInt(savedTime, 10);
          } else {
            state.timeSpent = action.payload.data.levels[0].totalTimeSpent;
          }
          state.vocabularyAccuracyRate =
            action?.payload?.data?.levels[0]?.accuracy;
        } else {
          state.vocabularyPracticeLevel = [];
          state.programId = null;
          state.vocabularyLevelId = null;
          state.vocabularyLevelOrder = null;
          state.questionsAnswered = 0;
          state.questionsSkipped = 0;
          state.incorrectQuestions = 0;
          state.timeSpent = 0;
          state.vocabularyAccuracyRate = 0;
        }
      })

      .addCase(fetchVocabularyPracticeLevel.rejected, (state, action) => {
        state.isPracticeLevelsLoading = false;
        state.error = action.error.message;
      });

    // For fetching skill levels
    builder
      .addCase(fetchVocabularySkillLevels.pending, (state) => {
        state.isLoadingLevels = true;
      })
      .addCase(fetchVocabularySkillLevels.fulfilled, (state, action) => {
        state.isLoadingLevels = false;
        state.vocabularySkillLevels = action.payload;
        state.spellingLevelId = action.payload[0].levelId;
        state.spellingLevelOrder = action.payload[0].levelOrder;
      })
      .addCase(fetchVocabularySkillLevels.rejected, (state, action) => {
        state.isLoadingLevels = false;
        state.error = action.error.message;
      });

    // For vocabularyPracticeStart
    builder
      .addCase(vocabularyPracticeStart.pending, (state) => {
        state.isPracticeLoading = true;
      })
      .addCase(vocabularyPracticeStart.fulfilled, (state, action) => {
        state.isPracticeLoading = false;
        state.success = true;
        state.vocabularyPracticeSuccess = true;
        state.vocabularyOptions = action?.payload?.data?.nextQuestionDetails?.options;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.vocabularyQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.questionsAnswered =
          action?.payload?.data?.vocabularyPracticeSession?.correct +
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.vocabularyPracticeSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.vocabularyQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.totalTimeSpent = action?.payload?.data?.vocabularyPracticeSession?.totalTimeSpent;
        state.skippedPracticeSession = action?.payload?.data?.skippedPracticeSession;
        state.incorrectPracticeSession = action?.payload?.data?.incorrectPracticeSession;
        state.isCompleted = action?.payload?.data?.vocabularyPracticeSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isIncorrectPracticeSessionCompleted;
        state.vocabularyLevelOrder =
          action?.payload?.data?.vocabularyPracticeSession?.levelOrder;
        const previousQuestionId = localStorage.getItem("previousQuestionId");
        const currentQuestionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;

        if (previousQuestionId !== currentQuestionId) {
          state.timeSpent = 0;
          localStorage.setItem("currentTimeSpent", 0);
        } else {
          state.timeSpent =
            parseInt(localStorage.getItem("currentTimeSpent"), 10) || 0;
        }

        localStorage.setItem("previousQuestionId", currentQuestionId);
      })
      .addCase(vocabularyPracticeStart.rejected, (state, action) => {
        state.isPracticeLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(vocabularyPracticeStartSkippedQuestions.pending, (state) => {
        state.isSkipPracticeLoading = true;
      })
      .addCase(vocabularyPracticeStartSkippedQuestions.fulfilled, (state, action) => {
        state.isSkipPracticeLoading = false;
        state.success = true;
        state.vocabularyPracticeSuccess = true;
        state.vocabularyOptions = action?.payload?.data?.nextQuestionDetails?.options;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.vocabularyQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.questionsAnswered =
          action?.payload?.data?.vocabularyPracticeSession?.correct +
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.vocabularyPracticeSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.vocabularyQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.totalTimeSpent = action?.payload?.data?.vocabularyPracticeSession?.totalTimeSpent;
        state.skippedPracticeSession = action?.payload?.data?.skippedPracticeSession;
        state.incorrectPracticeSession = action?.payload?.data?.incorrectPracticeSession;
        state.isCompleted = action?.payload?.data?.vocabularyPracticeSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isIncorrectPracticeSessionCompleted;
        state.vocabularyLevelOrder =
          action?.payload?.data?.vocabularyPracticeSession?.levelOrder;
        const previousQuestionId = localStorage.getItem("previousQuestionId");
        const currentQuestionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;

        if (previousQuestionId !== currentQuestionId) {
          state.timeSpent = 0;
          localStorage.setItem("currentTimeSpent", 0);
        } else {
          state.timeSpent =
            parseInt(localStorage.getItem("currentTimeSpent"), 10) || 0;
        }

        localStorage.setItem("previousQuestionId", currentQuestionId);
      })
      .addCase(vocabularyPracticeStartSkippedQuestions.rejected, (state, action) => {
        state.isSkipPracticeLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(vocabularyPracticeStartIncorrectQuestions.pending, (state) => {
        state.isIncorrectPracticeLoading = true;
      })
      .addCase(vocabularyPracticeStartIncorrectQuestions.fulfilled, (state, action) => {
        state.isIncorrectPracticeLoading = false;
        state.success = true;
        state.vocabularyPracticeSuccess = true;
        state.vocabularyOptions = action?.payload?.data?.nextQuestionDetails?.options;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.vocabularyQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.questionsAnswered =
          action?.payload?.data?.vocabularyPracticeSession?.correct +
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.vocabularyPracticeSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.vocabularyQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.totalTimeSpent = action?.payload?.data?.vocabularyPracticeSession?.totalTimeSpent;
        state.skippedPracticeSession = action?.payload?.data?.skippedPracticeSession;
        state.incorrectPracticeSession = action?.payload?.data?.incorrectPracticeSession;
        state.isCompleted = action?.payload?.data?.vocabularyPracticeSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isIncorrectPracticeSessionCompleted;
        state.vocabularyLevelOrder =
          action?.payload?.data?.vocabularyPracticeSession?.levelOrder;
        const previousQuestionId = localStorage.getItem("previousQuestionId");
        const currentQuestionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;

        if (previousQuestionId !== currentQuestionId) {
          state.timeSpent = 0;
          localStorage.setItem("currentTimeSpent", 0);
        } else {
          state.timeSpent =
            parseInt(localStorage.getItem("currentTimeSpent"), 10) || 0;
        }

        localStorage.setItem("previousQuestionId", currentQuestionId);
      })
      .addCase(vocabularyPracticeStartIncorrectQuestions.rejected, (state, action) => {
        state.isIncorrectPracticeLoading = false;
        state.error = action.error.message;
      });

    // For vocabularyPracticeSubmit
    builder
      .addCase(vocabularyPracticeSubmit.pending, (state) => {
        state.isSubmitLoading = true;
      })
      .addCase(vocabularyPracticeSubmit.fulfilled, (state, action) => {
        state.isSubmitLoading = false;
        state.submitSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.questionsAnswered =
          action?.payload?.data?.vocabularyPracticeSession?.correct;
        state.questionsSkipped =
          action?.payload?.data?.vocabularyPracticeSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.attemptedVocabularyQuestionNo =
          action?.payload?.data?.attemptedQuestionDetails?.questionNo;
        state.vocabularyQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.attemptedVocabularyQuestion =
          action?.payload?.data?.attemptedQuestionDetails?.question?.text;
        state.vocabularyQuestion =
          action?.payload?.data?.attemptedQuestionDetails?.question?.text;
        state.attemptedSubmittedAnswer =
          action?.payload?.data?.attemptedQuestionDetails?.submittedAnswer[0];
        state.attemptedCorrectAnswer =
          // action?.payload?.data?.attemptedQuestionDetails?.correctAnswer[0];
          state.isVocabularyCorrect =
          action?.payload?.data?.attemptedQuestionDetails?.isCorrect;
        state.attemptedTimeSpent =
          action?.payload?.data?.attemptedQuestionDetails?.timeSpent;
        state.vocabularySubmitSuccess = action.payload.success;
        state.questionsAnswered =
          action?.payload?.data?.vocabularyPracticeSession?.correct +
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.vocabularyPracticeSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.isCompleted = action?.payload?.data?.vocabularyPracticeSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.vocabularyPracticeSession?.isIncorrectPracticeSessionCompleted;
        state.vocabularyLevelOrder =
          action?.payload?.data?.vocabularyPracticeSession?.levelOrder;
        const currentTimeSpent = state.timeSpent;
        state.timeSpent += currentTimeSpent;
        localStorage.setItem("currentTimeSpent", 0);
        state.timeSpent = 0;
        state.vocabularyQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.vocabularyAccuracyRate =
          action?.payload?.data?.vocabularyPracticeSession?.accuracy;
      })
      .addCase(vocabularyPracticeSubmit.rejected, (state, action) => {
        state.isSubmitLoading = false;
        state.error = action.error.message;
      });

    // For vocabularyPracticeSkip
    builder
      .addCase(vocabularyPracticeSkip.pending, (state) => {
        state.isSkipLoading = true;
      })
      .addCase(vocabularyPracticeSkip.fulfilled, (state, action) => {
        state.isSkipLoading = false;
        state.skipSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.questionsSkipped =
          action?.payload?.data?.vocabularyPracticeSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.vocabularyPracticeSession?.wrong;
        state.totalTimeSpent =
          action?.payload?.data?.vocabularyPracticeSession?.totalTimeSpent;
        state.vocabularyQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.vocabularyQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.vocabularyAccuracyRate =
          action?.payload?.data?.vocabularyPracticeSession?.accuracy;
      })
      .addCase(vocabularyPracticeSkip.rejected, (state, action) => {
        state.isSkipLoading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  nextVocabularyQuestion,
  startTimer,
  stopTimer,
  updateTimeSpent,
  resetTimeSpent,
  setSidebarActive,
  isLoadingLevels,
  vocabularySkillLevels,
} = vocabularySlice.actions;
export default vocabularySlice.reducer;
