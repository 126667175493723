import React from "react";
import warning from "../../Assets/Images/warning-exclamation-mark.svg";

const WarningMessage = ({
  message,
  showButton1,
  showButton2,
  handleConfirmationClick,
  hideWarning,
  isAssign
}) => {
  return (
    <div
      className={`position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-opacity-75 idle-warning-container ${
        hideWarning ? "fade-out" : "fade-in"
      } ${isAssign ? "warning-background" : "bg-dark"}`}
    >
      <div
        className="position-relative d-flex gap-2 flex-column justify-content-center align-items-center bg-white text-center rounded shadow-sm fs-5 p-4 idle-warning"
        onClick={(e) => e.stopPropagation()}
      >
        <img src={warning} alt="Warning" />
        <span className="mt-3 fs-7">{message}</span>
        <div className="d-flex flex-row mt-3 fs-5 gap-5 warning-button-container">
          {showButton1 && (
            <button
              className="quit-button bg-white rounded p-2"
              onClick={() => handleConfirmationClick(true)}
            >
              Cancel
            </button>
          )}
          {showButton2 && (
            <button
              className="not-confirm-button text-white border-0 p-2 rounded "
              onClick={() => handleConfirmationClick(false)}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;
