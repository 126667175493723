import React, { useEffect, useState, useCallback, useRef } from "react";
import "../TestBuilder/test.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TestFinishCard from "../TestFinishCard";
import questionTypeConfig from "../questionTypeConfig";

// hooks import
import { useDebounce } from "../../../hooks/useDebounce";
import { useWebSocket } from "../../../hooks/useWebSocket";
import { useIdleTimer } from "../../../hooks/useIdleTimer";

// utils import
import { parseLatexText } from "../../../Utils/LatexUtils";
import { emitStudentTrack } from "../utils/events";

// component import
import Loader from "../../../Components/Loader/Loader";
import WarningMessage from "../../../Components/WarningMessage/WarningMessage";
import OptionComponent from "./ReadingOptionComponent";
import ReadingQuestionComponent from "./ReadingQuestionComponent";

// redux action import
import {
  startRCBenchmarkTest,
  startRCNextBenchmarkTest,
} from "../../../redux/actions/benchmarkTestAction";
import { resetTestState } from "../../../redux/actions/placementTestActions";
import { startRCSkillPracticeApi } from "../../../redux/actions/skillPracticeAction";

// redux slice import
import { useAuthenticate } from "../../../hooks/useAuthenticate";

// image import
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";
import Speaker from "../../../Assets/Images/speaker.svg";
import ExplanationModalContent from "../TestBuilder/ExplanationModalContent";
import SkillPracticeVideoModal from "../TestBuilder/SkillPracticeVideoModal";

// lazy loading
const SideBarComponent = React.lazy(() => import("../SideBarComponent"));

const ReadingTestViewer = () => {
  const { debounce } = useDebounce();
  localStorage.setItem("sessionTime", (0).toString());
  const queryParams = new URLSearchParams(window.location.search);
  const assignmentDetails = JSON.parse(
    decodeURIComponent(queryParams.get("assignmentDetails"))
  );
  const authMsg = useSelector((state) => state.details.authErrMsg);
  const { sdaccessToken } = useSelector((state) => state.details);
  const {
    passageText,
    passageId,
    skillName,
    questionType,
    questionId,
    options,
    skippedQuestionIds,
    accuracyRate,
    testCompleted,
    attemptedQuestionsCount,
    isCompleted,
    isemptyState,
    totalTimeSpent,
    textExplanation,
    createdAt,
    questionText,
    skillPracticeVideo,
    isNewSkill,
  } = useSelector((state) => state.testViewReducer);

  const [textExplanationData, setTextExplanationData] = useState("");
  useEffect(() => {
    setTextExplanationData(textExplanation);
  }, [textExplanation]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const speechSynthesisInstance = window.speechSynthesis;

  const inputRefs = useRef({});
  const viewBodyRef = useRef(null);
  const idleTimeoutRef = useRef(null);
  const prevAttemptedQuestionsCount = useRef(attemptedQuestionsCount);

  const pathname = location.pathname;
  const parts = pathname.split("/");
  const item = parts.pop();
  const item2 = parts.pop();
  const assignmentId = assignmentDetails._id;
  const assignmentType =
    assignmentDetails.assignment_type ?? (item === "benchmark-test" ? 0 : 2);
  const questionTimeRef = useRef(
    parseInt(localStorage.getItem("questionTime")) || 0
  );
  const timeRef = useRef(totalTimeSpent);
  const statusRef = useRef();
  const intervalRef = useRef(null);
  const sessionIntervalRef = useRef(null);

  const { isIdle, setIsIdle } = useIdleTimer(180000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputData, setInputData] = useState([]);
  const [questionTime, setQuestionTime] = useState(0);
  const [hideWarning, setHideWarning] = useState(false);
  const [tabSwitched, setTabSwitched] = useState(false);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speakingIndex, setSpeakingIndex] = useState(null);
  const [additionalTime, setAdditionalTime] = useState(0);
  const [totalTime, setTotalTime] = useState(totalTimeSpent);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [endTestWarning, setEndTestWarning] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [validationMessage, setValidationMessage] = useState(false);
  const [totalTimeSpentInMs, setTotalTimeSpentInMs] = useState(null);
  const [timeSpentPerQuestion, setTimeSpentPerQuestion] = useState(0);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [lastQuestionSkipped, setLastQuestionSkipped] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [lastQuestionSubmitted, setLastQuestionSubmitted] = useState(false);
  const [isRCSkillModal, setIsRCSkillModal] = useState(false);
  const [openSkillVideoModal, setOpenSkillVideoModal] = useState(false);
  const [openedFromIconClick, setOpenedFromIconClick] = useState(false);
  const [videoModal, setVideoModal] = useState(true);

  const debounceTime = 300;
  const [sessionTime, setSessionTime] = useState(
    parseInt(localStorage.getItem("sessionTime"), 10) || 0
  );
  const {
    authResult,
    loading: authLoading,
    isAuthenticated,
  } = useAuthenticate();
  const [questionNumber, setQuestionNumber] = useState(() => {
    return (
      (skippedQuestionIds ? skippedQuestionIds.length : 0) +
      attemptedQuestionsCount +
      1
    );
  });

  const userData = {
    studentName: `${assignmentDetails.assignedTo.firstName} ${assignmentDetails.assignedTo.lastName}`,
    studentEmail: assignmentDetails.assignedTo.email,
  };
  const { socket } = useWebSocket(userData);

  // open the modal for showing the math skill practice skill and video
  useEffect(() => {
    if (isNewSkill) {
      setVideoModal(true);
    }
  }, [isNewSkill]);

  // close the math skill pracice modal
  const RCSkillPracticeCloseModal = () => {
    // setIsRCSkillModal(false);
    // setOpenSkillVideoModal(false);
    // setOpenedFromIconClick(false);
    setVideoModal(false);
    setQuestionTime((prev) => prev + 1);
    localStorage.setItem("questionTime", questionTimeRef.current + 1);
    questionTimeRef.current += 1;
  };

  useEffect(() => {
    const isSkipped =
      assignmentType === 0
        ? skippedQuestionIds?.some(
            (skippedQuestion) => skippedQuestion.questionId === questionId
          )
        : skippedQuestionIds?.includes(questionId);

    const totalSkipped = skippedQuestionIds?.length || 0;

    if (isSkipped) {
      setQuestionNumber(totalSkipped + attemptedQuestionsCount);
    } else {
      setQuestionNumber(totalSkipped + attemptedQuestionsCount + 1);
    }
  }, [questionId, skippedQuestionIds, attemptedQuestionsCount, assignmentType]);

  useEffect(() => {
    let interval;
    if (
      !loading &&
      !isIdle &&
      !tabSwitched &&
      !validationMessage &&
      !isCompleted &&
      !endTestWarning &&
      !isRCSkillModal && !videoModal &&
      !isModalOpen
    ) {
      interval = setInterval(() => {
        setQuestionTime((prev) => prev + 1);
        localStorage.setItem("questionTime", questionTimeRef.current + 1);
        questionTimeRef.current += 1;
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [
    loading,
    isIdle,
    tabSwitched,
    validationMessage,
    isCompleted,
    endTestWarning,
    isRCSkillModal,
    videoModal,
    isModalOpen
  ]);

  // emit socket data
  const handleEmitData = async (accuracyRate, totalTimeSpent, status) => {
    const eventData = {
      student_name: `${assignmentDetails.assignedTo.firstName} ${assignmentDetails.assignedTo.lastName}`,
      subject: item2,
      assignment_type: assignmentDetails.assignment_type,
      assigned_on: assignmentDetails.createdAt ?? createdAt,
      current_session_time: sessionTime,
      current_question_time: questionTimeRef.current,
      total_time_spent: totalTimeSpent,
      student_email_id: assignmentDetails.assignedTo.email,
      accuracy_rate: accuracyRate,
      assignment_status: status,
      test_status: socket ? socket.connected : false,
      socket_id: socket ? socket.id : "",
      question_details: {
        assignment_id: assignmentId,
        skill_name: skillName ?? "",
        question_number: questionNumber ?? "",
        passage_text: passageText ?? "",
        passage_id: passageId ?? "",
        question_text: questionText ?? "",
        question_id: questionId ?? "",
        is_completed: isCompleted,
        options: options.map((option) => ({
          text: option.text ?? "",
        })),
      },
    };

    //console.log("Event Data:", eventData);
    // console.log('Socket:', socket);

    if (!socket || !socket.connected) {
      //  socket.connect();
      console.error("Socket is not connected.", socket);
      return;
    }

    try {
      await emitStudentTrack(socket, eventData);
      // console.log("Event emitted successfully");
    } catch (error) {
      console.error("Error emitting data:", error);
    }
  };

  useEffect(() => {
    const emitData = async () => {
      if (socket && socket.connected) {
        await handleEmitData(
          accuracyRate,
          totalTimeSpent,
          tabSwitched ? "paused" : "resumed"
        );
      } else {
        // console.log("Socket is not connected. reconnecting...");
      }
    };

    if (socket) {
      // Emit data as soon as the component is fully loaded (on connect)

      socket.on("connect", async () => {
        // console.log("student connected", userData);
        await emitData();

        socket.emit("studentConnect", userData);
      });

      // Emit data as soon as the teacherJoined and component is fully loaded (on connect)
      socket.on("teacherJoined", async () => {
        // console.log("teacher joined", userData);
        socket.emit("studentConnect", userData);
        await emitData();
      });

      // Cleanup the socket connect event listener on unmount or when socket changes
      return () => {
        socket.off("connect", emitData);
        socket.off("teacherJoined", emitData);
      };
    }
  }, [socket, accuracyRate, totalTimeSpent, tabSwitched]);

  useEffect(() => {
    // Clear the previous interval if any
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (!isCompleted && socket && socket.connected) {
      // Set an interval to emit data every 5 seconds
      intervalRef.current = setInterval(() => {
        // console.log("------timer started");
        if (socket && socket.connected) {
          handleEmitData(
            accuracyRate,
            totalTimeSpent,
            tabSwitched ? "paused" : "resumed"
          );
          // console.log("Data emitted at:", new Date().toISOString());
        } else {
          // console.log("Socket is not connected.", socket);
        }
      }, 5000); // Emit data every 5 seconds
    } else {
      // console.log("Socket is not connected or test is completed.");
    }

    // Cleanup the interval on unmount or when dependencies change
    return () => clearInterval(intervalRef.current);
  }, [isCompleted, socket, accuracyRate, totalTimeSpent, tabSwitched]);

  // Session Timer
  useEffect(() => {
    if (!loading && !isCompleted) {
      sessionIntervalRef.current = setInterval(() => {
        setSessionTime((prevTime) => {
          const newTime = prevTime + 5;
          localStorage.setItem("sessionTime", newTime.toString());
          return newTime;
        });
      }, 5000);
    }

    // Cleanup the session timer
    return () => {
      clearInterval(sessionIntervalRef.current);
    };
  }, [loading, isCompleted]);

  const fetchTestDataRef = useRef(false);

  useEffect(() => {
    if (authResult) {
      setLoading(true);
      dispatch(resetTestState());
      setQuestionStartTime(Date.now());
      if (!fetchTestDataRef.current) {
        fetchData();
        fetchTestDataRef.current = true;
      }
    } else if (!authLoading) {
      setLoading(false);
    }
  }, [dispatch, authResult, authLoading]);

  useEffect(() => {
    const storedStartTime = localStorage.getItem("startTime");
    if (storedStartTime) {
      setStartTime(parseInt(storedStartTime, 10));
    } else {
      setStartTime(Date.now());
      localStorage.setItem("startTime", Date.now().toString());
    }
    if(assignmentType !== 2 ){
      setVideoModal(false);
    }
  }, []);

  useEffect(() => {
    const hasValidInput = inputData.length > 0;
    const hasValidSelection = selectedOptions.length > 0;
    setShowSubmitButton(hasValidInput || hasValidSelection);
  }, [inputData, selectedOptions]);

  useEffect(() => {
    const adjustHeight = () => {
      if (viewBodyRef.current) {
        viewBodyRef.current.style.height = "auto";
        const newHeight = viewBodyRef.current.scrollHeight;
        viewBodyRef.current.style.height = `${newHeight}px`;
      }
    };
    const debouncedAdjustHeight = debounce(adjustHeight, 100);
    const observer = new ResizeObserver(debouncedAdjustHeight);

    if (viewBodyRef.current) {
      observer.observe(viewBodyRef.current);
    }

    return () => {
      if (viewBodyRef.current) {
        observer.unobserve(viewBodyRef.current);
      }
    };
  }, [questionNumber, showSubmitButton, selectedOptions]);

  useEffect(() => {
    if (attemptedQuestionsCount !== prevAttemptedQuestionsCount.current) {
      prevAttemptedQuestionsCount.current = attemptedQuestionsCount;
    }
  }, [attemptedQuestionsCount]);

  // Hook for Idle timeout handling
  useEffect(() => {
    const resetIdleTimer = () => {
      if (isIdle) {
        setIsIdle(false);
      }
      clearTimeout(idleTimeoutRef.current);
      if (!isIdle) {
        idleTimeoutRef.current = setTimeout(() => {
          setIsIdle(true);
        }, 180000); // 3 minutes
      }
    };
    const handleClick = () => {
      if (isIdle) {
        setIsIdle(false);
      }
      resetIdleTimer();
    };
    const startIdleTimer = () => {
      idleTimeoutRef.current = setTimeout(() => {
        setIsIdle(true);
      }, 180000);
    };
    window.addEventListener("keypress", resetIdleTimer);
    document.addEventListener("click", handleClick);
    startIdleTimer();

    return () => {
      clearTimeout(idleTimeoutRef.current);
      window.removeEventListener("keypress", resetIdleTimer);
      document.removeEventListener("click", handleClick);
    };
  }, [isIdle]);

  const debouncedHandleBlur = debounce(() => {
    if (!tabSwitched) {
      setTabSwitched(true);
      statusRef.current = "paused";
      handleEmitData(accuracyRate, totalTimeSpent, statusRef.current);
    }
  }, debounceTime);

  // Then wrap the debounced function in useCallback
  const handleBlur = useCallback(() => {
    debouncedHandleBlur();
  }, [tabSwitched, debounceTime, accuracyRate, totalTimeSpent, handleEmitData]);

  const debouncedHandleFocus = debounce(() => {
    if (tabSwitched) {
      setTabSwitched(false);
      statusRef.current = "resumed";
      handleEmitData(accuracyRate, totalTimeSpent, statusRef.current);
    }
  }, debounceTime);

  // Then wrap the debounced function in useCallback
  const handleFocus = useCallback(() => {
    debouncedHandleFocus();
  }, [tabSwitched, debounceTime, accuracyRate, totalTimeSpent, handleEmitData]);

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [handleBlur, handleFocus]);

  const fetchData = async () => {
    setLoading(true);
    const skippedQuestion = localStorage.getItem("skippedId");
    const parentQuestionId = localStorage.getItem("passageId");
    try {
      let response;
      if (assignmentType === 0) {
        if (skippedQuestion && skippedQuestion !== "0") {
          response = await dispatch(
            startRCBenchmarkTest(
              assignmentId,
              sdaccessToken,
              skippedQuestion,
              parentQuestionId
            )
          );
        } else {
          response = await dispatch(
            startRCBenchmarkTest(assignmentId, sdaccessToken)
          );
        }
      } else {
        if (skippedQuestion && skippedQuestion !== "0") {
          response = await dispatch(
            startRCSkillPracticeApi(
              passageId,
              skippedQuestion,
              assignmentId,
              [],
              0,
              0,
              sdaccessToken,
              0
            )
          );
        } else {
          response = await dispatch(
            startRCSkillPracticeApi(
              "",
              "",
              assignmentId,
              [],
              0,
              0,
              sdaccessToken,
              1
            )
          );
        }
      }
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTimeTakenUpdate = (totalTimeInMs) => {
    setTotalTimeSpentInMs(totalTimeInMs);
  };

  const handleModalCloseOpen = async () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSkipQuestion = async () => {
    setTotalTime((prevTime) => {
      const newTime = totalTimeSpent + questionTimeRef.current;
      timeRef.current = newTime;
      return newTime;
    });
    setLoading(true);
    setTimeSpentPerQuestion(totalTimeSpent + questionTimeRef.current);
    if (isCompleted === 1) {
      setLastQuestionSkipped(true);
    }
    let response;
    localStorage.setItem("skippedId", 0);
    const timeSpentOnQuestion = totalTimeSpent + questionTimeRef.current;
    if (questionTypeConfig.fillInTheBlanksType.includes(questionType)) {
      if (assignmentType === 0) {
        response = dispatch(
          startRCNextBenchmarkTest(
            questionId,
            assignmentId,
            inputData,
            1,
            questionTimeRef.current,
            sdaccessToken,
            passageId
          )
        );
      } else {
        response = dispatch(
          startRCSkillPracticeApi(
            passageId,
            questionId,
            assignmentId,
            inputData,
            1,
            questionTimeRef.current,
            sdaccessToken
          )
        );
      }
    } else {
      if (assignmentType === 0) {
        response = dispatch(
          startRCNextBenchmarkTest(
            questionId,
            assignmentId,
            [],
            1,
            questionTimeRef.current,
            sdaccessToken,
            passageId
          )
        );
      } else {
        response = dispatch(
          startRCSkillPracticeApi(
            passageId,
            questionId,
            assignmentId,
            selectedOptions,
            1,
            questionTimeRef.current,
            sdaccessToken
          )
        );
      }
    }
    response.finally(() => {
      setLoading(false);
      setInputData([]);
      setSelectedOptions([]);
      setQuestionTime(0);
      questionTimeRef.current = 0;
      localStorage.setItem("questionTime", 0);
    });
  };

  const handleSubmitAnswer = async () => {
    setTotalTime((prevTime) => {
      const newTime = totalTimeSpent + questionTimeRef.current;
      timeRef.current = newTime;
      return newTime;
    });

    setSubmitButtonClicked(true);
    setLoading(true);
    const timeSpentOnQuestion = questionTimeRef.current;
    if (isCompleted === 1) {
      setLastQuestionSubmitted(true);
    }
    localStorage.setItem("skippedId", 0);
    let response;
    if (questionTypeConfig.fillInTheBlanksType.includes(questionType)) {
      if (assignmentType === 0) {
        response = dispatch(
          startRCNextBenchmarkTest(
            questionId,
            assignmentId,
            inputData,
            0,
            questionTimeRef.current,
            sdaccessToken,
            passageId
          )
        );
      } else {
        response = dispatch(
          startRCSkillPracticeApi(
            passageId,
            questionId,
            assignmentId,
            inputData,
            0,
            questionTimeRef.current,
            sdaccessToken,
            0
          )
        );
      }
    } else {
      if (assignmentType === 0) {
        response = dispatch(
          startRCNextBenchmarkTest(
            questionId,
            assignmentId,
            selectedOptions,
            0,
            questionTimeRef.current,
            sdaccessToken,
            passageId
          )
        );
      } else {
        response = dispatch(
          startRCSkillPracticeApi(
            passageId,
            questionId,
            assignmentId,
            selectedOptions,
            0,
            questionTimeRef.current,
            sdaccessToken,
            0
          )
        );
      }
    }
    response.finally(() => {
      setLoading(false);
      setInputData([]);
      setSelectedOptions([]);
      setQuestionTime(0);
      questionTimeRef.current = 0;
      localStorage.setItem("questionTime", 0);
    });
  };

  const startRCSkillPracticeRef = useRef(false);
  const handleEndTest = async () => {
    setHideWarning(true);
    let response;
    handleEmitData(accuracyRate, totalTimeSpent, "paused");
    localStorage.setItem("skippedId", 0);
    localStorage.setItem("selectedAssignment", "null");
    localStorage.setItem("isCardClick", "true");
    if (isCompleted === 0) {
      if (assignmentType === 2 && !startRCSkillPracticeRef.current) {
        response = dispatch(
          startRCSkillPracticeApi(
            "",
            "",
            assignmentId,
            [],
            0,
            questionTimeRef.current,
            sdaccessToken,
            1,
            1
          )
        );

        startRCSkillPracticeRef.current = true;
      } else {
        response = dispatch(
          startRCBenchmarkTest(
            assignmentId,
            sdaccessToken,
            "",
            "",
            questionTimeRef.current
          )
        );
      }
    }
    localStorage.setItem("questionTime", 0);
    setTimeout(() => {
      setEndTestWarning(false);
      let path = "";
      if (item2 === "reading") {
        path = `/ela/reading/assignments`;
      }
      navigate(path);
    }, 400);
  };

  const handleConfirmationClick = (confirm) => {
    setIsIdle(false);
    clearTimeout(idleTimeoutRef.current);
    if (confirm) {
      handleEndTest();
    } else {
      setHideWarning(true);
      setTimeout(() => {
        setValidationMessage(false);
        setEndTestWarning(false);
        setTabSwitched(false);
      }, 400);
    }
  };

  const handleInputChange = (updatedValues) => {
    setInputData(updatedValues);
  };

  const handleSpeak = (text, index) => {
    if ("speechSynthesis" in window) {
      const replacedText = text
        .replace(/<#@blank#>/g, "blank")
        .replace(/_{2,}/g, "blank");

      const utterance = new SpeechSynthesisUtterance(
        parseLatexText(replacedText)
      );
      if (speechSynthesisInstance.speaking || speechSynthesisInstance.pending) {
        if (speakingIndex === index) {
          speechSynthesisInstance.cancel();
          setSpeakingIndex(null);
          return;
        } else {
          speechSynthesisInstance.cancel();
        }
      }
      setSpeakingIndex(index);
      speechSynthesisInstance.speak(utterance);
      utterance.onend = () => setSpeakingIndex(null);
    }
  };

  //Stopping the speaker when the user moving to next question
  useEffect(() => {
    if (
      submitButtonClicked ||
      isIdle ||
      tabSwitched ||
      validationMessage ||
      endTestWarning
    ) {
      speechSynthesisInstance.cancel();
      setSpeakingIndex(null);
      setSubmitButtonClicked(false);
    }
  }, [
    submitButtonClicked,
    setSubmitButtonClicked,
    speechSynthesisInstance,
    isIdle,
    tabSwitched,
    validationMessage,
    endTestWarning,
  ]);

  return (
    <div className="test-viewer-bg-container">
      {authLoading ? (
        <Loader />
      ) : isAuthenticated ? (
        <div className="d-flex justify-content-around gap-1">
          <div className="overflow-auto d-flex flex-column h-100 vh-100 test-view-container">
            {loading && <Loader />}
            <div></div>
            {isIdle && (
              <WarningMessage
                showButton1={true}
                showButton2={true}
                hideWarning={hideWarning}
                message={"Hey, Do you want to resume the test?"}
                handleConfirmationClick={handleConfirmationClick}
              />
            )}
            
            {tabSwitched && (
              <WarningMessage
                message={
                  "You are either trying to close this tab or switch to another tab. The test will end!"
                }
                showButton1={true}
                showButton2={true}
                handleConfirmationClick={handleConfirmationClick}
                hideWarning={hideWarning}
              />
            )}
            {validationMessage && (
              <WarningMessage
                showButton1={false}
                showButton2={true}
                hideWarning={hideWarning}
                handleConfirmationClick={handleConfirmationClick}
                message={"Select at least one option to submit successfully."}
              />
            )}
            {isModalOpen &&
              textExplanationData !== null &&
              textExplanationData !== "" &&
              textExplanationData !== undefined &&
              !loading && (
                <div
                  className="modal-backdrop d-flex justify-content-center align-items-center rounded-2 p-5"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="custom-modal-container">
                    <ExplanationModalContent
                      handleExplanationModalClose={handleModalCloseOpen}
                      handleSpeak={handleSpeak}
                      textExplanation={textExplanationData}
                      setTextExplanationData={setTextExplanationData}
                      speakingIndex={speakingIndex}
                      isCompleted={isCompleted}
                      assignmentId={assignmentId}
                      questionId={textExplanation?.questionId}
                      selectedOptions={selectedOptions}
                      inputData={inputData}
                      isTestFlagged={textExplanation?.isFlag}
                      text={textExplanation}
                      assignmentType={assignmentType}
                    />
                  </div>
                </div>
              )}
            {isCompleted === 1 && !isModalOpen && (
              <TestFinishCard programName={item2} isemptyState={isemptyState} />
            )}
            {!testCompleted && !loading && isCompleted !== 1 && data && (
              <div
                className="view-body wide"
                // ref={viewBodyRef}
                style={{
                  width: "99%",
                  overflowY: "auto",
                  overflowX: "scroll",
                  minHeight: "480px",
                  marginLeft: "10px",
                }}
              >
                <span
                  className="view-body-header wide"
                  style={{ minWidth: "100%" }}
                ></span>
                <div className="skill-container">
                  <div className="skill-name-container d-flex justify-content-between">
                    {skillName && (
                      <div className="d-flex gap-1">
                        <span>Skill:</span>
                        <span className="fw-semi-bold ">{skillName}</span>
                      </div>
                    )}
                    {/* show skill video icon */}
                    {(skillPracticeVideo === null || skillPracticeVideo) && (
                      <button
                        className={`me-5 bg-transparent border-0 ${
                          skillPracticeVideo === null
                            ? "no-video-icon"
                            : "text-primary"
                        }`}
                        style={{
                          fontSize: "1.5rem",
                          cursor:
                            skillPracticeVideo === null
                              ? "not-allowed"
                              : "pointer",
                        }}
                        title={
                          skillPracticeVideo === null
                            ? "No video available"
                            : "Revisit the concept"
                        }
                        disabled={skillPracticeVideo === null}
                        onClick={() => {
                          // setOpenSkillVideoModal(true);
                          // setOpenedFromIconClick(true);
                          setVideoModal(true);
                        }}
                      >
                        <i class="bi bi-camera-video-fill"></i>
                      </button>
                    )}
                  </div>
                  <span className="question-counter fw-bold">
                    {" "}
                    Question No: {questionNumber}
                  </span>
                </div>
                <div className="reading-container">
                  {true && (
                    <div
                      className="passage-container"
                      style={{ marginLeft: "-2.5rem" }}
                    >
                      <div
                        className={`speaker-icon ${
                          isSpeaking ? "enlarged" : ""
                        }`}
                        onClick={() => handleSpeak(passageText, "paragraph")}
                      >
                        <img
                          src={
                            speakingIndex === "paragraph"
                              ? activeSpeaker
                              : Speaker
                          }
                          alt="Speaker"
                        />
                      </div>
                      <span
                        className="passage-text"
                        dangerouslySetInnerHTML={{ __html: passageText }}
                      ></span>
                    </div>
                  )}
                  <div className="question-option-reading-container">
                    <ReadingQuestionComponent
                      onInputChange={handleInputChange}
                      speakingIndex={speakingIndex}
                      handleSpeak={handleSpeak}
                    />
                    {questionType !== "N" && (
                      <OptionComponent
                        setSelectedOptions={setSelectedOptions}
                        speakingIndex={speakingIndex}
                        handleSpeak={handleSpeak}
                      />
                    )}
                  </div>
                </div>

                <div className="view-footer d-flex justify-content-between align-items-center fw-medium position-relative mt-auto mb-4">
                  <span
                    className="skip-question-button"
                    onClick={handleSkipQuestion}
                  >
                    <u>Skip the question</u>
                  </span>
                  <button
                    className="submit-answer-button rounded-1 text-white fs-7"
                    onClick={() => {
                      if (assignmentType === 2) {
                        handleModalCloseOpen();
                        handleSubmitAnswer();
                      } else {
                        handleSubmitAnswer();
                      }
                    }}
                    disabled={!showSubmitButton}
                    data-tooltip={
                      !showSubmitButton ? "Please answer to submit" : ""
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
          {isCompleted !== 1 && data && (
            <div className="test-view-sidebar ms-3">
              <SideBarComponent
                item2={item2}
                isIdle={isIdle}
                loading={loading}
                startTime={startTime}
                setLoading={setLoading}
                isCompleted={isCompleted}
                tabSwitched={tabSwitched}
                accuracyRate={accuracyRate}
                setStartTime={setStartTime}
                assignmentId={assignmentId}
                sdaccessToken={sdaccessToken}
                answeredCount={answeredCount}
                testCompleted={testCompleted}
                additionalTime={additionalTime}
                totalTimeSpent={totalTimeSpent}
                skippedQuestions={skippedQuestionIds}
                validationMessage={validationMessage}
                currentQuestionNumber={questionNumber}
                lastQuestionSkipped={lastQuestionSkipped}
                skippedCount={
                  skippedQuestionIds?.length + (lastQuestionSkipped ? 1 : 0)
                }
                handleTimeTakenUpdate={handleTimeTakenUpdate}
                lastQuestionSubmitted={lastQuestionSubmitted}
                attemptedQuestionsCount={
                  attemptedQuestionsCount + (lastQuestionSubmitted ? 1 : 0)
                }
                assignmentType={assignmentType}
                handleEndTest={handleEndTest}
                questionId={questionId}
                passageId={passageId}
                endTestWarning={endTestWarning}
                setEndTestWarning={setEndTestWarning}
                isModalOpen={isModalOpen}
                handleSubmitAnswer={handleSubmitAnswer}
                isRCSkillModal={isRCSkillModal}
                videoModal={videoModal}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="auth-err-msg">{authMsg}</div>
      )}
      {(videoModal && !isModalOpen && !loading && assignmentType === 2) && (
        <SkillPracticeVideoModal
          RCskillPracticeVideo={skillPracticeVideo}
          RCSkillName={skillName}
          onClose={RCSkillPracticeCloseModal}
          isResumed={openedFromIconClick}
        />
      )}
    </div>
  );
};

export default ReadingTestViewer;
