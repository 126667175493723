export const handleSkillSelection = (selectedSkill,
    topics,
    selectedLevel,
    selectedDomain,
    programId,
    firstName, lastName, workEmail, domains) => {
    let data;
    topics.map((topic) => {
        topic?.skills?.map((skill) => {
            if (selectedSkill === skill._id) {
                    data = {
                        levelId: selectedLevel,
                        domains: [{
                            _id: selectedDomain._id,
                            domainTitle: selectedDomain.title,
                            topics: [{
                                _id: topic._id,
                                topicTitle: topic.title,
                                skills: [{
                                    _id: skill._id,
                                    skillTitle: skill.title
                                }]
                            }]
                        }],
                        assignedTo: {
                            email: workEmail,
                            firstName: firstName,
                            lastName: lastName,
                        },
                        programId: programId,
                        isNewSkill: skill.isStarted ? false : true,
                        skillId: skill._id
                    }
                
            }
        })
    })
    return data;
}