import React, { useRef, useState, useEffect, useCallback } from "react";
import "./Header.css";
const ProfileDropdown = ({
  profilePic,
  firstName,
  lastName,
  role,
  staffFirstName,
  staffLastName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // toggle dropdown
  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  // close dropdown on outside click
  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  }, []);

  // close dropdown on outside click
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, handleClickOutside]);

  return (
    <div className="listView">
      <div onClick={toggleDropdown}>
        <i className="bi bi-list-ul w-25 h-25"></i>
      </div>
      {isDropdownOpen && (
        <div className="dropdown-content position-relative" ref={dropdownRef}>
          <div
            className="d-flex align-items-center mobileAlignAvatarName fw-semi-bold position-relative"
            style={{ fontWeight: "600" }}
          >
            {profilePic ? (
              <img src={profilePic} alt="Avatar" className="mobileAvatar" />
            ) : (
              <div className="default-avatar-container d-flex align-items-center justify-content-center">
                <i className="bi bi-person-fill default-avatar-icon"></i>
              </div>
            )}
            {`${firstName} ${lastName}`}
          </div>
          {role !== "student" && (
            <>
              <hr />
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "0.8rem",
                  marginTop: "0.5rem",
                }}
              >
                Name : {`${staffFirstName} ${staffLastName}`}
              </div>
              <div style={{ fontWeight: "400", fontSize: "0.8rem" }}>
                Role : {role}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
