/* eslint-disable no-whitespace-before-property */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDomainsMasteryByLevelIdApi,
  getTopicsMasteryByDomainIdApi,
  getSkillsMasteryByTopicIdApi,
} from "../../../Utils/ApiHandler/GradeLevelMasteryReportApi.js";

const initialState = {
  data: {},
  loading: false,
  topicsLoading: false,
  skillsLoading: false,
  error: null,
  domainsError: null,
  topicsError: null,
  skillsError: null
};

export const getDomainsMasteryByLevelIdThunk = createAsyncThunk(
  "gradeMastery/getDomainsMasteryByLevelId",
  async ({ token, programId, levelId,  masteryType }) => {
    const response = await getDomainsMasteryByLevelIdApi(
      token,
      programId,
      levelId,
      masteryType
    );
    return response;
  }
);

export const getTopicsMasteryByDomainIdThunk = createAsyncThunk(
  "gradeMastery/getTopicsMasteryByDomainId",
  async ({  token,
    programId,
    levelId,
    domainId,
    masteryType }) => {
    const response = await getTopicsMasteryByDomainIdApi(
      token,
  programId,
  levelId,
  domainId,
  masteryType
    );
    return response;
  }
);

export const getSkillsMasteryByTopicIdThunk = createAsyncThunk(
  "library/getSkillsMasteryByTopicId",
  
  async ({ token, programId,   levelId, domainId, topicId,  masteryType }) => {
    const response = await getSkillsMasteryByTopicIdApi(token,
      programId,
      levelId,
      domainId,
      topicId,
      masteryType);
    return response.data;
  }
);

const gradeMasterySlice = createSlice({
  name: "gradeMastery",
  initialState,
  reducers: {
    clearMasteryData: (state) => {
      state.data = {};
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDomainsMasteryByLevelIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDomainsMasteryByLevelIdThunk.fulfilled, (state, action) => {
        if (!action.payload.success) {
          state.domainsError =
            action.payload?.message ||
            "Failed to fetch domains for the selected level";
          state.data = null;
        } else {
          state.loading = false;
          state.data = {
            ...state.data,
            ...action.payload?.data
          };
        }
      })
      .addCase(getDomainsMasteryByLevelIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.domainsError = action.error.message;
      })
      .addCase(getTopicsMasteryByDomainIdThunk.pending, (state) => {
        state.topicsLoading = true;
        state.error = null;
      })
      .addCase(getTopicsMasteryByDomainIdThunk.fulfilled, (state, action) => {
        if (!action.payload.success) {
          state.topicsError =
            action.payload.message ||
            "Failed to fetch topics for the selected domain";
          state.data = null;
        } else {
          state.topicsLoading = false;

          const { domainId, topics } = action.payload.data;
          
          if (state.data?.domains) {
            const domainIndex = state.data.domains.findIndex(
              (domain) => domain._id === domainId
            );

            if (domainIndex !== -1) {
              // Update the skills for the specific topic
              state.data = {
                ...state.data,
                domains : [
                  ...state.data.domains.slice(0, domainIndex),
                  {
                    ...state.data.domains[domainIndex],
                    topics: topics,
                  },
                  ...state.data.domains.slice(domainIndex + 1),
                ]
              };
            } else {
              console.error("Domain not found for domainId:", domainId);
            }
          } else {
            console.error("Topics data is null or undefined");
          }
        }
      })
      .addCase(getTopicsMasteryByDomainIdThunk.rejected, (state, action) => {
        state.topicsLoading = false;
        state.topicsError =
          action.error?.message ||
          "Failed to fetch topics for the selected domain";
      })
      .addCase(getSkillsMasteryByTopicIdThunk.pending, (state) => {
        state.skillsLoading = true;
        state.error = null;
      }).addCase(getSkillsMasteryByTopicIdThunk.fulfilled, (state, action) => {
        if (action.payload?.error) {
          state.error = action.payload?.error;
          state.data = null;
        } else {
          state.skillsLoading = false;
          const { topicId, skills } = action.payload;
      
          

          const findDomainAndTopic = (topicId) => {
            return state?.data?.domains?.find((domain) =>
              domain.topics?.find((topic) => topic._id === topicId)
            );
          };
          
      
          const domainData = findDomainAndTopic(topicId);
      
          if (domainData) {
            const topicIndex = domainData.topics.findIndex((topic) => topic._id === topicId);
      
            if (topicIndex !== -1) {
              state.data = {
                ...state.data,
                domains: state.data?.domains?.map((domain) =>
                  domain._id === domainData._id
                    ? {
                        ...domain,
                        topics: domain.topics.map((topic, index) =>
                          index === topicIndex
                            ? { ...topic, skills: skills }
                            : topic
                        ),
                      }
                    : domain
                ),
              };
      
            } else {
              console.error("Topic not found for topicId:", topicId);
            }
          } else {
            console.error("Domain containing the topic not found");
          }
        }
      })
      .addCase(getSkillsMasteryByTopicIdThunk.rejected, (state, action) => {
        state.skillsLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {clearMasteryData} = gradeMasterySlice.actions;
export default gradeMasterySlice.reducer;
