const base_url = process.env.REACT_APP_BASE_URL;

// auth api
export const Authenticate = async (token) => {
  try {
    const response = await fetch(`${base_url}/sd/v1/auth/verify-portal-token`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return false
  }
  
  
};
