import { useCallback } from "react";
export const useDebounce = (delay) => {
  const debounce = useCallback(
    (func) => {
      let inDebounce;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
      };
    },
    [delay]
  );

  return { debounce };
};
