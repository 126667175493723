import React from "react";

const WritingInstructionCard = ({ close, resource }) => {
  
  return (
    <div className="modal" tabIndex="-1" style={{ display: "flex", backgroundColor: "rgba(0, 0, 0, 0.5)" }} >
      <div className="modal-dialog" style={{ minWidth: "70vw", minHeight: "60vh" }}>
        <div className="modal-content" style={{ height: "90%", maxWidth:"90%" }} >
          <div className="modal-header">
            <h5 className="modal-title">Resources</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={close}></button>
          </div>
          <div className="modal-body">
            <iframe
              id="iframe"
              src={resource}
              frameBorder="0"
              title="instruction Modal"
              style={{ width: '100%', height: "100%" }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WritingInstructionCard;