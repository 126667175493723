import {
  startSkillPractice,
  startRCSkillPractice,
} from "../../Utils/ApiHandler/SkillPracticeApi";
import { TEST_EMPTY_STATE } from "../actionTypes/placementTestActionTypes";
import {
  START_SKILL_PRACTICE_SUCCESS,
  START_SKILL_PRACTICE_FAILURE,
  SET_ASSIGNMENT_ID,
  START_SKILL_PRACTICE_REQUEST,
  SKILL_PRACTICE_FINISHED,
  SKILL_PRACTICE_COMPLETED,
  SKILL_QUESTIONS_COUNT,
  START_RC_SKILL_PRACTICE_SUCCESS,
  START_RC_SKILL_PRACTICE_FAILURE,
  START_RC_SKILL_PRACTICE_REQUEST,
  RC_SKILL_PRACTICE_COMPLETED,
  RC_SKILL_QUESTIONS_COUNT,
} from "../actionTypes/skillPracticeActionTypes";

export const startSkillPracticeApi =
  (
    questionId = null,
    assignmentId,
    submittedAnswer = null,
    isSkipped = null,
    timeSpentPerQuestion = null,
    token,
    isStarted,
    isResume
  ) =>
  async (dispatch) => {
    dispatch({
      type: START_SKILL_PRACTICE_REQUEST,
    });
    try {
      let response;
      response = await startSkillPractice(
        questionId,
        assignmentId,
        submittedAnswer,
        isSkipped,
        timeSpentPerQuestion,
        token,
        isStarted,
        isResume
      );
      let data = {
        accuracyRate: response.data?.accuracyRate,
        attemptedQuestionsCount: response.data?.attemptedQuestionsCount,
        totalTimeSpent: response.data?.totalTimeSpent,
      };
      if (response.data.isCompleted) {
        if (response.data.attemptedQuestionsCount === 0) {
          await dispatch({ type: TEST_EMPTY_STATE, payload: true });
        } else {
          await dispatch({
            type: SKILL_QUESTIONS_COUNT,
            payload: response.data,
          });
          await dispatch({
            type: SKILL_PRACTICE_COMPLETED,
            payload: response.data,
          });
        }
      } else if (response.status === 500 || response.message === "Failure" || response.data.isCompleted) {
        await dispatch({
          type: SKILL_PRACTICE_COMPLETED,
          payload: response.data,
        });
      } else {
        await dispatch({
          type: START_SKILL_PRACTICE_SUCCESS,
          payload: response.data,
        });
        return data;
      }
    } catch (error) {
      await dispatch({ type: SET_ASSIGNMENT_ID, payload: assignmentId });
      await dispatch({
        type: START_SKILL_PRACTICE_FAILURE,
        payload: error.message,
      });
      return ;
    }
  };

export const startRCSkillPracticeApi =
  (
    passageId,
    assignmentId,
    token,
    questionId = null,
    submittedAnswer = null,
    isSkipped = null,
    timeSpentPerQuestion = null,
    isStarted,
    isResume
  ) =>
  async (dispatch) => {
    dispatch({
      type: START_RC_SKILL_PRACTICE_REQUEST,
    });
    try {
      let response;
      response = await startRCSkillPractice(
        passageId,
        assignmentId,
        token,
        questionId,
        submittedAnswer,
        isSkipped,
        timeSpentPerQuestion,
        isStarted,
        isResume
      );

      let data = {
        accuracyRate: response.data?.accuracyRate,
        attemptedQuestionsCount: response.data?.attemptedQuestionsCount,
        totalTimeSpent: response.data?.totalTimeSpent,
      };
      if (response.data.isCompleted) {
          await dispatch({
            type: RC_SKILL_QUESTIONS_COUNT,
            payload: response.data,
          });
          await dispatch({
            type: RC_SKILL_PRACTICE_COMPLETED,
            payload: response.data,
          });
      } else if (response.status === 500 || response.message === "Failure" || response.data.isCompleted) {
        await dispatch({
          type: RC_SKILL_PRACTICE_COMPLETED,
          payload: response.data,
        });
      } else {
        await dispatch({
          type: START_RC_SKILL_PRACTICE_SUCCESS,
          payload: response.data,
        });
        return data;
      }
    } catch (error) {
      await dispatch({ type: SET_ASSIGNMENT_ID, payload: assignmentId });
      await dispatch({
        type: START_RC_SKILL_PRACTICE_FAILURE,
        payload: error.message,
      });
      return ;
    }
  };

export const practiceFinished = (data) => ({
  type: SKILL_PRACTICE_FINISHED,
  payload: data,
});
export const practiceCompleted = (data) => ({
  type: SKILL_PRACTICE_COMPLETED,
  payload: data,
});
