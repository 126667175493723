import { useSelector } from "react-redux";
import ReactQuill from "react-quill";

const Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = ["sans-serif", "serif", "monospace"];
ReactQuill.Quill.register(Font, true);

export const useQuillConfig = () => {
  const { staffAccess } = useSelector((state) => state.icons);
  let modules = {},
    formats = [];

  if (staffAccess) {
    modules = {
      toolbar: [
        [{ font: Font.whitelist }],
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        
      ],
    };

    formats = [
      "font",
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "script",
      "color",
      "background",
      "align",
      "list",
      "bullet",
    ];
  } else {
    modules = {
      toolbar: [
        [{ font: Font.whitelist }],
        [{ header: [1, 2, 3, false] }],

        ["bold", "italic", "underline"],
        [{ script: "sub" }, { script: "super" }],

        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };

    formats = [
      "font",
      "header",
      "bold",
      "italic",
      "underline",
      "script",
      "color",
      "background",
      "align",
      "list",
      "bullet",
    ];
  }
  
  return { formats, modules };
};
