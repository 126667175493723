const questionTypeConfig = {
  answerType: [1, 2, 3, 4],

  imageTypeQuestion: [4, 5, 6, 13, 14, 15, 20],
  textTypeQuestion: [1, 2, 3, 10, 11, 12, 19, 22],
  textAndImageTypeQuestion: [7, 8, 9, 16, 17, 18, 21, 23],

  readingComprehensionType: [24],

  imageTypeOption: [1, 4, 7, 10, 13, 16],
  textTypeOption: [2, 5, 8, 11, 14, 17],
  textAndImageTypeOption: [3, 6, 9, 12, 15, 18],

  textAnswerTypeInputBox: [19, 20, 21],

  fillInTheBlanksType: [21, 22, 23],
  fillInTheBlanksWithImageType: [23],

  isMultiSelect: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  isSingleSelect: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
};

export default questionTypeConfig;
