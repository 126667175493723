const BASE_URL = process.env.REACT_APP_BASE_URL;


export const getDomainsByLevelIdApi = async (token, levelId) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/getDomainsByLevelIdForLibrary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ levelId }),
    });


    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Error in startWritingTestApi:", error);
    throw new Error("Network response was not ok");
  }
};


export const getTopicsByDomainIdApi = async (token, domainId) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/getTopicsByDomainIdForLibrary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ domainId }),
    });


    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data || [];
  } catch (error) {
    console.error("Error in startWritingTestApi:", error);
    throw new Error("Network response was not ok");
  }
};



export const getSkillsByTopicIdApi = async (token, topicId) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/getSkillsByTopicIdForLibrary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ topicId }),
    });


    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Error", error);
    throw new Error("Network response was not ok");
  }
};

export const startLibraryQuestionApi = async (token, data) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/startLibraryAssignment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    return responseData || [];
  } catch (error) {
    console.error("Error in startLibraryTestApi:", error);
    throw new Error("Network response was not ok");
    
  }
};

export const libraryNextQuestionApi = async (token, data) => {
  try {
    const response = await fetch(`${BASE_URL}/sd/v1/startNextLibraryQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();

    return responseData || [];
  } catch (error) {
    throw new Error("Network response was not ok");
  }
}
