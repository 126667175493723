import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
// components imports
import EnvTest from "../../Components/EnvTest";
import Loader from "../../Components/Loader/Loader";
import Error404 from "../../Components/Error404/Error404";
// layouts imports
import LayoutWithheaderAndTabs from "../../layouts/Layout/LayoutWithheaderAndTabs";
import LayoutWithHeader from "../../layouts/Layout/LayoutWithHeader";
import LayoutWithoutProfile from "../../layouts/Layout/LayoutWithoutProfile";
// test imports
import TestViewer from "../Test/TestBuilder/TestViewer";
import ReadingTestViewer from "../Test/ReadingTestBuilder/ReadingTestViewer";
import GradeWiseDetailedReport from "../../Components/Progress-Report/GradeWiseDetailedReport";
import WritingTestViewer from "../Test/WritingTestBuilder/WritingTestViewer";
import WritingTestDetails from "../Test/WritingTestBuilder/WritingTestDetails";
import WritingCompletedDetails from "../Test/WritingTestBuilder/WritingCompletedDetails";
import LibraryHome from "../../Components/Library/LibraryHome.jsx";
import SpellingAndVocabCompletedDetails from "../../Components/SpellingAndVocabCommon/SpellingAndVocabCompletedDetails.js";
import SkillPracticeVideoModal from "../Test/TestBuilder/SkillPracticeVideoModal.jsx";

// lazy loading
const HomePage = lazy(() => import("../HomePage/HomePage"));
const WelcomeCard = lazy(() => import("../../Components/Card/WelcomeCard"));
const EnrollCard = lazy(() => import("../../Components/Card/EnrollCard"));

const ProgressReport = lazy(() =>
  import("../../Components/Progress-Report/ProgressReport")
);
const AssignTestPage = lazy(() => import("../AssignTest/AssignTestPage"));
const RealTimeTracker = lazy(() =>
  import("../RealTimeTracker/RealTimeTracker")
);
const ViewCompletedDetails = lazy(() =>
  import("../../Components/ViewCompletedDetails/ViewCompletedDetails")
);

const Paths = () => {
  const { role } = useSelector((state) => state.details);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/math/assignments" replace />}
          />
          <Route path="/" element={<LayoutWithheaderAndTabs />}>
            <Route
              path="ela/writing/completedassignment"
              element={<WritingCompletedDetails />}
            />

            <Route path="math/assignments" element={<HomePage />} exact />
            <Route path="ela/assignments" element={<Navigate to="/ela/language/assignments" replace />}  />
            <Route
              path="ela/language/assignments"
              element={<HomePage />}
              exact
            />
            <Route
              path="ela/reading/assignments"
              element={<HomePage />}
              exact
            />
            <Route
              path="ela/writing/assignments"
              element={<HomePage />}
              exact
            />
            <Route path="ela/spelling/practice" element={<HomePage />} exact />
            <Route
              path="ela/vocabulary/practice"
              element={<HomePage />}
              exact
            />
            <Route
              path="staff/library"
              element={role==="student"?<Error404/>:<LibraryHome staff={'staff'}/>}
              exact
            />                      

            <Route
              path="math/assignment/details"
              element={<ViewCompletedDetails />}
              exact
            />

            <Route
              path="ela/language/assignment/details"
              element={<ViewCompletedDetails />}
              exact
            />
            <Route
              path="ela/reading/assignment/details"
              element={<ViewCompletedDetails />}
              exact
            />
            <Route
              path="math/progress-report"
              element={<ProgressReport />}
              exact
            />
            <Route path="math/library" element={<LibraryHome />} />

            <Route
              path="ela/language/progress-report"
              element={<ProgressReport />}
              exact
            />
            <Route path="ela/language/library" element={<LibraryHome />} />

            <Route
              path="ela/reading/progress-report"
              element={<ProgressReport />}
              exact
            />
            <Route path="ela/reading/library" element={<LibraryHome />} />

            <Route
              path="ela/writing/progress-report"
              element={<ProgressReport />}
              exact
            />
            <Route
              path="ela/spelling/progress-report"
              element={<ProgressReport />}
              exact
            />
            <Route
              path="ela/vocabulary/progress-report"
              element={<ProgressReport />}
              exact
            />
            <Route
              path="math/progress-report/grade-wise-detailed-report"
              element={<GradeWiseDetailedReport />}
              exact
            />
            <Route
              path="ela/language/progress-report/grade-wise-detailed-report"
              element={<GradeWiseDetailedReport />}
              exact
            />
            <Route
              path="ela/writing/progress-report/grade-wise-detailed-report"
              element={<GradeWiseDetailedReport />}
              exact
            />
            <Route
              path="ela/spelling/progress-report/grade-wise-detailed-report"
              element={<SpellingAndVocabCompletedDetails />}
              exact
            />
            <Route
              path="ela/vocabulary/progress-report/grade-wise-detailed-report"
              element={<SpellingAndVocabCompletedDetails />}
              exact
            />
            <Route
              path="ela/reading/progress-report/grade-wise-detailed-report"
              element={<GradeWiseDetailedReport/>}
              exact
            />
            <Route
              path="math/assign-test"
              element={<AssignTestPage />}
              exact
            />
            <Route
              path="ela/language/assign-test"
              element={<AssignTestPage />}
              exact
            />
            <Route
              path="ela/reading/assign-test"
              element={<AssignTestPage />}
              exact
            />
            <Route
              path="ela/writing/assign-test"
              element={<AssignTestPage />}
              exact
            />
          </Route>
          <Route path="/" element={<LayoutWithHeader />}>
            <Route path="/math/enroll" element={<EnrollCard />} exact />
            <Route path="ela/enroll" element={<EnrollCard />} exact />
          </Route>
          <Route path="/" element={<LayoutWithoutProfile />}>
            <Route path="realTimeTracker" element={<RealTimeTracker />} exact />
          </Route>
          <Route
            path="ela/writing/assignment/details"
            element={<WritingTestDetails />}
          />
          <Route path="math/benchmark-test" element={<TestViewer />} />
          <Route path="ela/benchmark-test" element={<TestViewer />} />
          <Route path="math/placement-test" element={<TestViewer />} />
          <Route path="/welcome" element={<WelcomeCard />} />
          <Route path="/ela/placement-test" element={<TestViewer />} />
          <Route path="/ela/skill-practice" element={<TestViewer />} />
          <Route
            path="/reading/skill-practice"
            element={<ReadingTestViewer />}
          />
          <Route
            path="/reading/benchmark-test"
            element={<ReadingTestViewer />}
          />
          <Route path="/math/skill-practice" element={<TestViewer />} />
          <Route
            path="/writing/skill-practice"
            element={<WritingTestViewer />}
          />
          <Route path="/env" element={<EnvTest />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/videoModal" element={<SkillPracticeVideoModal />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Paths;
