import { Outlet } from "react-router-dom";
import Header from "../../Components/Header/Header";
import HomeButtonGroup from "../../Components/HomeButtonGroup/HomeButtonGroup";
import ELASubMenu from "../../Components/ELASubMenu/ELASubMenu";
import { useLocation } from "react-router-dom";

const LayoutWithheaderAndTabs = () => {
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();
  const isELA = pathname.includes("/ela");

  return (
    <div className="student-container">
      <Header />
      {isELA && (
        <div className="ela-sub-menu-container">
          <ELASubMenu />
        </div>
      )}
      <div className={isELA ? "ela-tabs-alignment" : "tabsAlignment"}>
        <HomeButtonGroup />
      </div>
      <div className="container-fluid">
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default LayoutWithheaderAndTabs;
