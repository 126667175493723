/* eslint-disable no-whitespace-before-property */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDomainsByLevelIdApi,
  getTopicsByDomainIdApi,
  getSkillsByTopicIdApi,
  startLibraryQuestionApi,
  libraryNextQuestionApi,
} from "../../../Utils/ApiHandler/LibraryApi";

const initialState = {
  data: {
    domains: JSON.parse(localStorage.getItem("libraryDomains")) || [],
    topics: JSON.parse(localStorage.getItem("libraryTopics")) || [],
  },
  questionData:
    JSON.parse(localStorage.getItem("libraryCurrentQuestion")) || {},
  loading: false,
  error: null,
  testError: null,
  domainsError: null,
  topicsError: null,
};

export const getDomainsByLevelIdThunk = createAsyncThunk(
  "library/getDomainsByLevelId",
  async ({ token, levelId, emailId }) => {
    const response = await getDomainsByLevelIdApi(token, levelId);
    return response;
  }
);

export const getTopicsByDomainIdThunk = createAsyncThunk(
  "library/getTopicsByDomainId",
  async ({ token, domainId }) => {
    const response = await getTopicsByDomainIdApi(token, domainId);
    return response;
  }
);

export const getSkillsByTopicIdThunk = createAsyncThunk(
  "library/getSkillsByTopicId",
  async ({ token, topicId }) => {
    const response = await getSkillsByTopicIdApi(token, topicId);
    return response.data;
  }
);

export const startLibraryQuestionThunk = createAsyncThunk(
  "library/startLibraryQuestion",
  async ({ token, data }) => {
    const response = await startLibraryQuestionApi(token, data);
    return response;
  }
);

export const libraryNextQuestionThunk = createAsyncThunk(
  "library/libraryNextQuestion",
  async ({ token, data }) => {
    const response = await libraryNextQuestionApi(token, data);
    return response.data;
  }
);

export const libraryQuestionTimePause = createAsyncThunk(
  "library/libraryTimePause",
  async ({ token, data }) => {
    const response = await startLibraryQuestionApi(token, data);
    return response.data;
  }
);

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    clearLibraryDomains: (state, action) => {
      state.data = {};
    },
    clearLibraryQuestion: (state, action) => {
      state.questionData = {}
    },
    setLibraryLoading:(state,action)=>{
      state.loading = true
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDomainsByLevelIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDomainsByLevelIdThunk.fulfilled, (state, action) => {
        if (!action.payload.success) {
          state.domainsError =
            action.payload?.message ||
            "Failed to fetch domains for the selected level";
          state.data = null;
        } else {
          state.loading = false;
          state.data = {
            ...state.data,
            domains: action.payload?.data,
          };
        }
      })
      .addCase(getDomainsByLevelIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.domainsError = action.error.message;
      })
      .addCase(getTopicsByDomainIdThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTopicsByDomainIdThunk.fulfilled, (state, action) => {
        if (!action.payload.success) {
          state.topicsError =
            action.payload.message ||
            "Failed to fetch topics for the selected domain";
          state.data = null;
        } else {
          state.loading = false;
          localStorage.setItem(
            "libraryTopics",
            JSON.stringify(action.payload.data.topics)
          );
          state.data = {
            ...state.data,
            topics: action.payload.data.topics,
          };
        }
      })
      .addCase(getTopicsByDomainIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.topicsError =
          action.error?.message ||
          "Failed to fetch topics for the selected domain";
      })
      .addCase(getSkillsByTopicIdThunk.pending, (state) => {
        state.loading = false;
        state.error = null; // Clear previous error
      })
      .addCase(getSkillsByTopicIdThunk.fulfilled, (state, action) => {
        if (action.payload?.error) {
          state.error = action.payload?.error;
          state.data = null;
        } else {
          state.loading = false;
          const { topicId, skills } = action.payload;

          // Check if topics array exists
          if (state.data?.topics) {
            const topicIndex = state.data.topics.findIndex(
              (topic) => topic._id === topicId
            );

            if (topicIndex !== -1) {
              // Update the skills for the specific topic
              state.data = {
                ...state.data,
                topics: [
                  ...state.data.topics.slice(0, topicIndex),
                  {
                    ...state.data.topics[topicIndex],
                    skills: skills,
                  },
                  ...state.data.topics.slice(topicIndex + 1),
                ],
              };
            } else {
              console.error("Topic not found for topicId:", topicId);
            }
          } else {
            console.error("Topics data is null or undefined");
          }
        }
      })
      .addCase(getSkillsByTopicIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(startLibraryQuestionThunk.pending, (state) => {
        state.loading = true;
        state.testError = null; // Clear previous error
      })
      .addCase(startLibraryQuestionThunk.fulfilled, (state, action) => {
        if (!action.payload?.success) {
          state.testError = true;
          state.data = null;
        } else {
          state.loading = false;
          localStorage.setItem(
            "libraryCurrentQuestion",
            JSON.stringify(action.payload.data)
          );
          state.questionData = action.payload.data;
        }
      })
      .addCase(startLibraryQuestionThunk.rejected, (state, action) => {
        state.loading = false;
        state.testError = action.error.message;
      })
      .addCase(libraryNextQuestionThunk.pending, (state) => {
        state.loading = true;
        state.testError = null; // Clear previous error
      })
      .addCase(libraryNextQuestionThunk.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.testError = action.payload.error;
          state.data = null;
        } else {
          state.loading = false;
          localStorage.setItem(
            "libraryCurrentQuestion",
            JSON.stringify(action.payload)
          );
          state.questionData = action.payload;
        }
      })
      .addCase(libraryNextQuestionThunk.rejected, (state, action) => {
        state.loading = false;
        state.testError = action.error.message;
      });
  },
});

export const { clearLibraryDomains, clearLibraryQuestion, setLibraryLoading } = librarySlice.actions;

export default librarySlice.reducer;
