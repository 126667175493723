import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//slice imports
import { setShowAssign } from "../redux/slices/icons/iconsSlice";
import {
  setAccessToken,
  setFirstName,
  setLastName,
  setWorkEmail,
  setEnrolledPrograms,
  setAuthenticated,
  setLevelOrder,
  setRole,
  setStaffEmail,
  setStaffFirstName,
  setStaffLastName,
  setPresentProgram,
  setAuthMsg,
} from "../redux/slices/studentDetails/studentDetailsSlice";
//Utils imports
import { Authenticate } from "../Utils/ApiHandler/AuthApi";

const useAuthenticate = () => {
  const [authResult, setAuthResult] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roleType,setRoleType] = useState('');
  const [staffData,setStaffData] = useState({})
  const location = useLocation();

  // get current pathname or token
  const pathname = location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  const token =
    queryParams.get("token") ||
    sessionStorage.getItem("token") ||
    localStorage.getItem("token");

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.details);

  useEffect(() => {
    if (isAuthenticated) {
      setAuthResult(true);
      setLoading(false);
    } else {
     authentication();
    }
  }, []);


  
  // Authentication function
  const authentication = async () => {
    if (token) {
      localStorage.setItem("token", token);
      const response = await Authenticate(token);
      if (response.success) {
        const data = response.data;
        setStaffData(data.staff)
        setRoleType(data.role)
        dispatch(setAccessToken(data.sdAccessToken));
        dispatch(setFirstName(data.firstName));
        dispatch(setLastName(data.lastName));
        dispatch(setWorkEmail(data.email));
        dispatch(setEnrolledPrograms(data.enrolledPrograms));
        dispatch(setLevelOrder(data.grade));

        if (data.staff.firstName) {
          dispatch(setShowAssign());
          dispatch(setRole(data.staff.role));
          dispatch(setStaffEmail(data.staff.email));
          dispatch(setStaffFirstName(data.staff.firstName));
          dispatch(setStaffLastName(data.staff.lastName));
          sessionStorage.setItem("token", token);
        } else {
          dispatch(setRole(data.role));
          localStorage.setItem("token", token);
        }
        dispatch(setAuthenticated());
        const program = (pathname.split("/").pop() || "").toUpperCase();
        dispatch(setPresentProgram(program));
        setAuthResult(true);
      } else {
        dispatch(setAuthMsg("Failed to authenticate, please try again"));
      }
    } else {
      dispatch(setAuthMsg("Authorization token is missing, please try again"));
    }
    setLoading(false);
  };
  return { authResult, loading, setLoading, isAuthenticated,roleType, staffData };
};

export { useAuthenticate };
