const base_url = process.env.REACT_APP_BASE_URL;

// Spelling practice level API
export const spellingPracticeLevelApi = async (
  programId,
  workEmail,
  levelId,
  authToken
) => {
  const response = await fetch(
    `${base_url}/sd/v1/spellingPractice/getAllLevels?programId=${programId}&studentEmail=${workEmail}&levelId=${levelId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  const responseJson = await response.json();
  return responseJson;
};

// Spelling Practice API
export const SpellingPracticeStartApi = async (
  workEmail,
  programId,
  levelId,
  levelOrder,
  authToken
) => {
  const payload = {
    studentEmail: workEmail,
    programId: programId,
    levelId: levelId,
    levelOrder: levelOrder,
  };

  const response = await fetch(`${base_url}/sd/v1/spellingPractice/start`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};

export const SpellingPracticeStartSkippedQuestionApi = async (
  workEmail,
  programId,
  levelId,
  levelOrder,
  authToken
) => {
  const payload = {
    studentEmail: workEmail,
    programId: programId,
    levelId: levelId,
    levelOrder: levelOrder,
  };

  const response = await fetch(`${base_url}/sd/v1/spellingPractice/startSkippedQuestion`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};

export const SpellingPracticeStartIncorrectQuestionApi = async (
  workEmail,
  programId,
  levelId,
  levelOrder,
  authToken
) => {
  const payload = {
    studentEmail: workEmail,
    programId: programId,
    levelId: levelId,
    levelOrder: levelOrder,
  };

  const response = await fetch(`${base_url}/sd/v1/spellingPractice/startIncorrectQuestion`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};

// Spelling Skip API
export const SpellingPracticeSkipApi = async (
  workEmail,
  programId,
  levelId,
  questionId,
  levelOrder,
  timeSpent,
  skippedPracticeSession,
  incorrectPracticeSession,
  authToken
) => {
  const payload = {
    studentEmail: workEmail,
    programId: programId,
    levelId: levelId,
    questionId: questionId,
    levelOrder: levelOrder,
    timeSpent: timeSpent,
    skippedPracticeSession: skippedPracticeSession,
    incorrectPracticeSession: incorrectPracticeSession
  };

  const response = await fetch(`${base_url}/sd/v1/spellingPractice/skip`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};

// Spelling Submit API
export const SpellingPracticeSubmitApi = async (
  workEmail,
  programId,
  levelId,
  levelOrder,
  questionId,
  answer,
  timeSpent,
  skippedPracticeSession,
  incorrectPracticeSession,
  authToken
) => {
  const payload = {
    studentEmail: workEmail,
    programId: programId,
    levelId: levelId,
    levelOrder: levelOrder,
    questionId: questionId,
    submittedAnswer: [answer],
    timeSpent: timeSpent,
    skippedPracticeSession: skippedPracticeSession,
    incorrectPracticeSession: incorrectPracticeSession
  };

  const response = await fetch(`${base_url}/sd/v1/spellingPractice/submit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = await response.json();
  return responseJson;
};
