const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDomainsMasteryByLevelIdApi = async (
  token,
  programId,
  levelId,
  masteryType
) => {
  try {
    let url = '';
    let body = {}
    if (window.location.href.includes("reading") || window.location.href.includes("writing")) {
      url = `${BASE_URL}/sd/v1/getDomainsBylevelIdForGradeMasteryForRcAndWriting`;
      body = { programId, levelId }
    } else {
      url = `${BASE_URL}/sd/v1/getDomainsBylevelIdForGradeMastery`;
      body = { programId, levelId, masteryType }
    }
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Error in fetching domains data for grade mastery", error);
    throw new Error("Network response was not ok");
  }
};

export const getTopicsMasteryByDomainIdApi = async (
  token,
  programId,
  levelId,
  domainId,
  masteryType
) => {
  try {
    let url = '';
    let body = {}
    if (window.location.href.includes("reading") || window.location.href.includes("writing")) {
      url = `${BASE_URL}/sd/v1/getTopicsByDomainIdForGradeMasteryForRcAndWriting`;
      body = { programId, levelId, domainId }
    } else {
      url = `${BASE_URL}/sd/v1/getTopicsByDomainIdForGradeMastery`;
      body = { programId, levelId, domainId, masteryType }
    }
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data || [];
  } catch (error) {
    console.error("Error in fetching topics data for grade mastery:", error);
    throw new Error("Network response was not ok");
  }
};

export const getSkillsMasteryByTopicIdApi = async (
  token,
  programId,
  levelId,
  domainId,
  topicId,
  masteryType
) => {
  try {
    let url = '';
    let body = {}
    if (window.location.href.includes("reading") || window.location.href.includes("writing")) {
      url = `${BASE_URL}/sd/v1/getSkillsByTopicIdForGradeMasteryForRcAndWriting`;
      body = { programId, levelId, domainId, topicId }
    } else {
      url = `${BASE_URL}/sd/v1/getSkillsByTopicIdForGradeMastery`;
      body = { programId, levelId, domainId, topicId, masteryType }
    }
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error("Error", error);
    throw new Error("Network response was not ok");
  }
};
