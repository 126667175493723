import React from 'react';
import testEndCone from "../../Assets/Images/testEndCone.svg"

const LibraryFinishCard = ({ handleContinue }) => {
    return (
        <div className="container " style={{

            height: "fit-content",
            paddingBottom: "70px",
            paddingTop: "70px"
        }}>
            <div className="row  justify-content-center">
                <div className="col-md-7 pt-4 pb-4" style={{
                    border: "1px solid #F4F4F4",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    borderRadius: "0.5rem",
                    backgroundColor: "#FFF"
                }}>
                    <div className="row row-1 justify-content-center align-items-center mb-3 ">
                        <img src={testEndCone} alt="cone" style={{ width: "17%" }} />
                    </div>
                    <div className="row row-2 justify-content-center">
                        <p className="text-center text-dark fw-bold fs-4">Congratulations</p>
                        <p className="text-center text-muted fw-semibold fs-5 ">Test Ended successfully</p>
                        <div className="mt-1 text-center" onClick={handleContinue}>
                            <button
                                className="flex-shrink-0 text-white bg-primary border-0 fw-semibold 
                    rounded-pill p-3 pt-2 pb-2"
                            >
                                Let's continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LibraryFinishCard