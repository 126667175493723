import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchStudentBasicStats,
  fetchStudentGradeMasteryStats,
  fetchStudentProgressStats,
  getStudentProgressStatsReport,
  resetGradeLevelMasteryDataApi,
} from "../../../Utils/ApiHandler/ProgressReportApi";

const initialState = {
  progressData: {},
  progressLoading: false,
  error: null,
};

export const resetGradeLevelMasteryDataThunk = createAsyncThunk(
  "progress/resetGradeLevelMasteryData",
  async ({ token, programId }) => {
    const response = await resetGradeLevelMasteryDataApi(token, programId);
    return response;
  }
);

const getProgressUrlsByProgramId = (location) => {
  switch (true) {
    case location.pathname.includes("math") ||
      location.pathname.includes("language"):
      return {
        basicStats: "getBasicStudentStats",
        additionalData: "/getStudentProgressStats",
        performanceData: "/getStudentGradeMasteryStats",
      };

    case location.pathname.includes("reading"):
      return {
        basicStats: "getBasicStudentStatsForRc",
        additionalData: "getStudentProgressStatsForRc",
        performanceData: "getGradeLevelMasteryForRc",
      };
    case location.pathname.includes("writing"):
      return {
        basicStats: "getStudentBasicStatsForWriting",
        additionalData: "getStudentProgressStatsForWriting",
        performanceData: "getGradeLevelMasteryForWriting",
      };
    default:
      return {};
  }
};

export const getStudentBasicStatsThunk = createAsyncThunk(
  "progress/getStudentBasicStats",
  async ({ token, programId, location, startDate, endDate }) => {
    const progressUrls = getProgressUrlsByProgramId(location);
    const response = await fetchStudentBasicStats(
      token,
      programId,
      startDate,
      endDate,
      progressUrls.basicStats
    );
    return response;
  }
)

export const getStudentProgressStatsThunk = createAsyncThunk(
  "progress/getStudentProgressStats",
  async ({ token, programId, location, startDate, endDate }) => {
    const progressUrls = getProgressUrlsByProgramId(location);
    const response = await fetchStudentProgressStats(
      token,
      programId,
      startDate,
      endDate,
      progressUrls.additionalData
    );
    return response;
  }
)

export const getStudentGradeMasteryStatsThunk = createAsyncThunk(
  "progress/getStudentGradeMasteryStats",
  async ({ token, programId, location, startDate, endDate }) => {
    const progressUrls = getProgressUrlsByProgramId(location);
    const response = await fetchStudentGradeMasteryStats(
      token,
      programId,
      startDate,
      endDate,
      progressUrls.performanceData
    );
    return response;
  }
)

export const getStudentRWProgressReportThunk = createAsyncThunk(
  "progress/getStudentReadingWritingProgress",
  async ({ token, programId, startDate, endDate }) => {
    const response = await getStudentProgressStatsReport(
      token,
      programId,
      startDate,
      endDate
    );
    return response;
  }
);

//Reducer to get the progress for spelling and vocabulary.
export const getStudentSVProgressReportThunk = createAsyncThunk(
  "progress/getStudentSpellingVocabProgress",
  async ({ token, programId, startDate, endDate }) => {
    const response = await getStudentProgressStatsReport(
      token,
      programId,
      startDate,
      endDate
    );
    return response;
  }
);

/* Slice function for progress report to fetch basic stats, grade level mastery
 and progress report. */
const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    resetProgressData: (state) => {
      state.progressData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentSVProgressReportThunk.pending, (state) => {
        state.progressLoading = true;
        state.error = null;
        state.progressData = {};
      })
      .addCase(getStudentSVProgressReportThunk.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.progressData = action.payload.data;
      })
      .addCase(getStudentSVProgressReportThunk.rejected, (state, action) => {
        state.progressLoading = false;
        state.error = action.error.message;
        state.progressData = {};
      })

      .addCase(resetGradeLevelMasteryDataThunk.pending, (state) => {
        state.progressLoading = true;
        state.error = null;
        state.progressData = {};
      })
      .addCase(resetGradeLevelMasteryDataThunk.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.progressData = {};
      })
      .addCase(resetGradeLevelMasteryDataThunk.rejected, (state, action) => {
        state.progressLoading = false;
        state.error = action.error.message;
        state.progressData = {};
      })
      .addCase(getStudentRWProgressReportThunk.pending, (state) => {
        state.progressLoading = true;
        state.error = null;
        state.progressData = {};
      })
      .addCase(getStudentRWProgressReportThunk.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.progressData = action.payload.data;
      })
      .addCase(getStudentRWProgressReportThunk.rejected, (state, action) => {
        state.progressLoading = false;
        state.error = action.error.message;
        state.progressData = {};
      })
      .addCase(getStudentBasicStatsThunk.pending, (state) => {
        state.progressLoading = true;
        state.error = null;
      })
      .addCase(getStudentBasicStatsThunk.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.progressData = {
          ...state.progressData,
          timeLearning: action.payload.data?.timeLearning || 0,
          questionsAnswered:
            action.payload.data?.questionsAnswered || 0,
          skillsMastered: action.payload.data?.skillsMastered || 0,
          benchmarkScore: action.payload.data?.benchmarkScore || 0,
          accuracyRate: action.payload.data?.accuracyRate || 0
        };
      })
      .addCase(getStudentBasicStatsThunk.rejected, (state, action) => {
        state.progressLoading = false;
        state.error = action.error.message;
      })
      .addCase(getStudentProgressStatsThunk.pending, (state) => {
        state.progressLoading = true;
        state.error = null;
      })
      .addCase(getStudentProgressStatsThunk.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.progressData = {
          ...state.progressData,
          dailyProgress: action.payload.data?.dailyProgress,
          monthlyProgress: action.payload.data?.monthlyProgress,
          weeklyProgress: action.payload.data?.weeklyProgress,
        };
      })
      .addCase(getStudentProgressStatsThunk.rejected, (state, action) => {
        state.progressLoading = false;
        state.error = action.error.message;
      })
      .addCase(getStudentGradeMasteryStatsThunk.pending, (state) => {
        state.progressLoading = true;
        state.error = null;
      })  
      .addCase(getStudentGradeMasteryStatsThunk.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.progressData = {
          ...state.progressData,
          gradeLevelMastery: action.payload.data?.gradeLevelMastery,
        };
      })
      .addCase(getStudentGradeMasteryStatsThunk.rejected, (state, action) => {  
        state.progressLoading = false;
        state.error = action.error.message;   
      });
  },
});

export const { resetProgressData } = progressSlice.actions;
// Export the reducer properly
export default progressSlice.reducer;
