import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const startSkillPractice = async (
  questionId,
  assignmentId,
  submittedAnswer,
  isSkipped,
  timeSpentPerQuestion,
  token,
  isStarted,
  isResume
) => {
  try {
    const url = `${BASE_URL}/sd/v2/startSkillTest`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const body = !isStarted
      ? JSON.stringify({
          questionId,
          assignmentId,
          submittedAnswer,
          isSkipped,
          timeSpent: timeSpentPerQuestion,
        })
      : isResume
      ? JSON.stringify({
          assignmentId,
          timeSpent: timeSpentPerQuestion,
          isPause: true,
        })
      : JSON.stringify({ assignmentId });
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });
    if (!response.ok) {
      if (response.status === 500) {
        toast.error("Something went wrong, Please try again after some time.");
      }
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error(`Failed to get next question: ${error.message}`);
  }
};

export const startRCSkillPractice = async (
  passageId,
  questionId,
  assignmentId,
  submittedAnswer,
  isSkipped,
  timeSpentPerQuestion,
  token,
  isStarted,
  isResume
) => {
  try {
    const url = `${BASE_URL}/sd/v2/startRcSkillTest`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = !isStarted
      ? JSON.stringify({
          passageId,
          questionId,
          assignmentId,
          submittedAnswer,
          isSkipped,
          timeSpent: timeSpentPerQuestion,
        })
      : isResume
      ? JSON.stringify({
          assignmentId,
          timeSpent: timeSpentPerQuestion,
          isPause: true,
        })
      : JSON.stringify({ assignmentId });
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });
    if (!response.ok) {
      if (response.status === 500) {
        toast.error("Something went wrong, Please try again after some time.");
      }
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error(`Failed to get next question: ${error.message}`);
  }
};
