import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  sdaccessToken: "",
  firstName: "",
  lastName: "",
  workEmail: "",
  enrolledPrograms: [],
  role: "",
  authErrMsg: "Failed to Authenticate, Please try again later",
  staffFirstName: "",
  staffLastName: "",
  staffEmail: "",
  presentProgram: "",
  levelOrder: "",
};

const DetailSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      localStorage.setItem('currentSdAccessToken',action.payload)
      state.sdaccessToken = action.payload;
    },
    setAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setWorkEmail: (state, action) => {
      state.workEmail = action.payload;
    },
    setEnrolledPrograms: (state, action) => {
      state.enrolledPrograms = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },

    setAuthMsg: (state, action) => {
      state.authErrMsg = action.payload;
    },
    setStaffFirstName: (state, action) => {
      state.staffFirstName = action.payload;
    },
    setStaffLastName: (state, action) => {
      state.staffLastName = action.payload;
    },
    setStaffEmail: (state, action) => {
      state.staffEmail = action.payload;
    },
    setPresentProgram: (state, action) => {
      state.presentProgram = action.payload;
    },
    setLevelOrder: (state, action) => {
      state.levelOrder = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setAuthenticated,
  setFirstName,
  setLastName,
  setWorkEmail,
  setEnrolledPrograms,
  setRole,
  setAuthMsg,
  setStaffFirstName,
  setStaffLastName,
  setStaffEmail,
  setPresentProgram,
  setLevelOrder,
} = DetailSlice.actions;
export default DetailSlice.reducer;
