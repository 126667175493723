import { emitEvent } from "./emitSocketDataUtils";

// Assignment Events

export const emitStudentTrack = async (socket, data) => {
  return await emitEvent(socket, "student_track", data);
};

export const emitAssignmentStarted = async (socket, assignmentDetails) => {
  if (!assignmentDetails) {
    console.error("Assignment details missing for 'assignment_started'");
    return false;
  }

  const data = {
    assignmentId: assignmentDetails.assignmentId,
    student_name: `${assignmentDetails?.assignedTo?.firstName || ""} ${
      assignmentDetails?.assignedTo?.lastName || ""
    }`,
    startTime: new Date().toISOString(),
  };

  return await emitEvent(socket, "assignment_started", data);
};

export const emitAssignmentPaused = async (socket, assignmentDetails) => {
  if (!assignmentDetails) {
    console.error("Assignment details missing for 'assignment_paused'");
    return false;
  }

  const data = {
    assignmentId: assignmentDetails.assignmentId,
    student_name: `${assignmentDetails?.assignedTo?.firstName || ""} ${
      assignmentDetails?.assignedTo?.lastName || ""
    }`,
    pauseTime: new Date().toISOString(),
  };

  return await emitEvent(socket, "assignment_paused", data);
};

export const emitAssignmentEnded = async (
  socket,
  assignmentDetails,
  totalTimeSpent
) => {
  if (!assignmentDetails) {
    console.error("Assignment details missing for 'assignment_ended'");
    return false;
  }

  const data = {
    assignmentId: assignmentDetails.assignmentId,
    student_name: `${assignmentDetails?.assignedTo?.firstName || ""} ${
      assignmentDetails?.assignedTo?.lastName || ""
    }`,
    endTime: new Date().toISOString(),
    totalTimeSpent,
  };

  return await emitEvent(socket, "assignment_ended", data);
};

// Student events

export const emitStudentConnected = async (socket, student = {}) => {
  console.log(student);
  if (!student.student_name || !student.student_email) {
    console.error("Student data incomplete for 'studentConnected'");
    return false;
  }

  const data = {
    studentName: student.student_name,
    studentEmail: student.student_email_id,
    socketId: socket.id,
  };

  return await emitEvent(socket, "studentConnected", data);
};
