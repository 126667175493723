import React from "react";
import { Card, ProgressBar, Row, Col } from "react-bootstrap";
import Arithmetic from "../../Assets/Images/arithmetic.svg";
import Geometry from "../../Assets/Images/geometry.svg";
import NumberTheory from "../../Assets/Images/numbertheory.svg";
import Language from "../../Assets/Images/language.svg";
import Reading from "../../Assets/Images/reading.svg";
import Statistics from "../../Assets/Images/statistics.svg";
import Algebra from "../../Assets/Images/algebra.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const LibraryDomains = ({ domains, handleDomainChange, isStaff }) => {
  const { domainsError } = useSelector((state) => state.library);
  const location = useLocation();

  const tagImageToDomain = (domainName) => {
    const imageName = domainName?.trim().replace(/\s+/g, "").toLowerCase();
    let image = "";
    switch (imageName) {
      case "arithmetic":
        image = Arithmetic;
        break;
      case "geometry":
        image = Geometry;
        break;
      case "statistics":
        image = Statistics;
        break;
      case "numbertheory":
        image = NumberTheory;
        break;
      case "algebra":
        image = Algebra;
        break;
      default:
        if (location.pathname.includes("language")) {
          image = Language;
        } else if (location.pathname.includes("reading")) {
          image = Reading;
        }
    }
    return image;
  };
  if (domainsError) {
    return <div className="auth-err-msg">Failed to fetch domains</div>;
  }
  if (!domains) {
    return (
      <div className="auth-err-msg">
        No domains available for selected level
      </div>
    );
  }
  return (
    <Row
      style={{
        fontFamily: "Inter",
        maxHeight: "70vh",
        overflowY: "scroll",
      }}
      className="g-3 ps-3"
    >
      {domains?.map((item, index) => (
        <Col key={item?._id} lg={4} md={6} sm={12}>
          <Card
            onClick={() => handleDomainChange(item._id)}
            style={{
              background: "#FFF",
              boxShadow: "0px 1px 8px 0px rgba(58, 58, 58, 0.12)",
              position: "relative",
              cursor: "pointer",
              height: "100%",
            }}
          >
            <Card.Body className="d-flex flex-column justify-content-around">
              <Card.Title style={{ fontSize: "16px", width: "73%", fontWeight: "600" }}>
                {item?.title}
              </Card.Title>
              <Card.Text style={{ fontSize: "12px", fontWeight: "400" }}>
                {item?.topicsCount} topics, {item?.skillsCount} skills
              </Card.Text>
              <div className="d-flex gap-2 align-items-center">
                <div style={{ width: "60%" }}>
                  <ProgressBar
                    variant="success"
                    now={item?.domainScore}
                    style={{ height: "10px" }}
                  />
                </div>
                <span style={{ fontSize: "10px", fontWeight: "600" }}>
                  {item?.domainScore} %
                </span>
              </div>
            </Card.Body>
            {!isStaff &&
              <Card.Img
                src={tagImageToDomain(item?.title)}
                alt="Card image"
                style={{
                  width: "25%",
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                }}
              />
            }
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default LibraryDomains;
