import React, { useState } from "react";
import { Container, ListGroup, Accordion } from "react-bootstrap";
import Arithmetic from "../../Assets/Images/arithmetic.svg";
import Geometry from "../../Assets/Images/geometry.svg";
import goldMedal from "../../Assets/Images/gold-medal.svg";
import greyMedal from "../../Assets/Images/grey-medal.svg";
import NumberTheory from "../../Assets/Images/numbertheory.svg";
import Algebra from "../../Assets/Images/algebra.svg";
import Statistics from "../../Assets/Images/statistics.svg";
import Language from "../../Assets/Images/language.svg";
import Reading from "../../Assets/Images/reading.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SkillPracticeVideoModal from "../../Pages/Test/TestBuilder/SkillPracticeVideoModal";
import { startLibraryQuestionThunk } from "../../redux/slices/library/librarySlice";
const LibraryTopics = ({
  domain,
  topics,
  handleSkillSelect,
  handleTopicSelect,
  handleTopicBack,
  staff,
  isStaff
}) => {
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedSkillTitle, setSelectedSkillTitle] = useState("");
  const [selectedSkillVideo, setSelectedSkillVideo] = useState("");
  const [questionApiPayload, setQuestionApiPayload] = useState(
    JSON.parse(localStorage.getItem("questionPayload")) || {}
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.details.sdaccessToken);
  const { topicsError } = useSelector((state) => state.library);

  const openSkillModalHandler = (skillId, skillTitle, skillVideo) => {
    setSelectedSkill(skillId);
    setSelectedSkillTitle(skillTitle);
    setSelectedSkillVideo(skillVideo);
  };

  const handleCloseModal = () => {
    setSelectedSkill(null);
    setSelectedSkillTitle("");
    setSelectedSkillVideo("");
  };
  const tagImageToDomain = (domainName) => {
    const imageName = domainName?.trim().replace(/\s+/g, "").toLowerCase();
    let image = "";
    switch (imageName) {
      case "arithmetic":
        image = Arithmetic;
        break;
      case "geometry":
        image = Geometry;
        break;
      case "statistics":
        image = Statistics;
        break;
      case "numbertheory":
        image = NumberTheory;
        break;
      case "algebra":
        image = Algebra;
        break;
      default:
        if (location.pathname.includes("language")) {
          image = Language;
        } else if (location.pathname.includes("reading")) {
          image = Reading;
        }
    }

    return image;
  };

  if (topicsError) {
    return <div className="auth-err-msg">{topicsError}</div>;
  }

  if (!topics) {
    return (
      <div className="auth-err-msg">
        Topics are not available for the selected domain.
      </div>
    );
  }

  return (
    <Container
      fluid
      className="p-0 ms-3"
      style={{
        borderRadius: "8px",
        border: "1px solid #DFE3EB",
        background: "#FFF",
      }}
    >
      <Container
        fluid
        className="p-3"
        style={{
          fontFamily: "Inter",
          fontSize: "20px",
          borderBottom: "1px solid #DFE3EB",
          position: "relative",
          fontWeight: "600",
        }}
      >
        <i
          className="bi bi-arrow-left custom-icon"
          onClick={handleTopicBack}
        ></i>
        {domain?.title}
        {!isStaff &&
          <img
            src={tagImageToDomain(domain?.title)}
            alt="domain"
            height={"50px"}
            style={{ position: "absolute", right: "10px" }}
          />
        }
      </Container>
      {topics.length > 0 ? (
        <Accordion
          className="p-3"
          style={{ maxHeight: "60vh", overflowY: "scroll" }}
        >
          {topics?.map((topic, index) => (
            <Accordion.Item
              eventKey={topic._id}
              key={topic._id}
              className="mb-3 "
              style={{ border: "none", borderRadius: "4px" }}
            >
              <Accordion.Header
                style={{
                  fontSize: "16px",
                  border: "1px solid #EFEFEF",
                  borderRadius: "4px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTopicSelect(topic._id);
                }}
              >
                <div
                  style={{ flex: 1, fontSize: "0.9375rem", fontWeight: "500" }}
                >
                  {topic.title}
                </div>
                <div
                  className="d-flex gap-1 align-items-center"
                  style={{
                    color:
                      topic.topicScore > 75
                        ? "green"
                        : topic.topicScore >= 50 && topic.topicScore < 75
                          ? "orange"
                          : "red",
                    fontSize: "0.9375rem",
                    fontWeight: "500"
                  }}
                >
                  {topic.topicScore ? topic.topicScore : 0}%
                  <img
                    src={topic.topicScore === 100 ? goldMedal : greyMedal}
                    alt="Medal"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      flexShrink: "0",
                      top: "-10px",
                      position: "relative",
                    }}
                  />
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <ListGroup as="ol">
                  {topic?.skills ? (
                    topic?.skills?.length > 0 ? (
                      topic?.skills?.map((skill, index) => {
                        return (
                          <ListGroup.Item
                            key={skill._id}
                            className="d-flex justify-content-between skill-hover"
                            as="li"
                            onClick={(e) => {
                              e.stopPropagation();
                              staff
                                ? openSkillModalHandler(
                                    skill?._id,
                                    skill?.title,
                                    skill?.solution
                                  )
                                : handleSkillSelect(skill._id);
                            }}
                            style={{
                              backgroundColor: "#FAFAFA",
                              border: "none",
                              marginBottom: "6px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              fontSize: "0.8125rem",
                              fontWeight: "500",
                            }}
                          >
                            <div>
                              {index + 1}. {skill.title}
                            </div>
                            <div
                              className="d-flex gap-1"
                              style={{
                                float: "right",
                                color:
                                  skill.skillScore > 75
                                    ? "green"
                                    : skill.skillScore >= 50 &&
                                      skill.skillScore < 75
                                    ? "orange"
                                    : "red",
                              }}
                            >
                              {skill.skillScore ? skill.skillScore : 0}%
                              <img
                                src={
                                  skill.skillScore === 100
                                    ? goldMedal
                                    : greyMedal
                                }
                                alt="Medal"
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  flexShrink: "0",
                                  top: "-10px",
                                  position: "relative",
                                }}
                              />
                            </div>
                          </ListGroup.Item>
                        );
                      })
                    ) : (
                      <div>No skills available for this topic</div>
                    )
                  ) : (
                    <div className="loading-dots">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <div className="p-3">No topics available for the selected domain</div>
      )}
      {/* modal for show the skill with the video */}
      {selectedSkill && (
        <SkillPracticeVideoModal
          skillTitle={selectedSkillTitle}
          skillVideo={selectedSkillVideo}
          onClose={() => {
            handleSkillSelect(selectedSkill);
            handleCloseModal();
          }}
        />
      )}
    </Container>
  );
};

export default LibraryTopics;
