import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const startPlacementTestAPI = async (
  assignmentId,
  programName,
  token,
  timeSpent
) => {
  try {
    var url;
    const body = timeSpent
      ? JSON.stringify({ assignmentId, isPause: true, timeSpent })
      : JSON.stringify({ assignmentId });
    // if (programName === 'ela') {
    //   url = `${BASE_URL}/sd/v1/startPlacementTest/ela`
    // }
    // else if (programName === 'math') {
    url = `${BASE_URL}/sd/v2/startPlacementTest/${programName}`;
    // url = "http://192.168.0.209:4000/startSkillTest"
    // }
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    const result = await response.json();

    if (result.statusCode === 500) {
      toast.error("Something went wrong, Please try again after some time.");

      return { message: "Failure", status: 500 };
    } else if (result.message === "Success") {
      return result;
    }
    const errorResponse = result;
    throw new Error(
      errorResponse.message ||
        `Network response was not ok: ${response.statusText}`
    );
  } catch (error) {
    throw new Error(`Failed to start test: ${error.message}`);
  }
};

export const placementTestNextQuestionAPI = async (
  questionId,
  assignmentId,
  submittedAnswer,
  isSkipped,
  timeSpentPerQuestion,
  programName,
  token,
  isCompleted
) => {
  var timeSpent = timeSpentPerQuestion;
  try {
    var url;
    url = `${BASE_URL}/sd/v2/placementTestNextQuestion/${programName}`;
    const data = isCompleted === 1 ? JSON.stringify({
      questionId,
      assignmentId,
      submittedAnswer,
      isSkipped,
      timeSpent,
      isCompleted
    }) : JSON.stringify({
      questionId,
      assignmentId,
      submittedAnswer,
      isSkipped,
      timeSpent,
    })

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
    const result = await response.json();
    console.log("result", result);
    if (
      result.statusCode === 500 ||
      result.message === "No question found to resume the test"
    ) {
      toast.error("Something went wrong, Please try again after some time.");
      return { message: "Failure", status: 500 };
    }
    if (result.message === "Success") {
      // if (result.data.isCompleted === 1) {
      //   return { message: "Test Completed" }
      // }
      return result;
    }
    const errorResponse = result;
    throw new Error(
      errorResponse.message ||
        `Network response was not ok: ${response.statusText}`
    );
  } catch (error) {
    throw new Error(`Failed to get next question: ${error.message}`);
  }
};
