import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import questionTypeConfig from "../questionTypeConfig";
import { parseLatex, parseLatexText } from "../../../Utils/LatexUtils";
import Speaker from "../../../Assets/Images/speaker.svg";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";

//  Suppress ResizeObserver errors
const handleError = (event) => {
  if (
    event.message ===
    "ResizeObserver loop completed with undelivered notifications."
  ) {
    event.stopImmediatePropagation();
  }
};
window.addEventListener("error", handleError);

const ReadingOptionComponent = ({
  setSelectedOptions,
  speakingIndex,
  handleSpeak
}) => {
  // state
  const [selectedOptions, updateSelectedOptions] = useState([]);
  const [speechSynthesis, setSpeechSynthesis] = useState(
    () => window.speechSynthesis
  );
  // redux selector
  const [imageClass, setImageClass] = useState("default");
  const { questionType, options } = useSelector(
    (state) => state.testViewReducer
  );

  //  toggle option selection
  const toggleOptionSelection = useCallback(
    (optionId) => {
      updateSelectedOptions((prevSelectedOptions) => {
        let newSelectedOptions;
        if (questionTypeConfig.isMultiSelect.includes(questionType)) {
          if (prevSelectedOptions.includes(optionId)) {
            newSelectedOptions = prevSelectedOptions.filter(
              (selectedOptionId) => selectedOptionId !== optionId
            );
          } else {
            newSelectedOptions = [...prevSelectedOptions, optionId];
          }
        } else {
          newSelectedOptions = prevSelectedOptions.includes(optionId)
            ? []
            : [optionId];
          if (prevSelectedOptions.includes(optionId)) {
            newSelectedOptions = [];
          } else {
            newSelectedOptions = [optionId];
          }
        }
        setSelectedOptions(newSelectedOptions);
        return newSelectedOptions;
      });
    },
    [questionType, setSelectedOptions]
  );

  //   image handler
  const handleImageLoad = useCallback((event) => {
    const img = event.target;
    if (img.naturalWidth === 400 && img.naturalHeight === 400) {
      setImageClass("custom");
    } else {
      setImageClass("default");
    }
  }, []);

  //   capitalize first for true false
  const capitalizeFirstForTrueFalse = useCallback((text) => {
    if (text) {
      const trimmedText = text.trim();
      const lowerText = trimmedText.toLowerCase();
      if (lowerText === "true" || lowerText === "false") {
        return lowerText.toUpperCase();
      }
    }
    return text;
  }, []);

  //  render question options
  const renderOption = useCallback(
    (option, index) => {
      return (
        <div
          key={option._id}
          className={`text-option-row  ${
            selectedOptions.includes(option._id) ? "selected" : ""
          }`}
          onClick={() => toggleOptionSelection(option._id)}
        >
          <div
            style={{marginTop:'-10px'}}
            className="speaker-icon "
            onClick={(e) => {
              e.stopPropagation();
              handleSpeak(option.text, index);
            }}
            

          >
            <img
              src={speakingIndex === index ? activeSpeaker : Speaker}
              alt="Speaker"
              className="mt-4 ms-3"
              // style={{ marginLeft: "2rem" }}
            />
          </div>
          <div className="option-text-wrapper d-flex ms-0 ">
            {questionTypeConfig.isMultiSelect.includes(questionType) ? (
              <div
                className={`option-text ${
                  selectedOptions.includes(option._id) ? "selected" : ""
                }`}
              >
                <div className="input-wrapper me-auto">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option._id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptionSelection(option._id);
                    }}
                    onChange={() => {}}
                    className="option-checkbox"
                  />
                </div>
                <div className="optionText-box-container text-center fw-auto">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parseLatex(
                        capitalizeFirstForTrueFalse(option.text)
                      ),
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptionSelection(option._id);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                className={`option-text ${
                  selectedOptions.includes(option._id) ? "selected" : ""
                }`}
                onClick={(e) => toggleOptionSelection(e, option._id)}
              >
                <div className="input-wrapper me-auto ">
                  <input
                    type="radio"
                    className="option-radio"
                    name="single-select"
                    checked={selectedOptions.includes(option._id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptionSelection(option._id);
                    }}
                  />
                </div>

                <div className="optionText-box-container text-center fw-normal">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parseLatex(
                        capitalizeFirstForTrueFalse(option.text)
                      ),
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleOptionSelection(option._id);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    [selectedOptions, toggleOptionSelection, handleSpeak, questionType]
  );

  const renderOptions = useMemo(() => {
    if (!options || !Array.isArray(options)) return [];
    return options
      .filter((option) => option.text && option.text.length > 0)
      .map((option, index) => renderOption(option, index));
  }, [options, renderOption]);

  //   render image option
  const renderImageOption = useCallback(
    (option) => {
      return (
        <div
          className={`option-image ${
            selectedOptions.includes(option._id) ? "selected" : ""
          }`}
          key={option._id}
        >
          <img
            src={option.image}
            alt={`Img ${option._id}`}
            className={selectedOptions.includes(option._id) ? "selected" : ""}
            onClick={() => toggleOptionSelection(option._id)}
            onLoad={handleImageLoad}
          />
          {questionTypeConfig.isMultiSelect.includes(questionType) ? (
            <input
              type="checkbox"
              checked={selectedOptions.includes(option._id)}
              onChange={() => toggleOptionSelection(option._id)}
              className="d-flex flex-row align-items-center justify-content-center mb-5"
            />
          ) : (
            <input
              type="radio"
              name="single-select"
              checked={selectedOptions.includes(option._id)}
              onClick={(e) => {
                e.stopPropagation();
                toggleOptionSelection(option._id);
              }}
              className="mb-4"
            />
          )}
        </div>
      );
    },
    [selectedOptions, toggleOptionSelection, handleImageLoad, questionType]
  );

  const renderImageOptions = useMemo(() => {
    return options?.map((option) => renderImageOption(option));
  }, [options, renderImageOption]);

  //   render text and image option
  const renderTextAndImageOption = useCallback(
    (option, index) => {
      const isSpeaking = speakingIndex === index;
      return (
        <div key={option._id}>
          <div
            className={`option-and-image ${
              selectedOptions.includes(option._id) ? "selected" : ""
            }`}
            onClick={() => toggleOptionSelection(option._id)}
          >
            <img
              src={option.image}
              alt={`Img ${option._id}`}
              onLoad={handleImageLoad}
            />
            <div className="speaker-and-text">
              <div className="optionText-box-container text-center fw-normal">
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseLatex(
                      capitalizeFirstForTrueFalse(option.text)
                    ),
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOptionSelection(option._id);
                  }}
                />
              </div>
            </div>
            {questionTypeConfig.isMultiSelect.includes(questionType) ? (
              <input
                type="checkbox"
                checked={selectedOptions.includes(option._id)}
                onClick={(e) => e.stopPropagation()}
                // style={{ marginBottom: "1.25rem" }}
                className="mb-5"
              />
            ) : (
              <input
                type="radio"
                name="single-select"
                checked={selectedOptions.includes(option._id)}
                onClick={(e) => e.stopPropagation()}
                className="mb-4"
              />
            )}
          </div>
          <div className="text-and-image ms-n3">
            <img
              src={isSpeaking ? activeSpeaker : Speaker}
              alt="Speaker"
              className="mt-4"
              onClick={(e) => {
                e.stopPropagation();
                handleSpeak(option.text, index);
              }}
            />
          </div>
        </div>
      );
    },
    [
      selectedOptions,
      toggleOptionSelection,
      handleImageLoad,
      handleSpeak,
      speakingIndex,
    ]
  );

  const renderTextAndImageOptions = useMemo(() => {
    return options?.map((option, index) =>
      renderTextAndImageOption(option, index)
    );
  }, [options, renderTextAndImageOption]);

  return (
    <div>
      {questionTypeConfig.textTypeOption.includes(questionType) && (
        <div className="text-options-container m-5 mx-4 d-flex flex-column gap-4">
          {renderOptions}
        </div>
      )}

      {questionTypeConfig.imageTypeOption.includes(questionType) && (
        <div className={`image-container ${imageClass}`}>
          {renderImageOptions}
        </div>
      )}
      {questionTypeConfig.textAndImageTypeOption.includes(questionType) && (
        <div className={`image-container ${imageClass}`}>
          {renderTextAndImageOptions}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // Map state to props if needed
});

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props if needed
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadingOptionComponent);
