import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllLevelsByProgramId } from "../../../Utils/ApiHandler/AssignTestApi";
import {
  SpellingPracticeStartApi,
  SpellingPracticeSubmitApi,
  SpellingPracticeSkipApi,
  spellingPracticeLevelApi,
  SpellingPracticeStartSkippedQuestionApi,
  SpellingPracticeStartIncorrectQuestionApi,
} from "../../../Utils/ApiHandler/SpellingApi";

// Async thunk for fetching spelling practice level
export const fetchSpellingPracticeLevel = createAsyncThunk(
  "spellingPractice/fetchSpellingPracticeLevel",
  async ({ programId, workEmail, levelId, authToken }) => {
    const response = await spellingPracticeLevelApi(
      programId,
      workEmail,
      levelId,
      authToken
    );
    return response;
  }
);

// Async thunk for fetching skill levels by program ID
export const fetchSkillLevels = createAsyncThunk(
  "spellingPractice/fetchSkillLevels",
  async ({ programId, authToken }) => {
    const response = await getAllLevelsByProgramId(programId, authToken);
    return response.data.levels.map((level) => ({
      value: level._id,
      label: level.title,
      order: level.order,
    }));
  }
);

// Async thunk for starting spelling practice
export const spellingPracticeStart = createAsyncThunk(
  "spellingPractice/start",
  async ({ workEmail, programId, levelId, levelOrder, authToken }) => {
    const response = await SpellingPracticeStartApi(
      workEmail,
      programId,
      levelId,
      levelOrder,
      authToken
    );

    return response;
  }
);

export const spellingPracticeStartSkippedQuestions = createAsyncThunk(
  "spellingPractice/startSkippedQuestions",
  async ({ workEmail, programId, levelId, levelOrder, authToken }) => {
    const response = await SpellingPracticeStartSkippedQuestionApi(
      workEmail,
      programId,
      levelId,
      levelOrder,
      authToken
    );

    return response;
  }
);

export const spellingPracticeStartIncorrectQuestions = createAsyncThunk(
  "spellingPractice/startIncorrectQuestion",
  async ({ workEmail, programId, levelId, levelOrder, authToken }) => {
    const response = await SpellingPracticeStartIncorrectQuestionApi(
      workEmail,
      programId,
      levelId,
      levelOrder,
      authToken
    );

    return response;
  }
);

// Async thunk for submitting spelling practice
export const spellingPracticeSubmit = createAsyncThunk(
  "spellingPractice/submit",
  async ({
    workEmail,
    programId,
    levelId,
    levelOrder,
    questionId,
    answer,
    timeSpent,
    skippedPracticeSession,
    incorrectPracticeSession,
    authToken,
  }) => {
    const response = await SpellingPracticeSubmitApi(
      workEmail,
      programId,
      levelId,
      levelOrder,
      questionId,
      answer,
      timeSpent,
      skippedPracticeSession,
      incorrectPracticeSession,
      authToken
    );
    return response;
  }
);

// Async thunk for skipping spelling practice
export const spellingPracticeSkip = createAsyncThunk(
  "spellingPractice/skip",
  async ({
    workEmail,
    programId,
    levelId,
    questionId,
    levelOrder,
    timeSpent,
    skippedPracticeSession,
    incorrectPracticeSession,
    authToken,
  }) => {
    const response = await SpellingPracticeSkipApi(
      workEmail,
      programId,
      levelId,
      questionId,
      levelOrder,
      timeSpent,
      skippedPracticeSession,
      incorrectPracticeSession,
      authToken
    );
    return response;
  }
);


// State
const initialState = {
  isLevelsLoading: false,
  isPracticeLoading: false,
  isSkipPracticeLoading: false,
  isIncorrectPracticeLoading: false,
  isSubmitLoading: false,
  isSkipLoading: false,
  isPracticeLevelsLoading: false,
  spellingPracticeLevel: [],
  error: null,
  success: false,
  submitSuccess: false,
  skipSuccess: false,
  skillLevels: [],
  spellingQuestion: null,
  questionId: null,
  programId: null,
  spellingAccuracyRate: 0,
  questionsAnswered: 0,
  questionsSkipped: 0,
  incorrectQuestions: 0,
  timeSpent: 0,
  isTimerRunning: false,
  spellingQuestionNo: 0,
  isStarted: false,
  isCompleted: false,
  isSkippedPracticeSessionCompleted: false,
  isIncorrectPracticeSessionCompleted: false,
  attemptedSpellingQuestion: null,
  attemptedCorrectAnswer: null,
  attemptedSubmittedAnswer: null,
  isSpellingCorrect: null,
  spellingSubmitSuccess: false,
  spellingLevelId: null,
  spellingLevelOrder: null,
  spellingPracticeSuccess: false,
  attemptedTimeSpent: 0,
  isSidebarActive: false,
};

// slice for handling all spelling practice actions
const spellingSlice = createSlice({
  name: "spellingPractice",
  initialState,
  reducers: {
    startTimer(state) {
      state.isTimerRunning = true;
    },
    stopTimer(state) {
      state.isTimerRunning = false;
    },
    updateTimeSpent(state, action) {
      state.timeSpent = action.payload;
    },
    resetTimeSpent(state) {
      state.timeSpent = 0;
    },
    nextSpellingQuestion: (state, action) => {
      state.spellingSubmitSuccess = false;
    },
    setSidebarActive: (state, action) => {
      state.isSidebarActive = action.payload;
    },
    resetPracticeCompletionStates: (state) => {
      state.isCompleted = false;
      state.isSkippedPracticeSessionCompleted = false;
      state.isIncorrectPracticeSessionCompleted = false;
    },
  },
  extraReducers: (builder) => {
    //   For fetching spelling practice level
    builder
      .addCase(fetchSpellingPracticeLevel.pending, (state) => {
        state.isPracticeLevelsLoading = true;
      })
      .addCase(fetchSpellingPracticeLevel.fulfilled, (state, action) => {
        state.isPracticeLevelsLoading = false;
        if (action?.payload?.data?.levels?.length > 0) {
          state.spellingPracticeLevel = action?.payload?.data?.levels || [];
          state.programId = action?.payload?.data?.levels[0]?.programId;
          state.spellingLevelId = action?.payload?.data?.levels[0]?.levelId;
          state.spellingLevelOrder =
            action?.payload?.data?.levels[0]?.levelOrder;
          state.questionsAnswered =
            action?.payload?.data?.levels[0]?.correct +
            action?.payload?.data?.levels[0]?.wrong;
          state.questionsSkipped = action?.payload?.data?.levels[0]?.skipped;
          state.incorrectQuestions = action?.payload?.data?.levels[0]?.wrong;
          state.isCompleted = action?.payload?.data?.practiceSession?.isCompleted;
          if (state.isSidebarActive) {
            const savedTime = localStorage.getItem("currentTimeSpent");
            state.timeSpent = parseInt(savedTime, 10);
          } else {
            state.timeSpent = action.payload.data.levels[0].totalTimeSpent;
          }
          state.spellingAccuracyRate =
            action?.payload?.data?.levels[0]?.accuracy;
        } else {
          state.spellingPracticeLevel = [];
          state.programId = null;
          state.spellingLevelId = null;
          state.spellingLevelOrder = null;
          state.questionsAnswered = 0;
          state.questionsSkipped = 0;
          state.incorrectQuestions = 0;
          state.timeSpent = 0;
          state.spellingAccuracyRate = 0;
        }
      })

      .addCase(fetchSpellingPracticeLevel.rejected, (state, action) => {
        state.isPracticeLevelsLoading = false;
        state.error = action.error.message;
      });

    // For fetching skill levels
    builder
      .addCase(fetchSkillLevels.pending, (state) => {
        state.isLoadingLevels = true;
      })
      .addCase(fetchSkillLevels.fulfilled, (state, action) => {
        state.isLoadingLevels = false;
        state.skillLevels = action.payload;
        state.spellingLevelId = action.payload[0].levelId;
        state.spellingLevelOrder = action.payload[0].levelOrder;
      })
      .addCase(fetchSkillLevels.rejected, (state, action) => {
        state.isLoadingLevels = false;
        state.error = action.error.message;
      });

    // For spellingPracticeStart
    builder
      .addCase(spellingPracticeStart.pending, (state) => {
        state.isPracticeLoading = true;
      })
      .addCase(spellingPracticeStart.fulfilled, (state, action) => {
        state.isPracticeLoading = false;
        state.success = true;
        state.spellingPracticeSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.spellingQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.questionsAnswered =
          action?.payload?.data?.practiceSession?.correct +
          action?.payload?.data?.practiceSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.practiceSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.practiceSession?.wrong;
        state.spellingQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.totalTimeSpent = action?.payload?.data?.practiceSession?.totalTimeSpent;
        state.skippedPracticeSession = action?.payload?.data?.skippedPracticeSession;
        state.incorrectPracticeSession = action?.payload?.data?.incorrectPracticeSession;
        state.isCompleted = action?.payload?.data?.practiceSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isIncorrectPracticeSessionCompleted;
        const previousQuestionId = localStorage.getItem("previousQuestionId");
        const currentQuestionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;

        if (previousQuestionId !== currentQuestionId) {
          state.timeSpent = 0;
          localStorage.setItem("currentTimeSpent", 0);
        } else {
          state.timeSpent =
            parseInt(localStorage.getItem("currentTimeSpent"), 10) || 0;
        }

        localStorage.setItem("previousQuestionId", currentQuestionId);
      })
      .addCase(spellingPracticeStart.rejected, (state, action) => {
        state.isPracticeLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(spellingPracticeStartSkippedQuestions.pending, (state) => {
        state.isSkipPracticeLoading = true;
      })
      .addCase(spellingPracticeStartSkippedQuestions.fulfilled, (state, action) => {
        state.isSkipPracticeLoading = false;
        state.success = true;
        state.spellingPracticeSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.spellingQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.questionsAnswered =
          action?.payload?.data?.practiceSession?.correct +
          action?.payload?.data?.practiceSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.practiceSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.practiceSession?.wrong;
        state.spellingQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.totalTimeSpent = action?.payload?.data?.practiceSession?.totalTimeSpent;
        state.skippedPracticeSession = action?.payload?.data?.skippedPracticeSession;
        state.incorrectPracticeSession = action?.payload?.data?.incorrectPracticeSession;
        state.isCompleted = action?.payload?.data?.practiceSession?.isCompleted;
        state.levelId = action?.payload?.data?.practiceSession?.levelId;
        state.isCompleted = action?.payload?.data?.practiceSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isIncorrectPracticeSessionCompleted;
        const previousQuestionId = localStorage.getItem("previousQuestionId");
        const currentQuestionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;

        if (previousQuestionId !== currentQuestionId) {
          state.timeSpent = 0;
          localStorage.setItem("currentTimeSpent", 0);
        } else {
          state.timeSpent =
            parseInt(localStorage.getItem("currentTimeSpent"), 10) || 0;
        }

        localStorage.setItem("previousQuestionId", currentQuestionId);
      })
      .addCase(spellingPracticeStartSkippedQuestions.rejected, (state, action) => {
        state.isSkipPracticeLoading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(spellingPracticeStartIncorrectQuestions.pending, (state) => {
        state.isIncorrectPracticeLoading = true;
      })
      .addCase(spellingPracticeStartIncorrectQuestions.fulfilled, (state, action) => {
        state.isIncorrectPracticeLoading = false;
        state.success = true;
        state.spellingPracticeSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.spellingQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.questionsAnswered =
          action?.payload?.data?.practiceSession?.correct +
          action?.payload?.data?.practiceSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.practiceSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.practiceSession?.wrong;
        state.spellingQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.totalTimeSpent = action?.payload?.data?.practiceSession?.totalTimeSpent;
        state.skippedPracticeSession = action?.payload?.data?.skippedPracticeSession;
        state.incorrectPracticeSession = action?.payload?.data?.incorrectPracticeSession;
        state.isCompleted = action?.payload?.data?.practiceSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isIncorrectPracticeSessionCompleted;
        const previousQuestionId = localStorage.getItem("previousQuestionId");
        const currentQuestionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;

        if (previousQuestionId !== currentQuestionId) {
          state.timeSpent = 0;
          localStorage.setItem("currentTimeSpent", 0);
        } else {
          state.timeSpent =
            parseInt(localStorage.getItem("currentTimeSpent"), 10) || 0;
        }

        localStorage.setItem("previousQuestionId", currentQuestionId);
      })
      .addCase(spellingPracticeStartIncorrectQuestions.rejected, (state, action) => {
        state.isIncorrectPracticeLoading = false;
        state.error = action.error.message;
      });
    // For spellingPracticeSubmit
    builder
      .addCase(spellingPracticeSubmit.pending, (state) => {
        state.isSubmitLoading = true;
      })
      .addCase(spellingPracticeSubmit.fulfilled, (state, action) => {
        state.isSubmitLoading = false;
        state.submitSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.questionsAnswered =
          action?.payload?.data?.practiceSession?.correct;
        state.questionsSkipped =
          action?.payload?.data?.practiceSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.practiceSession?.wrong;
        state.spellingQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.attemptedSpellingQuestion =
          action?.payload?.data?.attemptedQuestionDetails?.question?.text;
        state.attemptedSubmittedAnswer =
          action?.payload?.data?.attemptedQuestionDetails?.submittedAnswer[0];
        state.attemptedCorrectAnswer =
          action?.payload?.data?.attemptedQuestionDetails?.correctAnswer[0];
        state.isSpellingCorrect =
          action?.payload?.data?.attemptedQuestionDetails?.isCorrect;
        state.attemptedTimeSpent =
          action?.payload?.data?.attemptedQuestionDetails?.timeSpent;
        state.spellingSubmitSuccess = action.payload.success;
        state.questionsAnswered =
          action?.payload?.data?.practiceSession?.correct +
          action?.payload?.data?.practiceSession?.wrong;
        state.questionsSkipped =
          action?.payload?.data?.practiceSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.practiceSession?.wrong;
        state.isCompleted = action?.payload?.data?.practiceSession?.isCompleted;
        state.isSkippedPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isSkippedPracticeSessionCompleted;
        state.isIncorrectPracticeSessionCompleted = action?.payload?.data?.practiceSession?.isIncorrectPracticeSessionCompleted;
        const currentTimeSpent = state.timeSpent;
        state.timeSpent += currentTimeSpent;
        localStorage.setItem("currentTimeSpent", 0);
        state.timeSpent = 0;
        state.spellingQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.spellingAccuracyRate =
          action?.payload?.data?.practiceSession?.accuracy;
      })
      .addCase(spellingPracticeSubmit.rejected, (state, action) => {
        state.isSubmitLoading = false;
        state.error = action.error.message;
      });

    // For spellingPracticeSkip
    builder
      .addCase(spellingPracticeSkip.pending, (state) => {
        state.isSkipLoading = true;
      })
      .addCase(spellingPracticeSkip.fulfilled, (state, action) => {
        state.isSkipLoading = false;
        state.skipSuccess = true;
        state.questionId =
          action?.payload?.data?.nextQuestionDetails?.questionId;
        state.questionsSkipped =
          action?.payload?.data?.practiceSession?.skipped;
        state.incorrectQuestions =
          action?.payload?.data?.practiceSession?.wrong;
        state.totalTimeSpent =
          action?.payload?.data?.practiceSession?.totalTimeSpent;
        state.spellingQuestion =
          action?.payload?.data?.nextQuestionDetails?.question?.text;
        state.spellingQuestionNo =
          action?.payload?.data?.nextQuestionDetails?.questionNo;
        state.spellingAccuracyRate =
          action?.payload?.data?.practiceSession?.accuracy;
      })
      .addCase(spellingPracticeSkip.rejected, (state, action) => {
        state.isSkipLoading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  nextSpellingQuestion,
  startTimer,
  stopTimer,
  updateTimeSpent,
  isLoadingLevels,
  resetTimeSpent,
  setSidebarActive,
  skillLevels,
  resetPracticeCompletionStates
} = spellingSlice.actions;
export default spellingSlice.reducer;
