export const emitEvent = async (socket, eventType, eventData = {}) => {
  // Validate socket connection
  if (!socket?.connected) {
    console.error("Socket is not initialized or connected");
    return;
  }

  // Validate event type
  if (!eventType) {
    console.error("Event type is required");
    return;
  }

  // console.log(eventType, eventData);

  // Emit the event with a 20-second timeout
  try {
    await socket.timeout(20000).emit(eventType, eventData);
  } catch (err) {
    console.error(`Error emitting ${eventType}:`, err);
  }
};
