import React from "react";
import "./Loader.css";
import { BallTriangle } from "react-loader-spinner";
const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="spinner-auth">
        <BallTriangle
          height={60}
          width={60}
          radius={5}
          color="#F2762E"
          ariaLabel="ball-triangle-loading"
          visible={true}
        />
      </div>
    </div>
  );
};

export default Loader;
