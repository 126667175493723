import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuthenticate } from "../../../hooks/useAuthenticate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./WritingTestViewer.css";
import WritingTimer from "./WritingTimer";

import DOMPurify from "dompurify";
import toast from "react-hot-toast";

import Loader from "../../../Components/Loader/Loader";
import TestFinishCard from "../TestFinishCard";

// import { formats, modules } from "../../../Utils/reactQuillUtils";
import { useQuillConfig } from "../../../hooks/useQuillConfig";
import {
  startWritingTestThunk,
  saveWritingTestThunk,
  cleanWritingData,
  settingLoad,
} from "../../../redux/slices/writing/writingTestSlice";
import activeSpeaker from "../../../Assets/Images/active-speaker.svg";
import Speaker from "../../../Assets/Images/speaker.svg";
import { parseLatexText } from "../../../Utils/LatexUtils";
import WritingInstructionCard from "../../../Components/WarningMessage/WritingInstructionCard";
import WarningMessage from "../../../Components/WarningMessage/WarningMessage";

const WritingTestViewer = () => {
  const dispatch = useDispatch();
 

  const quillRef = useRef(null);
  const editorContentRef = useRef("");
  const { formats, modules } = useQuillConfig();

  const autoIntervalRef = useRef(null);

console.log(autoIntervalRef)
  
  const setAutoInterval = () => {
    if(autoIntervalRef.current){
      clearInterval(autoIntervalRef.current)
    }
    autoIntervalRef.current = setInterval(() => {
      handleSaveClick("save");
    }, 3*60*1000)
  }
  useEffect(() => {
    setAutoInterval();
    return () => {
      clearInterval(autoIntervalRef.current)
    }
  }, [])



  
  const {
    data: writingData,
    loading: writingLoading,
    error: writingError,
  } = useSelector((state) => state.writing);
  if(writingData?.questionId){
    console.log(writingData?.questionId)
    localStorage.setItem("writingQuestionId",writingData?.questionId)
  }

  console.log(writingData)

  const { sdaccessToken: token } = useSelector((state) => state.details);

  const queryParams = new URLSearchParams(window.location.search);
  const assignmentDetails = JSON.parse(
    decodeURIComponent(queryParams.get("assignmentDetails"))
  );
  const assignmentId = assignmentDetails?._id;

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [speakingIndex, setSpeakingIndex] = useState(null);
  const speechSynthesisInstance = window.speechSynthesis;
  const [isInstructionModelOpen, setInstructionModelOpen] = useState(
    localStorage.getItem("writingInstruction") === "true"
  );
  const [testConfirmation, setTestConfirmation] = useState(false);
  const [timeSpent, setTimeSpent] = useState(writingData?.timeSpent);
  
  console.log(timeSpent, "Backend saevd time or local storaqge time")
  const [isRunning, setIsRunning] = useState(localStorage.getItem("writingInstruction") !== "true");

  const { loading: authLoading, isAuthenticated } = useAuthenticate();
  console.log(isInstructionModelOpen);

  useEffect(() => {
    return () => {
      dispatch(cleanWritingData());
    };
  }, [dispatch]);


  useEffect(() => {
    // Cleanup function that will run on unmount and when dependencies change
    const cleanup = async () => {
      console.log("sending component unmount timeSpent", timeSpent, quillRef?.current);
      const writingTestStatus = parseInt(localStorage.getItem("writingTestStatus"));
      console.log(writingTestStatus)
      
      // if (quillRef.current) {
        const sanitizedContent = DOMPurify.sanitize(editorContentRef.current);
  
        if (token && assignmentId && sanitizedContent && writingTestStatus !==5) {
          try {
            // Await the dispatch call before continuing
            await dispatch(
              saveWritingTestThunk({
                token,
                assignmentId,
                questionId: writingData?.questionId || localStorage.getItem("writingQuestionId"),
                timeSpent: parseInt(localStorage.getItem("writingTimer")) ,
                answer: [sanitizedContent],
                status: 4,
              })
            );
            console.log("Action dispatched successfully.");
          } catch (error) {
            console.error("Error dispatching saveWritingTestThunk", error);
          }
        }
      // }
  
      // After dispatch is completed, clear the ref
      quillRef.current = null;
      
      // Cleanup timer from localStorage
      localStorage.removeItem("writingTimer");
      localStorage.removeItem("writingQuestionId");
      localStorage.removeItem("writingInstruction");
      localStorage.removeItem("writingTestStatus");
    };

    
  
    // This cleanup will run every time the dependencies change (or on unmount)
    return () => {
      cleanup();  // Execute cleanup when the component unmounts or dependencies change
    };
  }, []);  // dependencies array
  






  useEffect(() => {
    if (token && assignmentId) {
      dispatch(startWritingTestThunk({ token, assignmentId }));
    }
  }, [dispatch, token, assignmentId]);
  useEffect(() => {
    if (writingData) {
      editorContentRef.current = typeof writingData?.answer === "string" ? writingData?.answer : writingData?.answer?.[0] || "";
      setIsSubmitEnabled(isContentValid(editorContentRef.current));
    }
  }, [writingData]);

  useEffect(() => {
    if (quillRef.current && writingData) {
      const sanitizedAnswer =  typeof writingData?.answer === "string" ? writingData?.answer : writingData?.answer?.[0] || "";
      editorContentRef.current = sanitizedAnswer;

      const delta = quillRef.current
        .getEditor()
        .clipboard.convert(sanitizedAnswer);
      quillRef.current.getEditor().setContents(delta);
    }
  }, [writingData]);

  const handleSpeak = (text, index) => {
    if ("speechSynthesis" in window) {
      const replacedText = text
        .replace(/<#@blank#>/g, "blank")
        .replace(/_{2,}/g, "blank");

      const utterance = new SpeechSynthesisUtterance(
        parseLatexText(replacedText)
      );
      if (speechSynthesisInstance.speaking || speechSynthesisInstance.pending) {
        if (speakingIndex === index) {
          speechSynthesisInstance.cancel();
          setSpeakingIndex(null);
          return;
        } else {
          speechSynthesisInstance.cancel();
        }
      }
      setSpeakingIndex(index);
      speechSynthesisInstance.speak(utterance);
      utterance.onend = () => setSpeakingIndex(null);
    }
  };

  const handleOpenQuestionInstructions = () => {
    localStorage.setItem("writingInstruction", true);
    setIsRunning(false)
    setInstructionModelOpen(true);
  };

  const isContentValid = (content) => {
    return content?.length > 0 && content !== "<p><br></p>";
  };

  const handleEditorChange = () => {
    if (quillRef.current) {
      editorContentRef.current = quillRef?.current
        .getEditor()
        .root.innerHTML.trim();

      setIsSubmitEnabled(isContentValid(editorContentRef?.current));
    }
  };

  const handleSaveButtonClick = (state) => {
    handleSaveClick(state);
    setAutoInterval()
  };

  const handleConfirmationClick = (confirm) => {
    if (confirm) {
      setTestConfirmation(false);
      setIsRunning(true)
    } else {
      handleSaveClick("submit");
      setTestConfirmation(false);
      setIsRunning(false)
    }
  };

  const handleTimeUpdate = (updatedTime) => {
    setTimeSpent(updatedTime)
  }
  console.log("Time Spent", timeSpent)

  console.log("quillRef current value",quillRef.current)

  const handleSaveClick = (state) => {
    if (quillRef.current) {
      const sanitizedContent = DOMPurify.sanitize(editorContentRef.current);
      if (state === "save" && token && assignmentId && sanitizedContent) {
        dispatch(
          saveWritingTestThunk({
            token,
            assignmentId,
            questionId: writingData?.questionId || localStorage.getItem("writingQuestionId"),
            timeSpent: parseInt(localStorage.getItem("writingTimer")) ,
            answer: [sanitizedContent],
            status: 4,
          })
        );
        toast.success(
          "Your answers have been saved. Please remember that saving does not submit your test."
        );
      } else if (
        state === "submit" &&
        token &&
        assignmentId &&
        sanitizedContent
      ) {
        dispatch(settingLoad());
        dispatch(
          saveWritingTestThunk({
            token,
            assignmentId,
            questionId: writingData?.questionId,
            timeSpent,
            answer: [sanitizedContent],
            status: 5,
          })
        );
      }
    }
  };

  if (writingError) {
    return (
      <div className="auth-err-msg">
        Something went wrong, Please try again later
      </div>
    );
  }

  if (authLoading || writingLoading) {
    return <Loader />;
  }
  if (!isAuthenticated) {
    return <div className="auth-err-msg">You are not authenticated.</div>;
  }
  if (!assignmentId) {
    return <div className="auth-err-msg">Assignment does not exist.</div>;
  }

  return (
    <>
      <div className="writing-test-container">
        {writingData?.status === 5 ? (
          <div
            style={{ position: "absolute" }}
            className="d-flex justify-content-center align-items-center"
          >
            <TestFinishCard
              programName="writing"
              completionMessage="Your assignment has been successfully submitted for review."
            />
          </div>
        ) : (
          <>
            <div className="writing-test-body-container">
              <div className="skill-instruction-container">
                <span>Skill: {writingData?.skillTitle}</span>
                <span>

                <WritingTimer
                          startFrom={writingData?.timeSpent}
                          isRunning={isRunning}
                          onTimeUpdate={handleTimeUpdate}
                        />
                </span>
                <span
                  className="instructions-label resource-tooltip"
                  onClick={handleOpenQuestionInstructions}
                
                  data-tooltip={
                    !writingData?.resource
                      ? "Resources are not available"
                      : undefined
                  }
                >
                  <div >Resources</div>
                </span>
              </div>
              <div className="question-header-container">
                <img
                  src={
                    speakingIndex === "textExplanationQuestion"
                      ? activeSpeaker
                      : Speaker
                  }
                  alt="Speaker"
                  onClick={() =>
                    handleSpeak(
                      writingData?.question,
                      "textExplanationQuestion"
                    )
                  }
                />
                <div className="question-text-container">
                  {writingData?.question}
                  
                </div>
              </div>

              <div className="writing-answer-container">
                <ReactQuill
                  ref={quillRef}
                  value={editorContentRef.current || ""}
                  modules={modules}
                  formats={formats}
                  placeholder="Write your answer here..."
                  onChange={handleEditorChange}
                />
              </div>
              <div className="d-flex gap-3 p-2 m-2">
                <button
                  type="button"
                  className="writing-save-btn"
                  disabled={!isSubmitEnabled}
                  onClick={() => handleSaveButtonClick("save")}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="writing-sub-btn"
                  disabled={!isSubmitEnabled}
                  onClick={() => { setIsRunning(false); setTestConfirmation(true); }}
                >
                  Submit
                </button>
              </div>
            </div>
            {isInstructionModelOpen && writingData?.resource && (
              <WritingInstructionCard 
                resource={writingData?.resource}
                close={() => {
                  setInstructionModelOpen(false);
                  setIsRunning(true);
                  localStorage.setItem("writingInstruction", false);
                }}
              />
            )}
            {testConfirmation && (
              <WarningMessage
                showButton1={true}
                showButton2={true}
                message={
                  "Are you sure you want to submit your test? Once submitted, you cannot make any changes."
                }
                handleConfirmationClick={handleConfirmationClick}
                isAssign={true}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default WritingTestViewer;
