import React from "react";
import "./Header.css";
const HeaderItem = ({ label, page, isHighlighted, onClick }) =>{ 
  
  return (
  <div
    className={`${page} ${isHighlighted ? "highlightContent" : ""}`}
    onClick={() => onClick(page)}
  >
    <div className={`navListfontCustom fw-bold ${!isHighlighted ? "navList" : ""}`}>
      {label}
    </div>
  </div>
)
};
export default HeaderItem;
