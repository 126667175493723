import {
  startBenchmarkTestAPI,
  benchmarkTestNextQuestionAPI,
  startRCBenchmarkTestAPI,
  startRCBenchmarkTestNextQuestionAPI,
} from "../../Utils/ApiHandler/BenchmarkTestApi";
import {
  START_BENCHMARK_TEST_SUCCESS,
  START_BENCHMARK_TEST_FAILURE,
  SET_ASSIGNMENT_ID,
  START_BENCHMARK_TEST_REQUEST,
  BENCHMARK_NEXT_QUESTION_FAILURE,
  BENCHMARK_NEXT_QUESTION_REQUEST,
  BENCHMARK_NEXT_QUESTION_SUCCESS,
  BENCHMARK_TEST_FINISHED,
  BENCHMARK_TEST_COMPLETED,
  START_RC_BENCHMARK_TEST_SUCCESS,
  BENCHMARK_RC_NEXT_QUESTION_SUCCESS,
} from "../actionTypes/benchmarkTestActionTypes";

export const startBenchmarkTest =
  (assignmentId, programName, token, questionId, timeSpent) =>
  async (dispatch) => {
    dispatch({
      type: START_BENCHMARK_TEST_REQUEST,
    });
    try {
      const response = await startBenchmarkTestAPI(
        assignmentId,
        programName,
        token,
        questionId,
        timeSpent
      );
      let data = {
        level: response.data.currentLevel,
        accuracy: response.data.accuracyRate,
        complexity: response.data?.complexity,
        code: response.data?.code,
      };
      if (response.status === 500 || response.message === "Failure" || response.data.isCompleted) {
        dispatch({ type: BENCHMARK_TEST_COMPLETED, payload: true });
        return ;
      } else {
        dispatch({
          type: START_BENCHMARK_TEST_SUCCESS,
          payload: response.data,
        });
        return data; 
      }
    } catch (error) {
      dispatch({ type: SET_ASSIGNMENT_ID, payload: assignmentId });
      dispatch({ type: START_BENCHMARK_TEST_FAILURE, payload: error.message });
    }
  };

export const benchmarkTestNextQuestion =
  (
    questionId,
    assignmentId,
    submittedAnswer,
    isSkipped,
    timeSpentPerQuestion,
    programName,
    token
  ) =>
  async (dispatch) => {
    dispatch({
      type: BENCHMARK_NEXT_QUESTION_REQUEST,
    });
    try {
      const response = await benchmarkTestNextQuestionAPI(
        questionId,
        assignmentId,
        submittedAnswer,
        isSkipped,
        timeSpentPerQuestion,
        programName,
        token
      );
      let data = {
        level: response.data.currentLevel,
        accuracy: response.data.accuracyRate,
        complexity: response.data?.complexity,
        code: response.data?.code,
      };
      if (response.data.isCompleted === 1) {
        dispatch({ type: BENCHMARK_TEST_COMPLETED, payload: response.data });
      } else {
        dispatch({
          type: BENCHMARK_NEXT_QUESTION_SUCCESS,
          payload: response.data,
        });
        return data;
      }
    } catch (error) {
      dispatch({
        type: BENCHMARK_NEXT_QUESTION_FAILURE,
        payload: error.message,
      });
      return ;
    }
  };

export const startRCBenchmarkTest =
  (assignmentId, token, questionId, parentQuestionId, timeSpent) =>
  async (dispatch) => {
    dispatch({
      type: START_BENCHMARK_TEST_REQUEST,
    });
    try {
      const response = await startRCBenchmarkTestAPI(
        assignmentId,
        token,
        questionId,
        parentQuestionId,
        timeSpent
      );
      let data = {
        level: response.data.currentLevel,
        accuracy: response.data.accuracyRate,
        complexity: response.data?.complexity,
        code: response.data?.code,
      };
      if (response.status === 500 || response.message === "Failure" || response.data.isCompleted) {
        dispatch({ type: BENCHMARK_TEST_COMPLETED, payload: true });
      } else {
        dispatch({
          type: START_RC_BENCHMARK_TEST_SUCCESS,
          payload: response.data,
        });
        return data;
      }
    } catch (error) {
      dispatch({ type: SET_ASSIGNMENT_ID, payload: assignmentId });
      dispatch({ type: START_BENCHMARK_TEST_FAILURE, payload: error.message });
      return ;
    }
  };

export const startRCNextBenchmarkTest =
  (
    questionId,
    assignmentId,
    submittedAnswer,
    isSkipped,
    timeSpentPerQuestion,
    token,
    passageId
  ) =>
  async (dispatch) => {
    dispatch({
      type: BENCHMARK_NEXT_QUESTION_REQUEST,
    });
    try {
      const response = await startRCBenchmarkTestNextQuestionAPI(
        questionId,
        assignmentId,
        submittedAnswer,
        isSkipped,
        timeSpentPerQuestion,
        token,
        passageId
      );
      let data = {
        level: response.data?.currentLevel,
        accuracy: response.data?.accuracyRate,
        complexity: response.data?.complexity,
        code: response.data?.code,
      };
      if (response.data.isCompleted === 1) {
        dispatch({ type: BENCHMARK_TEST_COMPLETED, payload: response.data });
      } else {
        dispatch({
          type: BENCHMARK_RC_NEXT_QUESTION_SUCCESS,
          payload: response.data,
        });
        return data;
      }
    } catch (error) {
      dispatch({
        type: BENCHMARK_NEXT_QUESTION_FAILURE,
        payload: error.message,
      });
      return ;
    }
  };

export const testFinished = (data) => ({
  type: BENCHMARK_TEST_FINISHED,
  payload: data,
});
export const testCompleted = (data) => ({
  type: BENCHMARK_TEST_COMPLETED,
  payload: data,
});
